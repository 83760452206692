import { render, staticRenderFns } from "./SaranaUmum.vue?vue&type=template&id=6caa3144&"
import script from "./SaranaUmum.vue?vue&type=script&lang=js&"
export * from "./SaranaUmum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports