<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Data Perikanan - Ikan Tangkap</h4></v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      outlined
                      :rules="wajibRules"
                      v-model="editedItem.kategori"
                      :items="itemsikan"
                      item-text="kategori_ikan"
                      item-value="kategori_ikan"
                      color="teal"
                      label="Pilih Kategori"
                      dense
                    ></v-select>
                  </v-col>

                  <template v-if="editedItem.kategori === 'Pelaku Usaha'">
                    <v-row class="pl-1">
                      <v-col cols="12" md="9">
                        <v-text-field
                          label="NIK"
                          outlined
                          maxlength="16"
                          :counter="16"
                          v-model="editedItem.nik"
                          :rules="nomorduaRules"
                          color="teal"
                          required
                          dense
                          hint="masukan no NIK lalu tekan -> cek nik"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pl-5" cols="12" md="3">
                        <v-btn
                          small
                          dark
                          @click="cekKAKA()"
                          outlined
                          color="teal"
                        >
                          CEK NIK
                        </v-btn>
                        <h5 v-if="this.statusdata === 1" class="teal--text">
                          {{ this.pesancek }}
                        </h5>
                        <h5 v-else-if="this.statusdata === 0" class="red--text">
                          {{ this.pesancek }}
                        </h5>
                        <h5 v-else></h5>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-1 pr-1"
                      dense
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                    >
                      <v-col cols="12" md="6">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="hasildatakk.nik"
                          readonly
                          color="teal"
                          label="Nomor Induk Kependudukan (NIK)"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="hasildatakk.nama"
                          readonly
                          color="teal"
                          label="Nama Lengkap"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-col
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                      cols="12"
                    >
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.jenis_alat"
                        :items="itemsalat"
                        item-text="jenis_alat"
                        item-value="jenis_alat"
                        color="teal"
                        label="Jenis dan Jumlah Alat Tangkap"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>

                    <v-col
                      v-for="item in editedItem.jenis_alat"
                      :key="item"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'PANCING ULUR'"
                        color="teal"
                        v-model="editedItem.pancing_ulur"
                        label="Jumlah Pancing Ulur (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'JARING INSANG'"
                        color="teal"
                        v-model="editedItem.jaring_insang"
                        label="Jumlah Jaring Insang (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'PAYANG'"
                        color="teal"
                        v-model="editedItem.payang"
                        label="Jumlah Payang (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'BAGAN'"
                        color="teal"
                        v-model="editedItem.bagan"
                        label="Jumlah Payang (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'TONDA'"
                        color="teal"
                        v-model="editedItem.tonda"
                        label="Jumlah Tonda (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                      cols="12"
                    >
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.jenis_kapal"
                        :items="itemskapal"
                        item-text="jenis_kapal"
                        item-value="jenis_kapal"
                        color="teal"
                        label="Jumlah Kapal Tangkap"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>

                    <v-col
                      v-for="(item, index) in editedItem.jenis_kapal"
                      :key="index"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'KAPAL MOTOR'"
                        color="teal"
                        v-model="editedItem.kapal_motor"
                        label="Jumlah Kapal Motor (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'MOTOR TEMPEL'"
                        color="teal"
                        v-model="editedItem.motor_tempel"
                        label="Jumlah Motor Tempel (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'PERAHU TANPA MOTOR'"
                        color="teal"
                        v-model="editedItem.perahu_tanpa_motor"
                        label="Jumlah Motor Tanpa Motor (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                      cols="12"
                    >
                      <v-text-field
                        label="Jumlah Produksi Rata-Rata Perbulan (Kg)"
                        outlined
                        v-model="editedItem.jumlah_produksi"
                        :rules="nomorduaRules"
                        color="teal"
                        required
                        hint="hanya berisi angka dengan satuan Kg"
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>
                  <template v-if="editedItem.kategori === 'Kelompok'">
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Kelompok"
                        outlined
                        v-model="editedItem.nm_kelompok"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Alamat Kelompok"
                        outlined
                        v-model="editedItem.alamat_kelompok"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Ketua"
                        outlined
                        v-model="editedItem.nm_ketua"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Jumlah Anggota"
                        outlined
                        v-model="editedItem.jumlah_anggota"
                        color="teal"
                        :rules="nomorduaRules"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-3">
                      <v-btn small dark @click="addTextRow()" color="teal">
                        <v-icon small dense>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-row
                      dense
                      v-for="row in textRows"
                      :key="row.index"
                      class="pl-1 pr-1"
                    >
                      <v-col cols="12" md="10">
                        <v-text-field
                          label="Nama Anggota"
                          outlined
                          v-model="row.nm_anggota"
                          color="teal"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          small
                          dark
                          @click="deleteTextRow(row)"
                          color="red"
                        >
                          <v-icon small dense>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-col
                      v-for="(item, index) in editedItem.jenis_budidaya"
                      :key="index"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'Kolam Air Tenang'"
                        color="teal"
                        v-model="editedItem.air_tenang"
                        label="Luas Kolam Air Tenang (M2)"
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kolam Air Deras'"
                        color="teal"
                        v-model="editedItem.air_deras"
                        label="Luas Kolam Air Deras (M2) "
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kolam Terpal'"
                        color="teal"
                        v-model="editedItem.terpal"
                        label="Luas Kolam Terpal (M2) "
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Keramba'"
                        color="teal"
                        v-model="editedItem.keramba"
                        label="Luas Keramba (M2) "
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.jenis_alat"
                        :items="itemsalat"
                        item-text="jenis_alat"
                        item-value="jenis_alat"
                        color="teal"
                        label="Jenis dan Jumlah Alat Tangkap"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>

                    <v-col
                      v-for="(item, index) in editedItem.jenis_alat"
                      :key="index"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'PANCING ULUR'"
                        color="teal"
                        v-model="editedItem.pancing_ulur"
                        label="Jumlah Pancing Ulur (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'JARING INSANG'"
                        color="teal"
                        v-model="editedItem.jaring_insang"
                        label="Jumlah Jaring Insang (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'PAYANG'"
                        color="teal"
                        v-model="editedItem.payang"
                        label="Jumlah Payang (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'BAGAN'"
                        color="teal"
                        v-model="editedItem.bagan"
                        label="Jumlah Payang (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'TONDA'"
                        color="teal"
                        v-model="editedItem.tonda"
                        label="Jumlah Tonda (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.jenis_kapal"
                        :items="itemskapal"
                        item-text="jenis_kapal"
                        item-value="jenis_kapal"
                        color="teal"
                        label="Jumlah Kapal Tangkap"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>
                    <v-col
                      v-for="(item, index) in editedItem.jenis_kapal"
                      :key="index"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'KAPAL MOTOR'"
                        color="teal"
                        v-model="editedItem.kapal_motor"
                        label="Jumlah Kapal Motor (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'MOTOR TEMPEL'"
                        color="teal"
                        v-model="editedItem.motor_tempel"
                        label="Jumlah Motor Tempel (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'PERAHU TANPA MOTOR'"
                        color="teal"
                        v-model="editedItem.perahu_tanpa_motor"
                        label="Jumlah Motor Tanpa Motor (unit)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan unit"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        label="Jumlah Produksi Rata-Rata Triwulan (Kg)"
                        outlined
                        v-model="editedItem.jumlah_produksi"
                        :rules="nomorduaRules"
                        color="teal"
                        required
                        hint="hanya berisi angka dengan satuan Kg"
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <template v-if="this.statusdata === 1"> </template>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title><h5>Yakin hapus data Ikan Tangkap ?</h5> </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogDetail" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Detail Data Ikan Tangkap : {{ detailItem.kategori }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeDetail"
              >close</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <br />
          <div v-if="detailItem.kategori === 'Pelaku Usaha'">
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="detailItem.nik"
                  readonly
                  color="teal"
                  label="NIK"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jenis_alat"
                  readonly
                  color="teal"
                  label="Jenis Alat Tangkap "
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(item, index) in detailItem.jenis_alat"
                :key="index"
                cols="12"
                md="4"
              >
                <v-text-field
                  v-show="item === 'PANCING ULUR'"
                  color="teal"
                  v-model="detailItem.pancing_ulur"
                  label="Jumlah Pancing Ulur (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'JARING INSANG'"
                  color="teal"
                  v-model="detailItem.jaring_insang"
                  label="Jumlah Jaring Insang (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'PAYANG'"
                  color="teal"
                  v-model="detailItem.payang"
                  label="Jumlah Payang (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'BAGAN'"
                  color="teal"
                  v-model="detailItem.bagan"
                  label="Jumlah Payang (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'TONDA'"
                  color="teal"
                  v-model="detailItem.tonda"
                  label="Jumlah Tonda (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jenis_kapal"
                  readonly
                  color="teal"
                  label="Jumlah Kapal Tangkap "
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                v-for="(item, index) in detailItem.jenis_kapal"
                :key="index"
                cols="4"
              >
                <v-text-field
                  v-show="item === 'KAPAL MOTOR'"
                  color="teal"
                  v-model="detailItem.kapal_motor"
                  label="Jumlah Kapal Motor (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'MOTOR TEMPEL'"
                  color="teal"
                  v-model="detailItem.motor_tempel"
                  label="Jumlah Motor Tempel (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'PERAHU TANPA MOTOR'"
                  color="teal"
                  v-model="detailItem.perahu_tanpa_motor"
                  label="Jumlah Motor Tanpa Motor (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jumlah_produksi"
                  readonly
                  color="teal"
                  label="Jumlah Produksi Rata-Rata Pernulan (Kg)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.nm_kelompok"
                  readonly
                  color="teal"
                  label="Nama Kelompok"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.alamat_kelompok"
                  readonly
                  color="teal"
                  label="Alamat Kelompok"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.nm_ketua"
                  readonly
                  color="teal"
                  label="Nama Ketua "
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jumlah_anggota"
                  readonly
                  color="teal"
                  label="Jumlah Anggota "
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                v-for="(item, index) in this.anggota"
                :key="index"
                cols="4"
              >
                <v-text-field
                  :rules="wajibRules"
                  v-model="item.nm_anggota"
                  readonly
                  color="teal"
                  label="Nama Anggota "
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jenis_alat"
                  readonly
                  color="teal"
                  label="Jenis Alat Tangkap "
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(item, index) in detailItem.jenis_alat"
                :key="index"
                cols="12"
                md="4"
              >
                <v-text-field
                  v-show="item === 'PANCING ULUR'"
                  color="teal"
                  v-model="detailItem.pancing_ulur"
                  label="Jumlah Pancing Ulur (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'JARING INSANG'"
                  color="teal"
                  v-model="detailItem.jaring_insang"
                  label="Jumlah Jaring Insang (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'PAYANG'"
                  color="teal"
                  v-model="detailItem.payang"
                  label="Jumlah Payang (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'BAGAN'"
                  color="teal"
                  v-model="detailItem.bagan"
                  label="Jumlah Payang (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'TONDA'"
                  color="teal"
                  v-model="detailItem.tonda"
                  label="Jumlah Tonda (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jenis_kapal"
                  readonly
                  color="teal"
                  label="Jumlah Kapal Tangkap "
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                v-for="(item, index) in detailItem.jenis_kapal"
                :key="index"
                cols="4"
              >
                <v-text-field
                  v-show="item === 'KAPAL MOTOR'"
                  color="teal"
                  v-model="detailItem.kapal_motor"
                  label="Jumlah Kapal Motor (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'MOTOR TEMPEL'"
                  color="teal"
                  v-model="detailItem.motor_tempel"
                  label="Jumlah Motor Tempel (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
                <v-text-field
                  v-show="item === 'PERAHU TANPA MOTOR'"
                  color="teal"
                  v-model="detailItem.perahu_tanpa_motor"
                  label="Jumlah Motor Tanpa Motor (unit)"
                  filled
                  readonly
                  :rules="nomorduaRules"
                  dense
                  hint="hanya berisi angka dengan satuan unit"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="detailItem.jumlah_produksi"
                  readonly
                  color="teal"
                  label="Jumlah Rroduksi Rata-Rata Triwulan (Kg)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <br />
          <v-divider></v-divider>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-1"
              @click="detailData(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>
            <v-btn
              class="lighten-4 mx-1"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>

            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.jumlah_produksi`]="{ item }">
          <h4 class="dark--text font-weight-bold">
            {{ item.jumlah_produksi }} Kg
          </h4>
        </template>
        <template v-slot:[`item.jenis_alat`]="{ item }">
          <v-chip
            class="ma-2"
            color="brown"
            label
            small
            text-color="white"
            v-for="(items, index) in item.jenis_alat.split(',')"
            :key="index"
          >
            <v-icon small left> mdi-label </v-icon>
            <h4 class="font-weight-bold">
              {{ items }}
            </h4>
          </v-chip>
        </template>
        <template v-slot:[`item.jenis_kapal`]="{ item }">
          <v-chip
            small
            class="ma-2"
            color="brown"
            label
            text-color="white"
            v-for="(items, index) in item.jenis_kapal.split(',')"
            :key="index"
          >
            <v-icon small left> mdi-label </v-icon>
            <h4 class="font-weight-bold">
              {{ items }}
            </h4>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "SagaData",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Ikan Tangkap"
        : "Form Edit Data Ikan Tangkap";
    },
  },
  data() {
    return {
      //Data Ikanbudidaya
      itemsikan: [{}],

      anggota: [{}],

      // Item Bududaya

      itemsalat: [{}],
      itemskapal: [{}],

      statusdata: null,
      datailusi: {},

      hasildatakk: {
        id_nik: null,
        nik: null,
        nama: null,
        id_nokk: null,
      },
      pesancek: "",

      // Dialog
      dialogEdit: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,
      nik: "",
      dialogDetail: false,

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      nomorduaRules: [
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: "Kategori", value: "kategori" },
        { text: "Jenis  Alat Tangkap", value: "jenis_alat" },
        { text: "Jenis Kapal Tangkap", value: "jenis_kapal" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        kategori: "",
        jenis_alat: "",
        jenis_kapal: "",
        nik: "",
        jumlah_produksi: "",

        // Pelaku
        motor_tempel: null,
        perahu_tanpa_motor: null,
        pancing_ulur: null,
        jaring_insang: null,
        payang: null,
        bagan: null,
        tonda: null,
        kapal_motor: null,
        // kelompok

        nm_kelompok: null,
        alamat_kelompok: null,
        nm_ketua: null,
        jumlah_anggota: null,
        nm_anggota: null,
      },
      defaultItem: {
        id: 0,
        kategori: "",
        jenis_alat: "",
        jenis_kapal: "",
        nik: "",
        jumlah_produksi: "",

        // Pelaku
        motor_tempel: null,
        perahu_tanpa_motor: null,
        pancing_ulur: null,
        jaring_insang: null,
        payang: null,
        bagan: null,
        tonda: null,
        kapal_motor: null,
        // kelompok

        nm_kelompok: null,
        alamat_kelompok: null,
        nm_ketua: null,
        jumlah_anggota: null,
        nm_anggota: null,
      },

      detailItem: {
        id: 0,
        kategori: "",
        jenis_alat: "",
        jenis_kapal: "",
        nik: "",
        jumlah_produksi: "",

        // Pelaku
        motor_tempel: null,
        perahu_tanpa_motor: null,
        pancing_ulur: null,
        jaring_insang: null,
        payang: null,
        bagan: null,
        tonda: null,
        kapal_motor: null,
        // kelompok

        nm_kelompok: null,
        alamat_kelompok: null,
        nm_ketua: null,
        jumlah_anggota: null,
        nm_anggota: null,
      },

      textRows: [
        {
          nm_anggota: null,
        },
      ],
      removeTextRow: false,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getIkanTangkapAll();
    this.getIkanKategori();
    this.detailUsers();
    this.getDusun();
    this.getAlatTangkap();
    this.getKapalTangkap();
  },

  methods: {
    addTextRow() {
      this.textRows.push({});
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
      }
    },
    deleteTextRow(row) {
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
        this.textRows.splice(this.textRows.indexOf(row), 1);
      }
      if (this.textRows.length <= 1) {
        this.removeTextRow = false;
      }
    },
    cekKAKA: function (row) {
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },
    getIkanKategori: function () {
      AuthService.getIkanKategori().then(
        function (response) {
          this.itemsikan = response.data.data;
        }.bind(this)
      );
    },
    getAlatTangkap: function () {
      AuthService.getAlatTangkap().then(
        function (response) {
          this.itemsalat = response.data.data;
        }.bind(this)
      );
    },
    getKapalTangkap: function () {
      AuthService.getKapalTangkap().then(
        function (response) {
          this.itemskapal = response.data.data;
        }.bind(this)
      );
    },
    getIkanTangkapAll: function () {
      AuthService.getIkanTangkapAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailData(item) {
      this.detailItem = Object.assign({}, item);

      this.anggota = item.nm_anggota;
      this.detailItem.jenis_alat = item.jenis_alat.split(",");
      this.detailItem.jenis_kapal = item.jenis_kapal.split(",");
      this.dialogDetail = true;
      console.log(this.anggota);
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;

      this.nik = this.editedItem.nik;
      this.editedItem.jenis_alat = item.jenis_alat.split(",");
      this.editedItem.jenis_kapal = item.jenis_kapal.split(",");
      this.textRows = item.nm_anggota;

      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );

      this.dialog = true;
      // console.log(this.editedItem.jenis_budidaya);

      // this.editedItem.nik = this.editedItem.nik;
      // this.editedItem.nama = this.editedItem.nama;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;

      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteIkanTangkap(credentials);
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getIkanTangkapAll();
        this.getIkanKategori();
        this.getAlatTangkap();
        this.getKapalTangkap();

        this.loading = false;
        this.$router
          .push("/admin-kelurahan/perikanan/ikan-tangkap")
          .catch(() => {});
        this.dialog = false;
        this.statusdata = null;
        this.hasildatakk = [{}];
        this.close();
        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.statusdata = null;
      });
    },

    closeDetail() {
      this.dialogDetail = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.statusdata = 0;
      this.pesancek = "";
      // this.$refs.form.reset();
      this.dialogEdit = false;

      // this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },

    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          // console.log(this.editedItem.jenis_budidaya);

          // console.log(JSON.stringify(this.textRows));
          const credentials = {
            kategori: this.editedItem.kategori,
            nik: this.hasildatakk.nik,
            jumlah_produksi: this.editedItem.jumlah_produksi,
            jenis_alat: this.editedItem.jenis_alat.toString(),
            jenis_kapal: this.editedItem.jenis_kapal.toString(),

            motor_tempel: this.editedItem.motor_tempel,
            perahu_tanpa_motor: this.editedItem.perahu_tanpa_motor,
            pancing_ulur: this.editedItem.pancing_ulur,
            jaring_insang: this.editedItem.jaring_insang,
            payang: this.editedItem.payang,
            bagan: this.editedItem.bagan,
            tonda: this.editedItem.tonda,
            kapal_motor: this.editedItem.kapal_motor,

            nm_kelompok: this.editedItem.nm_kelompok,
            alamat_kelompok: this.editedItem.alamat_kelompok,
            nm_ketua: this.editedItem.nm_ketua,
            jumlah_anggota: this.editedItem.jumlah_anggota,
            nm_anggota: this.textRows,
          };
          const response = await AuthService.tambahIkanTangkap(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getIkanTangkapAll();
            this.getIkanKategori();
            this.getAlatTangkap();
            this.getKapalTangkap();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/perikanan/ikan-tangkap")
              .catch(() => {});
            this.dialog = false;
            this.statusdata = null;
            this.hasildatakk = [{}];
            this.dialogDetail = false;
            this.close();

            this.$refs.form.reset();
            console.log("berhasil simpan ikanbudidaya");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });
            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            kategori: this.editedItem.kategori,
            nik: this.hasildatakk.nik,
            jumlah_produksi: this.editedItem.jumlah_produksi,
            jenis_alat: this.editedItem.jenis_alat.toString(),
            jenis_kapal: this.editedItem.jenis_kapal.toString(),

            motor_tempel: this.editedItem.motor_tempel,
            perahu_tanpa_motor: this.editedItem.perahu_tanpa_motor,
            pancing_ulur: this.editedItem.pancing_ulur,
            jaring_insang: this.editedItem.jaring_insang,
            payang: this.editedItem.payang,
            bagan: this.editedItem.bagan,
            tonda: this.editedItem.tonda,
            kapal_motor: this.editedItem.kapal_motor,

            nm_kelompok: this.editedItem.nm_kelompok,
            alamat_kelompok: this.editedItem.alamat_kelompok,
            nm_ketua: this.editedItem.nm_ketua,
            jumlah_anggota: this.editedItem.jumlah_anggota,
            nm_anggota: this.textRows,
          };
          const response = await AuthService.editIkanTangkap(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getIkanTangkapAll();
            this.getIkanKategori();
            this.getAlatTangkap();
            this.getKapalTangkap();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/perikanan/ikan-tangkap")
              .catch(() => {});
            this.dialog = false;
            (this.statusdata = null),
              (this.hasildatakk = [{}]),
              (this.dialogDetail = false);

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
