<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Data Dasawisma - Kematian Ibu dan Bayi</h4></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      :rules="wajibRules"
                      v-model="editedItem.status"
                      :items="itemsibu"
                      item-text="name"
                      item-value="name"
                      color="teal"
                      label="Status "
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Nama Ibu / Bayi"
                      outlined
                      v-model="editedItem.nama"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :rules="wajibRules"
                      v-model="editedItem.jenis_kelamin"
                      :items="itemsjk"
                      item-text="jenis_kelamin"
                      item-value="jenis_kelamin"
                      color="teal"
                      label="Pilih Jenis Kelamin"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.tgl_meninggal"
                          color="teal"
                          label="Tanggal Meninggal"
                          readonly
                          :rules="wajibRules"
                          dense
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="teal"
                        v-model="editedItem.tgl_meninggal"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Sebab Meninggal"
                      outlined
                      v-model="editedItem.sebab"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialogDelete" persistent max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Yakin hapus data Kematian Ibu dan Bayi ?</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br />
            <h4 class="brown--text">Nama :{{ editedItem.nama }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-card v-else elevation="0">
        <v-card-text>
          <v-data-table
            multi-sort
            :headers="headers"
            :items="hasildatas"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="center">
                <v-btn
                  class="lighten-4 mx-2"
                  @click="editItem(item)"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon dense> mdi-pencil </v-icon>
                </v-btn>
                <v-btn small @click="deleteItem(item)" outlined color="error">
                  <v-icon dense center> mdi-delete </v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "masterbarangvue",
  data() {
    return {
      date: null,

      modal: false,
      menu2: false,
      // ITEM PKK
      itemsmakanan: [{}],
      itemskesehatan: [{}],
      itemsrencana: [{}],
      hasildatas: [],

      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      // VALIDASI

      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      headers: [
        { text: "Nama Bayi", value: "nama" },
        { text: "Status", value: "status" },
        { text: "Jenis Kelamin", value: "jenis_kelamin" },
        { text: "Tgl Meninggal", value: "tgl_meninggal" },
        { text: "Sebab Meninggal", value: "sebab" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        nama: "",
        jenis_kelamin: "",
        tgl_meninggal: "",
        status: "",
        sebab: "",
      },
      defaultItem: {
        provinsi: "",
      },
      hasildatakk: {
        nik: null,
        nama: null,
        status: null,
        id_nokk: null,
        id_nik: null,
        nokk: null,
      },
      itemsibu: [
        {
          name: "Ibu",
        },
        {
          name: "Bayi",
        },
      ],
      pesancek: "",

      statusdata: null,
      itemsyatidak: [{}],
      itemsjk: [{}],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data  Kematian Ibu dan Bayi"
        : "Form Edit Data  Kematian Ibu dan Bayi";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {
    this.getKematianKelahiranAll();
    this.detailUsers();
    this.getDusun();
    this.getJK();
  },
  methods: {
    getJK: function () {
      AuthService.getJK().then(
        function (response) {
          this.itemsjk = response.data.data;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          this.kota = this.detailusers.kota;
          this.kecamatan = this.detailusers.kecamatan;
          this.kelurahan = this.detailusers.kelurahan;
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getKematianKelahiranAll: function () {
      AuthService.getKematianKelahiranAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteKematianKelahiran(credentials);
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.getKematianKelahiranAll();
        this.detailUsers();
        this.getDusun();
        this.getJK();

        this.$router
          .push("/admin-kelurahan/dasawisma/kematian-bayi")
          .catch(() => {});
        this.dialogDelete = this.closeDelete();
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          console.log(this.editedItem);
          const credentials = {
            nama: this.editedItem.nama,
            status: this.editedItem.status,
            jenis_kelamin: this.editedItem.jenis_kelamin,
            tgl_meninggal: this.editedItem.tgl_meninggal,
            sebab: this.editedItem.sebab,
          };
          const response = await AuthService.tambahKematianKelahiran(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getKematianKelahiranAll();
            this.detailUsers();
            this.getDusun();
            this.getJK();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/dasawisma/kematian-bayi")
              .catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();
            console.log("berhasil simpan kematian bayi");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            nama: this.editedItem.nama,
            status: this.editedItem.status,
            jenis_kelamin: this.editedItem.jenis_kelamin,
            tgl_meninggal: this.editedItem.tgl_meninggal,
            sebab: this.editedItem.sebab,
          };
          const response = await AuthService.editKematianKelahiran(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getKematianKelahiranAll();
            this.detailUsers();
            this.getDusun();
            this.getJK();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/dasawisma/kematian-bayi")
              .catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
