<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Form Input Data Akses Jalan</h4></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-card v-else elevation="1">
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="simpanJalan"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="nama"
                color="teal"
                label="Nama Jalan"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :rules="nomorRules"
                v-model="panjangJalan"
                color="teal"
                label="Panjang Jalan"
                filled
                hint=" satuan (Meter)"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :rules="nomorRules"
                v-model="lebarJalan"
                color="teal"
                label="Lebar Jalan"
                filled
                hint=" satuan (Meter)"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="jenis"
                :items="itemsjenis"
                item-text="jenis_jalan"
                item-value="jenis_jalan"
                color="teal"
                label="Pilih Jenis Jalan"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="status"
                :items="itemsstatus"
                item-text="status_jalan"
                item-value="status_jalan"
                color="teal"
                label="Pilih Status Jalan"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                :rules="wajibRules"
                v-model="thn_dibuat"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Dibuat Tahun"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                :rules="wajibRules"
                v-model="thn_perbaikan"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Tahun Perbaikan Terakhir"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="link"
                color="teal"
                label="Link Embed Video 360 derajat"
                filled
                dense
              ></v-text-field>
            </v-col>
            <br />
            <br />
            <v-col cols="12">
              <h4>Tentukan Lokasi Akses Jalan Pada Peta :</h4>
            </v-col>
            <v-col cols="12">
              <MapMitra @data-peta="petaValue" />
            </v-col>
          </v-row>
          <br />

          <v-divider></v-divider>
          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.lat === null"
              type="submit"
              color="teal"
              disabled
              class="mr-0 white--text"
            >
              Simpan Data
            </v-btn>
            <v-btn
              v-else
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              color="teal"
              class="mr-0 white--text"
            >
              Simpan Data
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <br />
  </v-container>
</template>

<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";
import MapMitra from "@/components/MapMitra.vue";

export default {
  name: "JalanForm",
  components: {
    MapMitra,
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1800 },
        (value, index) => 1801 + index
      );
    },
  },
  data() {
    return {
      // Items Jalan

      itemsjenis: [{}],
      itemsstatus: [{}],

      // Data Akses Jalan
      nama: "",
      panjangJalan: "",
      lebarJalan: "",
      jenis: "",
      status: "",
      thn_dibuat: "",
      thn_perbaikan: "",
      link: "",
      lat: null,
      long: "",

      // DATE TGL LAHIR
      activePicker: null,
      date: null,
      menu: false,

      // VALIDASI

      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      // Loder
      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,
      dialogfinal: false,
      valid: true,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.textRows.activePicker = "YEAR"));
    },
  },
  methods: {
    petaValue(petaValue) {
      this.lat = petaValue.latitude;
      this.long = petaValue.longitude;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    getJenisJalan: function () {
      AuthService.getJenisJalan().then(
        function (response) {
          this.itemsjenis = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(response.data.data);
        }.bind(this)
      );
    },
    getStatusJalan: function () {
      AuthService.getStatusJalan().then(
        function (response) {
          this.itemsstatus = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(response.data.data);
        }.bind(this)
      );
    },

    async simpanJalan() {
      try {
        this.loading = true;
        const credentials = {
          nama: this.nama,
          panjangJalan: this.panjangJalan,
          lebarJalan: this.lebarJalan,
          jenis: this.jenis,
          status: this.status,
          thn_dibuat: this.thn_dibuat,
          thn_perbaikan: this.thn_perbaikan,
          link: this.link,
          lat: this.lat,
          long: this.long,
        };

        const response = await AuthService.tambahAksesJalan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil simpan jalan");

          this.$router
            .push("/admin-kelurahan/akses-jalan/data")
            .catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  created: function () {
    this.getDusun();
    this.getJenisJalan();
    this.getStatusJalan();
  },
  async mounted() {
    this.detailUsers();
  },
};
</script>
