<template>
  <div style="height: 260px">
    <l-map ref="map" :zoom="zoom" :center="[this.markerslat, this.markerslong]">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>

      <l-marker
        :lat-lng="[this.markerslat, this.markerslong]"
        :draggable="true"
        @drag="updateCoordinates"
      >
        <l-popup></l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { latLng } from "leaflet";
export default {
  props: ["markerslong", "markerslat"],
  // props: {
  //   markerslong: Boolean,
  //   markerslat: Object,
  // },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 14,

      center: latLng(-0.626439, 100.117958),

      kordinatvalue: {
        latitude: "",
        longitude: "",
      },
    };
  },

  mounted() {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  },
  methods: {
    updateCoordinates(location) {
      this.kordinatvalue.latitude = location.latlng.lat;
      this.kordinatvalue.longitude = location.latlng.lng;

      this.$emit("data-peta", this.kordinatvalue);
      //   this.markerLatLng = [location.latLng.lat(), location.latLng.lng()];
    },
  },
};
</script>
