<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Form Input Data SPPT-PBB</h4></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-card v-else elevation="1">
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="simpanPBB"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                color="teal"
                :value="this.kecamatan"
                label="Kecamatan"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                color="teal"
                :value="this.kelurahan"
                label="Kelurahan / Desa"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="dusunRules"
                v-model="id_dusun"
                :items="itemsdusun"
                item-text="dusun"
                item-value="id"
                color="teal"
                label="Pilih Dusun"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="tahun"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Pembayaran Tahun"
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="nama"
                color="teal"
                label="Nama Wajib Pajak"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :rules="nomorRules"
                v-model="luas_bumi"
                color="teal"
                label="Luas Bumi"
                filled
                dense
                hint=" satuan (m2)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :rules="nomorRules"
                v-model="luas_bangunan"
                color="teal"
                label="Luas Bangunan"
                filled
                dense
                hint=" satuan (m2)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="no_bangunan"
                :items="itemsnobang"
                item-text="no_bangunan"
                item-value="id"
                color="teal"
                return-object
                label="Pilih Nomor Bangunan"
                filled
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                :rules="dusunRules"
                v-model="status_bayar"
                :items="itemspbb"
                item-text="status_spt"
                item-value="status_spt"
                color="teal"
                label="Pilih Status Pembayaran"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="dusunRules"
                v-model="status_lalu"
                :items="itemstahunlalu"
                item-text="thn_lalu"
                item-value="thn_lalu"
                color="teal"
                label="Pilih Status Pembayaran Tahun Sebelumnya"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col v-show="this.status_lalu === 'Terhutang'" cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="tahun_hutang"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Pilih Tahun Terhutang"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col v-show="this.status_lalu === 'Terhutang'" cols="12" md="4">
              <v-text-field
                v-model="bayar_hutang"
                color="teal"
                label="Total Pembayaran biaya tahun sebelumnya"
                filled
                dense
                @input="handleInput"
                hint=" hanya angka"
              ></v-text-field>
            </v-col>
          </v-row>
          <br />

          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="!valid"
              type="submit"
              color="teal"
              class="mr-0 white--text"
            >
              Simpan Data
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <br />
  </v-container>
</template>

<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";

export default {
  name: "SpptForm",

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
  },
  data() {
    return {
      // Items Sppt
      itemspbb: [{}],
      itemstahunlalu: [{}],
      itemsnobang: [{}],
      // Data sptt
      itemsdusun: [{}],

      kecamatan: "",
      kelurahan: "",
      id_dusun: "",
      tahun: "",
      nama: "",
      luas_bumi: "",
      luas_bangunan: "",
      no_bangunan: [{}],
      status_bayar: "",
      status_lalu: "",
      tahun_hutang: "",
      bayar_hutang: "",

      // Loading bansos

      // DATE TGL LAHIR
      activePicker: null,
      date: null,
      menu: false,

      // VALIDASI

      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      // Loder
      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,
      dialogfinal: false,
      valid: true,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.textRows.activePicker = "YEAR"));
    },
    value: {
      handler(after) {
        this.bayar_hutang = this.format(after);
      },
      immediate: true,
    },
  },
  methods: {
    // RUPIAH FORMAT
    format: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInput() {
      this.bayar_hutang = this.format(this.bayar_hutang);
      this.$emit("v-textfield", (this.bayar_hutang + "").replace(/[0-9]/g, ""));
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    getStatusSPTPBB: function () {
      AuthService.getStatusSPTPBB().then(
        function (response) {
          this.itemspbb = response.data.data;
        }.bind(this)
      );
    },
    getStatusThnLalu: function () {
      AuthService.getStatusThnLalu().then(
        function (response) {
          this.itemstahunlalu = response.data.data;
        }.bind(this)
      );
    },
    nomorBangunan: function () {
      AuthService.nomorBangunan().then(
        function (response) {
          this.itemsnobang = response.data.data;
        }.bind(this)
      );
    },

    async simpanPBB() {
      try {
        this.loading = true;
        const credentials = {
          id_dusun: this.id_dusun,
          nama_pajak: this.nama,
          luas_bumi: this.luas_bumi,
          luas_bangunan: this.luas_bangunan,
          tahun: this.tahun,
          status_pembayaran: this.status_bayar,
          status_thn_lalu: this.status_lalu,
          thn_hutang: this.tahun_hutang,
          bayr_thn_lalu: this.bayar_hutang.replace(/[^0-9]/g, ""),

          no_bangunan: this.no_bangunan.no_bangunan,
          id_bangunan: this.no_bangunan.id,
        };
        console.log(this.no_bangunan.id);
        const response = await AuthService.tambahSpptpbb(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil simpan SPPT PBB");

          this.$router.push("/admin-kelurahan/sppt-pbb/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  created: function () {
    this.getDusun();
    this.getStatusSPTPBB();
    this.getStatusThnLalu();
    this.nomorBangunan();
  },
  async mounted() {
    this.detailUsers();
  },
};
</script>
