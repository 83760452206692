<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data SAGASAJA</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title
          ><h5>Apakah yakin hapus data SAGASAJA ?</h5>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-list-item-content>
            <v-list-item-title>
              <h5 class="teal--text">NIK Penerima</h5></v-list-item-title
            >
            <v-list-item-subtitle>
              <h3 class="teal--text">
                {{ editedItem.nik }}
              </h3></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogEdit" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Edit Data SAGASAJA : {{ editedItem.nik }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeEdit"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="updateSAGA"
            v-model="valid"
            lazy-validation
          >
            <br />

            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="nik"
                  :rules="nokkRules"
                  label="Nomor Induk Kependudukan (NIK)"
                  required
                  filled
                  counter="16"
                  maxlength="16"
                  dense
                  color="teal"
                  hint="input no NIK tekan -> cek kk"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn small dark @click="cekKAKA" outlined color="teal">
                  CEK NIK
                </v-btn>
                <h5 v-if="this.statusdata === 1" class="teal--text">
                  {{ this.pesancek }}
                </h5>
                <h5 v-else-if="this.statusdata === 0" class="red--text">
                  {{ this.pesancek }}
                </h5>
                <h5 v-else></h5>
              </v-col>
            </v-row>
            <v-divider class="pb-3"></v-divider>
            <v-row
              dense
              v-show="this.editedItem.nik !== null && this.statusdata === 1"
            >
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nik"
                  readonly
                  color="teal"
                  label="Nomor Induk Kependudukan (NIK)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.penerima"
                  readonly
                  color="teal"
                  label="Nama Lengkap"
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.tahun"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Pilih Tahun"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.perguruan_tinggi"
                  color="teal"
                  label="Masukan Perguruan Tinggi"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.asal_sekolah"
                  color="teal"
                  label="Masukan Asal Sekolah"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <template v-if="this.nik === editedItem.nik">
                <v-btn
                  v-show="editedItem.nik !== null && this.statusdata === 1"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  color="teal"
                  class="mr-0 white--text"
                >
                  Update Data
                </v-btn>
              </template>
              <template v-else>
                <v-btn disabled color="grey" class="mr-0 white--text">
                  Update Data
                </v-btn>
              </template>
            </v-card-actions>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-card class="rounded-card">
      <v-card-title>
        <v-col cols="12" md="4">
          <v-row class="pa-0">
            <!-- Filter for calories -->
            <v-select
              :items="caloriesList"
              v-model="caloriesFilterValue"
              label="Filter Tahun"
              outlined
              color="teal"
              dense
              hide-details
            ></v-select>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>

            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "SagaData",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
  },
  data() {
    return {
      //FILTER
      caloriesList: [
        { text: "All", value: null },
        { text: "2022", value: 2022 },
        { text: "2020", value: 2020 },
        { text: "2021", value: 2021 },
      ],

      caloriesFilterValue: null,
      // Data Bansos

      pesancek: "",
      statusdata: 1,
      datailusi: {},

      // Dialog
      dialogEdit: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,
      nik: "",

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: "NIK Penerima", value: "nik" },
        { text: "Nama Penerima", value: "penerima" },
        { text: "Tahun", value: "tahun", filter: this.caloriesFilter },
        { text: "Perguruan Tinggi", value: "perguruan_tinggi" },
        { text: "Asal Sekolah", value: "asal_sekolah" },
        { text: "Dusun", value: "dusun" },

        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        id_nokk: null,
        id_nik: null,
        nik: "",
        penerima: "",
        perguruan_tinggi: "",
        asal_sekolah: "",

        tahun: "",
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getSagasajaAll();

    this.detailUsers();
    this.getDusun();
  },
  methods: {
    caloriesFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.caloriesFilterValue) {
        return true;
      }

      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.caloriesFilterValue;
    },
    cekKAKA: function (row) {
      const credentials = {
        nik: this.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.editedItem.nik = response.data.message.nik;
            this.editedItem.penerima = response.data.message.nama;
            this.editedItem.id_nik = response.data.message.id;
            this.editedItem.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan Pada Database";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },

    // Data Bangunan
    getSagasajaAll: function () {
      AuthService.getSagasajaAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);

      this.statusdata = 1;
      this.pesancek = "";
      this.nik = this.editedItem.nik;
      this.dialogEdit = true;

      // this.editedItem.nik = this.editedItem.nik;
      // this.editedItem.nama = this.editedItem.nama;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteSagasaja(credentials);
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getSagasajaAll();

        this.$router.push("/admin-kelurahan/sagasaja/data").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.statusdata = 0;
      this.pesancek = "";
      // this.$refs.form.reset();
      this.dialogEdit = false;

      // this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },
    async updateSAGA() {
      this.loading = true;

      try {
        const credentials = {
          id: this.editedItem.id,
          tahun: this.editedItem.tahun,
          perguruan_tinggi: this.editedItem.perguruan_tinggi,
          asal_sekolah: this.editedItem.asal_sekolah,

          id_nokk: this.editedItem.id_nokk,
          nik: this.editedItem.nik,
          id_nik: this.editedItem.id_nik,
        };

        const response = await AuthService.editSagasaja(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil update SAGASAJA");
          this.getSagasajaAll();
          this.$router.push("/admin-kelurahan/sagasaja/data").catch(() => {});
          this.loading = false;
          this.closeEdit();
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.closeEdit();
        }
      } catch {
        this.loading = false;
        this.closeEdit();
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>



<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>





