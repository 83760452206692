<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Rumah Tinggal Kosong</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title
          ><h5>Apakah anda yakin mengahpus data ?</h5>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-list-item-content>
            <v-list-item-title>
              <h5 class="teal--text">
                Rumah Tinggal Kosong
              </h5></v-list-item-title
            >
            <v-list-item-subtitle>
              <h3 class="teal--text">
                {{ editedItem.nama_pemilik }}
              </h3></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogdetailrtk" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Detail Data : {{ editedItem.nama_pemilik }}</strong
            >
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogdetailrtk = false"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <DetailRumahKosongCom v-bind:detailrtkcom="detailrtkcom" />

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-skeleton-loader
      v-if="firstLoadhitung"
      :loading="sloadinghitung"
      type="article"
    ></v-skeleton-loader>
    <div v-else>
      <a v-on:click="tesData(tabdata.default)">
        <v-row dense>
          <v-col cols="12">
            <v-sheet outlined color="teal" elevation="0" rounded>
              <v-card>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-bold mb-4">Total Data Bangunan</div>
                    <v-list-item-title class="text-h5 mb-1"
                      >{{ hitungtb }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card></v-sheet
            >
          </v-col>
        </v-row>
      </a>
      <br />
      <v-row dense>
        <v-col cols="6" v-for="item in hasilhitung" :key="item.id">
          <a v-on:click="tesData(item.link)"
            ><v-sheet outlined elevation="0" rounded>
              <div v-if="item.link === tabdata.aktiftab">
                <v-card color="teal lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">{{ item.jenisBangunan }}</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ item.countBangunan }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <div v-else>
                <v-card>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">{{ item.jenisBangunan }}</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ item.countBangunan }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
            </v-sheet></a
          >
        </v-col>
      </v-row>
    </div>
    <br />

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <!-- <v-btn
              class="lighten-4"
              @click="detailRtk(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn> -->
            <v-btn
              class="lighten-4"
              :to="`../detail/${item.id_bangunan}`"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>
            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import DetailRumahKosongCom from "@/components/bangunan/RumahKosong.vue";
export default {
  name: "WirausahaData",
  components: {
    DetailRumahKosongCom,
  },
  data() {
    return {
      // Klik Per Data
      tabdata: {
        default: "all",
        aktiftab: "Rumah Tinggal Kosong",
      },

      // Data Sarana Umum
      datasarana: [{}],
      detailrtkcom: [{}],

      // Dialog
      dialogdetailrtk: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],
      // Loading Statistik
      sloadinghitung: true,
      firstLoadhitung: true,

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,

      headers: [
        { text: "No Bangunan", value: "no_bangunan" },
        { text: "Nama Pemilik", value: "nama_pemilik" },
        { text: "Dusun", value: "dusun" },
        { text: "Kelurahan/Desa", value: "kelurahan" },

        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      individuItem: {
        id: 0,
      },
      dialogDelete: false,

      dataindividu: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        id_bangunan: "",
        provinsi: "",
        nokk: "",
        id_dusun: "",
        status_kk: "",
        status_miskin: "",
        no_bangunan: "",
        jenis_kk: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
      },
      defaultItem: {
        nokk: "",
        no_bangunan: "",
        provinsi: "",
        id_dusun: "",

        kota: "",
        kecamatan: "",
        kelurahan: "",
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.dataRTK();
    this.getStatisktikBangunan();

    this.detailUsers();
    this.getDusun();
  },
  methods: {
    tesData: function (e) {
      console.log(e);

      if (e === "Sarana Umum") {
        this.$router.push("/admin-kelurahan/bangunan/data/sarana-umum");
      } else if (e === "all") {
        this.$router.push("/admin-kelurahan/bangunan/data");
      } else if (e === "Wirausaha") {
        this.$router.push("/admin-kelurahan/bangunan/data/wirausaha");
      } else if (e === "Rumah Tinggal Isi") {
        this.$router.push("/admin-kelurahan/bangunan/data/rumah-tinggal-isi");
      }
    },

    // Data Bangunan
    dataRTK: function () {
      AuthService.dataRTK().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    getStatisktikBangunan: function () {
      AuthService.getStatisktikBangunan().then(
        function (response) {
          this.hasilhitung = response.data.data;
          this.hitungtb = response.data.bangunan;
          this.firstLoadhitung = false;
          this.sloadinghitung = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    detailRtk(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogdetailrtk = true;

      this.detailrtkcom = item;
      console.log(item.nama);
    },

    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeTambah() {
      this.dialogTambahPenduduk = false;

      this.$refs.form.reset();
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
        kategori: "Rumah Tinggal Kosong",
        id_bangunan: this.editedItem.id_bangunan,
      };
      const response = await AuthService.deleteSelectBangunan(credentials);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.dataRTK();
        this.getStatisktikBangunan();

        this.$router
          .push("/admin-kelurahan/bangunan/data/rumah-tinggal-kosong")
          .catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    validate() {
      this.$refs.form.validate();
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>



<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>





