<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Form Input Data Sagasaja</h4></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-card v-else elevation="1">
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="simpanSAGA"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="nik"
                :rules="nokkRules"
                label="Nomor Induk Kependudukan (NIK)"
                required
                filled
                counter="16"
                maxlength="16"
                dense
                color="teal"
                hint="masukan no NIK lalu tekan -> cek nik"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-btn small dark @click="cekKAKA()" outlined color="teal">
                CEK NIK
              </v-btn>
              <h5 v-if="this.statusdata === 1" class="teal--text">
                {{ this.pesancek }}
              </h5>
              <h5 v-else-if="this.statusdata === 0" class="red--text">
                {{ this.pesancek }}
              </h5>
              <h5 v-else></h5>
            </v-col>
          </v-row>
          <v-divider class="pb-3"></v-divider>
          <v-row
            dense
            v-show="this.hasildatakk.nik !== null && this.statusdata === 1"
          >
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="hasildatakk.nik"
                readonly
                color="teal"
                label="Nomor Induk Kependudukan (NIK)"
                filled
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="hasildatakk.nama"
                readonly
                color="teal"
                label="Nama Lengkap"
                filled
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="tahun"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Pilih Tahun"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="perguruan_tinggi"
                color="teal"
                label="Masukan Perguruan Tinggi"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="asal_sekolah"
                color="teal"
                label="Masukan Asal Sekolah"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <br />

          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="this.hasildatakk.nik !== null && this.statusdata === 1"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              color="teal"
              class="mr-0 white--text"
            >
              Simpan Data
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <br />
  </v-container>
</template>



<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";

export default {
  name: "SagaForm",

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
  },
  data() {
    return {
      // Items Bansos

      // Data Bansos
      nik: "",

      tahun: "",
      perguruan_tinggi: "",
      asal_sekolah: "",

      hasildatakk: {
        id_nik: null,
        nik: null,
        nama: null,
        id_nokk: null,
      },
      pesancek: "",

      statusdata: null,

      // Loading bansos

      // DATE TGL LAHIR
      activePicker: null,
      date: null,
      menu: false,

      // VALIDASI

      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      // Loder
      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,
      dialogfinal: false,
      valid: true,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.textRows.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    cekKAKA: function (row) {
      const credentials = {
        nik: this.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;
            // this.hasildatakk.nokk = response.data.nokk;

            this.pesancek = "Data Ditemukan Pada Database";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },

    async simpanSAGA() {
      try {
        this.loading = true;
        const credentials = {
          tahun: this.tahun,
          perguruan_tinggi: this.perguruan_tinggi,
          asal_sekolah: this.asal_sekolah,

          id_nokk: this.hasildatakk.id_nokk,
          nik: this.hasildatakk.nik,
          id_nik: this.hasildatakk.id_nik,
        };

        const response = await AuthService.tambahSagasaja(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil simpan SAGASAJA");

          this.$router.push("/admin-kelurahan/sagasaja/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  created: function () {
    this.getDusun();
  },
  async mounted() {
    this.detailUsers();
  },
};
</script>








