<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Data Statistik - Posyandu</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="blue"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <!-- jenis jalan -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK TOTAL BALITA</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelSatu[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- status jalan -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK TOTAL IMUNISASI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatadua"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelDua[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK IMUNISASI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatiga"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelTiga[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KAPSUL BIRU</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataempat"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelEmpat[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KAPSUL MERAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalima"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelLima[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK OBAT CACING</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataenam"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelEnam[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK GIZI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData7"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel7[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK BBLR</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData8"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel8[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK LINGKAR KEPALA</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData9"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel9[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK INDEX KEHAMILAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData10"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel10[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK RESIKO KANDUNGAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData11"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel11[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK RIWAYAT KESEHATAN IBU</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData12"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel12[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK TABLET DARAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData13"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel13[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK LILLA</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData14"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel14[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK RIWAYAT KEHAMILAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData15"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel15[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK IMUNISASI IBU</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData16"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel16[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK ALAT KONTRASEPSI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData17"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel17[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK METODE KONTRASEPSI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData18"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKel18[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],

      // Chart
      panel: [0],
      chartDataKelSatu: [],
      chartDataKelDua: [],
      chartDataKelTiga: [],
      chartDataKelEmpat: [],
      chartDataKelLima: [],
      chartDataKelEnam: [],

      chartDataKel7: [],
      chartDataKel8: [],
      chartDataKel9: [],
      chartDataKel10: [],
      chartDataKel11: [],
      chartDataKel12: [],

      chartDataKel13: [],
      chartDataKel14: [],
      chartDataKel15: [],
      chartDataKel16: [],
      chartDataKel17: [],
      chartDataKel18: [],
      series: [44, 55, 41, 17, 15],

      chartData: "",
      chartDatadua: "",
      chartDatatiga: "",
      chartDataempat: "",
      chartDatalima: "",
      chartDataenam: "",

      chartData7: "",
      chartData8: "",
      chartData9: "",
      chartData10: "",
      chartData11: "",
      chartData12: "",

      chartData13: "",
      chartData14: "",
      chartData15: "",
      chartData16: "",
      chartData17: "",
      chartData18: "",
      chartOptions: {
        responsive: true,

        maintainAspectRatio: true,
        cutoutPercentage: 80,

        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsPosyandu();
  },
  methods: {
    stsPosyandu: function () {
      ServicePublik.stsPosyandu().then(
        function (response) {
          this.hasildatas = response.data.data[0];

          this.firstLoad = false;
          this.sloading = false;

          const responseData = this.hasildatas.data.total_balita;
          const responseDatadua = this.hasildatas.data.total_imunisasi;
          const responseDatatiga = this.hasildatas.data.imunisasi;
          const responseDataempat = this.hasildatas.data.kapsul_biru;
          const responseDatalima = this.hasildatas.data.kapsul_merah;
          const responseDataenam = this.hasildatas.data.obat_cacing;

          const responseData7 = this.hasildatas.data.gizi;
          const responseData8 = this.hasildatas.data.bblr;
          const responseData9 = this.hasildatas.data.lingkar;
          const responseData10 = this.hasildatas.data.index_kehamilan;
          const responseData11 = this.hasildatas.data.resiko_kandungan;
          const responseData12 = this.hasildatas.data.riwayat_kesehatan_ibu;

          const responseData13 = this.hasildatas.data.tablet_darah;
          const responseData14 = this.hasildatas.data.lilla;
          const responseData15 = this.hasildatas.data.riwayat_hamil;
          const responseData16 = this.hasildatas.data.imun_ibu;
          const responseData17 = this.hasildatas.data.alat_kontrasepsi;
          const responseData18 = this.hasildatas.data.metode_kontarepsi;

          const responseDataKel = this.hasildatas.kelurahan;

          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
          this.chartDatadua = {
            labels: responseDatadua.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatadua.map((item) => item.total),
              },
            ],
          };

          this.chartDatatiga = {
            labels: responseDatatiga.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatatiga.map((item) => item.total),
              },
            ],
          };

          this.chartDataempat = {
            labels: responseDataempat.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataempat.map((item) => item.total),
              },
            ],
          };

          this.chartDatalima = {
            labels: responseDatalima.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatalima.map((item) => item.total),
              },
            ],
          };
          this.chartDataenam = {
            labels: responseDataenam.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataenam.map((item) => item.total),
              },
            ],
          };

          this.chartData7 = {
            labels: responseData7.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData7.map((item) => item.total),
              },
            ],
          };
          this.chartData8 = {
            labels: responseData8.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData8.map((item) => item.total),
              },
            ],
          };

          this.chartData9 = {
            labels: responseData9.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData9.map((item) => item.total),
              },
            ],
          };

          this.chartData10 = {
            labels: responseData10.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData10.map((item) => item.total),
              },
            ],
          };

          this.chartData11 = {
            labels: responseData11.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData11.map((item) => item.total),
              },
            ],
          };
          this.chartData12 = {
            labels: responseData12.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData12.map((item) => item.total),
              },
            ],
          };

          this.chartData13 = {
            labels: responseData13.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData13.map((item) => item.total),
              },
            ],
          };
          this.chartData14 = {
            labels: responseData14.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData14.map((item) => item.total),
              },
            ],
          };

          this.chartData15 = {
            labels: responseData15.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData15.map((item) => item.total),
              },
            ],
          };

          this.chartData16 = {
            labels: responseData16.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData16.map((item) => item.total),
              },
            ],
          };

          this.chartData17 = {
            labels: responseData17.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData17.map((item) => item.total),
              },
            ],
          };
          this.chartData18 = {
            labels: responseData18.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData18.map((item) => item.total),
              },
            ],
          };

          // data kelurahan dua
          // data kelurahan dua
          responseDataKel.forEach((number, index) => {
            this.chartDataKelSatu[index] = {
              labels: number.data.total_balita.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.total_balita.map((item) => item.total),
                },
              ],
            };
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKelDua[index] = {
              labels: number.data.total_imunisasi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.total_imunisasi.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });

          responseDataKel.forEach((number, index) => {
            this.chartDataKelTiga[index] = {
              labels: number.data.imunisasi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.imunisasi.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKelEmpat[index] = {
              labels: number.data.kapsul_biru.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.kapsul_biru.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKelLima[index] = {
              labels: number.data.kapsul_merah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.kapsul_merah.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKelEnam[index] = {
              labels: number.data.obat_cacing.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.obat_cacing.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });

          responseDataKel.forEach((number, index) => {
            this.chartDataKel7[index] = {
              labels: number.data.gizi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.gizi.map((item) => item.total),
                },
              ],
            };
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel8[index] = {
              labels: number.data.bblr.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.bblr.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel9[index] = {
              labels: number.data.lingkar.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.lingkar.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel10[index] = {
              labels: number.data.index_kehamilan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.index_kehamilan.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel11[index] = {
              labels: number.data.resiko_kandungan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.resiko_kandungan.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel12[index] = {
              labels: number.data.riwayat_kesehatan_ibu.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.riwayat_kesehatan_ibu.map(
                    (item) => item.total
                  ),
                },
              ],
            };
            console.log(index);
          });

          responseDataKel.forEach((number, index) => {
            this.chartDataKel13[index] = {
              labels: number.data.tablet_darah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.tablet_darah.map((item) => item.total),
                },
              ],
            };
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel14[index] = {
              labels: number.data.lilla.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.lilla.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel15[index] = {
              labels: number.data.riwayat_hamil.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.riwayat_hamil.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel16[index] = {
              labels: number.data.imun_ibu.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.imun_ibu.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel17[index] = {
              labels: number.data.alat_kontrasepsi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.alat_kontrasepsi.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
          responseDataKel.forEach((number, index) => {
            this.chartDataKel18[index] = {
              labels: number.data.metode_kontarepsi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.metode_kontarepsi.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
        }.bind(this)
      );
    },
  },
  mounted() {
    this.renderChart(this.chartData);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
