<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Data Statistik - Penduduk</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="blue"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />
    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <!-- jenis KK -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JENIS KK</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS MISKIN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatadua"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak2[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- part dua-->
        <!-- jenis kelamin -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JENIS KELAMIN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS TINGGAL</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax1"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk2[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK AGAMA</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax2"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk3[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS PERKAWINAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax3"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk4[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KONDISI PEKERJAAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax4"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk5[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PEKERJAAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax5"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk6[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,
      sloadingdua: true,
      firstLoaddua: true,
      panel: [0],
      // chart DUA
      chartDatadua: "",
      chartDataduax: "",
      chartDatatigax: "",
      chartDataempatx: "",
      chartDatalimax: "",
      chartDataenamx: "",
      // kelurahan
      chartDataduak: [],
      chartDataduak2: [],
      chartDataduajk: [],

      chartDataduajk2: [],
      chartDataduajk3: [],
      chartDataduajk4: [],
      chartDataduajk5: [],
      chartDataduajk6: [],
      // Chart
      hasildatas: [{}],
      hasildatasdua: [{}],
      series: [44, 55, 41, 17, 15],
      chartData: "",
      chartDatax: "",
      chartDatax1: "",
      chartDatax2: "",
      chartDatax3: "",
      chartDatax4: "",
      chartDatax5: "",

      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataPenduduk();
    this.stsDataPendudukk();
  },
  methods: {
    stsDataPenduduk: function () {
      ServicePublik.stsDataPenduduk().then(
        function (response) {
          this.hasildatas = response.data.data[0];

          this.firstLoad = false;
          this.sloading = false;

          const responseData = this.hasildatas.data.jenis_kk;
          const responseDatadua = this.hasildatas.data.status_miskin;

          const responseDataKelurahan = this.hasildatas.kelurahan;

          // GRAFIK SATU
          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDatadua = {
            labels: responseDatadua.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatadua.map((item) => item.total),
              },
            ],
          };

          responseDataKelurahan.forEach((number, index) => {
            this.chartDataduak[index] = {
              labels: number.data.jenis_kk.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.jenis_kk.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak2[index] = {
              labels: number.data.status_miskin.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_miskin.map((item) => item.total),
                },
              ],
            };
            // console.log(index);
          });
        }.bind(this)
      );
    },
    stsDataPendudukk: function () {
      ServicePublik.stsDataPendudukk().then(
        function (response) {
          this.hasildatasdua = response.data.data[0];

          this.firstLoaddua = false;
          this.sloadingdua = false;

          // const responseData = this.hasildatas.data.jenis_kk;
          // const responseDatadua = this.hasildatas.data.status_miskin;

          const responseDataKelurahans = this.hasildatasdua.kelurahan;
          const responseDatax = this.hasildatasdua.data.jenis_kelamin;
          const responseDatax1 = this.hasildatasdua.data.status_tinggal;
          const responseDatax2 = this.hasildatasdua.data.agama;
          const responseDatax3 = this.hasildatasdua.data.status_kawin;
          const responseDatax4 = this.hasildatasdua.data.kondisi_pekerjaan;
          const responseDatax5 = this.hasildatasdua.data.pekerjaan;

          // console.log(this.hasildatasdua);

          // GRAFIK SATU
          this.chartDatax = {
            labels: responseDatax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax.map((item) => item.total),
              },
            ],
          };

          this.chartDatax1 = {
            labels: responseDatax1.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax1.map((item) => item.total),
              },
            ],
          };

          this.chartDatax2 = {
            labels: responseDatax2.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax2.map((item) => item.total),
              },
            ],
          };
          this.chartDatax3 = {
            labels: responseDatax3.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax3.map((item) => item.total),
              },
            ],
          };
          this.chartDatax4 = {
            labels: responseDatax4.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax4.map((item) => item.total),
              },
            ],
          };
          this.chartDatax5 = {
            labels: responseDatax5.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatax5.map((item) => item.total),
              },
            ],
          };

          responseDataKelurahans.forEach((number, index) => {
            this.chartDataduajk[index] = {
              labels: number.data.jenis_kelamin.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.jenis_kelamin.map((item) => item.total),
                },
              ],
            };

            this.chartDataduajk2[index] = {
              labels: number.data.status_tinggal.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_tinggal.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk3[index] = {
              labels: number.data.agama.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.agama.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk4[index] = {
              labels: number.data.status_kawin.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_kawin.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk5[index] = {
              labels: number.data.kondisi_pekerjaan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.kondisi_pekerjaan.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk6[index] = {
              labels: number.data.pekerjaan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                    "#46408c",
                    "#46408c",
                    "#ff3598",
                    "#1a89dd",
                    "#721b23",
                    "#5f239c",
                    "#0b5bb3",
                    "#875a65",
                    "#875a65",
                    "#4656d4",
                    "#85ecbf",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.pekerjaan.map((item) => item.total),
                },
              ],
            };
          });
        }.bind(this)
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
