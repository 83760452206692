import { render, staticRenderFns } from "./TernakSakit.vue?vue&type=template&id=6ce9fea6&scoped=true&"
import script from "./TernakSakit.vue?vue&type=script&lang=js&"
export * from "./TernakSakit.vue?vue&type=script&lang=js&"
import style0 from "./TernakSakit.vue?vue&type=style&index=0&id=6ce9fea6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce9fea6",
  null
  
)

export default component.exports