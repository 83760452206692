<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Data Statistik - Pertanian</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="blue"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <!-- jenis jalan -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK USAHA PERTANIAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelSatu[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- status jalan -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK LUAS SERANGAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatadua"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelDua[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KEBUTUHAN ALSINTAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatiga"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelTiga[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PRODUKSI PASCA PANEN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataempat"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelEmpat[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK LAHAN SAWAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalima"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataKelLima[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],

      // Chart
      panel: [0],
      chartDataKelSatu: [],
      chartDataKelDua: [],
      chartDataKelTiga: [],
      chartDataKelEmpat: [],
      chartDataKelLima: [],
      // chart DUA
      chartDatadua: "",
      chartDatatiga: "",
      chartDataempat: "",
      chartDatalima: "",
      series: [44, 55, 41, 17, 15],

      chartData: "",
      chartOptions: {
        responsive: true,

        maintainAspectRatio: true,
        cutoutPercentage: 80,

        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsPertanian();
  },
  methods: {
    stsPertanian: function () {
      ServicePublik.stsPertanian().then(
        function (response) {
          this.hasildatas = response.data.data[0];

          this.firstLoad = false;
          this.sloading = false;

          const responseData = this.hasildatas.data.usaha_tani;
          const responseDatadua = this.hasildatas.data.luas_srangan;
          const responseDatatiga = this.hasildatas.data.kebutuhan_alsintan;
          const responseDataempat = this.hasildatas.data.produksi_pasca;
          const responseDatalima = this.hasildatas.data.pemilik_sawah;

          const responseDataKel = this.hasildatas.kelurahan;

          // GRAFIK SATU
          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDatadua = {
            labels: responseDatadua.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatadua.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TIGA
          this.chartDatatiga = {
            labels: responseDatatiga.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatiga.map((item) => item.total),
              },
            ],
          };

          // GRAFIK EMPAT
          this.chartDataempat = {
            labels: responseDataempat.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempat.map((item) => item.total),
              },
            ],
          };

          this.chartDatalima = {
            labels: responseDatalima.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalima.map((item) => item.total),
              },
            ],
          };

          // Kelurahan
          // data kelurahan satu
          responseDataKel.forEach((number, index) => {
            this.chartDataKelSatu[index] = {
              labels: number.data.usaha_tani.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.usaha_tani.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });

          // data kelurahan dua
          responseDataKel.forEach((number, index) => {
            this.chartDataKelDua[index] = {
              labels: number.data.luas_srangan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.luas_srangan.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });

          responseDataKel.forEach((number, index) => {
            this.chartDataKelTiga[index] = {
              labels: number.data.kebutuhan_alsintan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.kebutuhan_alsintan.map(
                    (item) => item.total
                  ),
                },
              ],
            };
            console.log(index);
          });

          // data kelurahan dua
          responseDataKel.forEach((number, index) => {
            this.chartDataKelEmpat[index] = {
              labels: number.data.produksi_pasca.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.produksi_pasca.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });

          responseDataKel.forEach((number, index) => {
            this.chartDataKelLima[index] = {
              labels: number.data.pemilik_sawah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.pemilik_sawah.map((item) => item.total),
                },
              ],
            };
            console.log(index);
          });
        }.bind(this)
      );
    },
  },
  mounted() {
    this.renderChart(this.chartData);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
