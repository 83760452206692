<template>
  <v-card-text class="pt-5">
    <v-row dense>
      <v-col cols="12" md="12">
        <h3 class="teal--text pb-2">Rumah Tinggal Isi</h3>
        <v-divider class="pb-3"></v-divider>
      </v-col>

      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Nama Pemilik Rumah"
          :value="isibangunan.rtivalue[0].nama_pemilik"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Tempat Tinggal yang Ditempati"
          :value="isibangunan.rtivalue[0].tempat_tinggal"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Status Lahan Tempat Tinggal yang Ditempati"
          :value="isibangunan.rtivalue[0].status_lahan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field
          color="teal"
          label="Luas Lahan Bangunan"
          :value="isibangunan.rtivalue[0].luas_lahan + format.luas"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field
          color="teal"
          label="Luas Lantai Bangunan"
          :value="isibangunan.rtivalue[0].luas_lantai + format.luas"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jenis Lantai Tempat Tinggal yang Ditempati"
          :value="isibangunan.rtivalue[0].jenis_lantai"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Dinding Sebagian Besar Rumah"
          :value="isibangunan.rtivalue[0].dinding"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Status Jendela"
          :value="isibangunan.rtivalue[0].jendela"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Bahan Atap"
          :value="isibangunan.rtivalue[0].atap"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Penerangan Rumah"
          :value="isibangunan.rtivalue[0].penerangan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Energi Untuk Masak"
          :value="isibangunan.rtivalue[0].energi_masak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <template v-if="isibangunan.rtivalue[0].energi_masak === 'Kayu bakar'">
        <v-col cols="4" md="4">
          <v-text-field
            color="teal"
            label="Pilih Sumber Kayu Bakar"
            :value="isibangunan.rtivalue[0].kayu_bakar"
            readonly
            outlined
            required
            dense
          ></v-text-field>
        </v-col>
      </template>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Tempat Pembuangan Sampah"
          :value="isibangunan.rtivalue[0].tempat_pembuangan_sampah"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Fasilitas MCK"
          :value="isibangunan.rtivalue[0].fasilitas_mck"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Sumber Air Mandi Terbanyak Dari"
          :value="isibangunan.rtivalue[0].sumber_air_mandi"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Fasilitas Buang Air Besar"
          :value="isibangunan.rtivalue[0].fasilitas_bab"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Sumber Air Minum Terbanyak Dari"
          :value="isibangunan.rtivalue[0].sumber_air_minum"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Tempat Pembuangan Limbah Cair"
          :value="isibangunan.rtivalue[0].tempat_limbah"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Bangunan Rumah berada di bawah SUTET/SUTT/SUTTAS"
          :value="isibangunan.rtivalue[0].rumah_sutet"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Bangunan Rumah di bantaran sungai"
          :value="isibangunan.rtivalue[0].rumah_bantaran_sungai"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Rumah di lereng bukit/gunung"
          :value="isibangunan.rtivalue[0].rumah_lereng_bukit"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Memiliki Kendaraan Bermotor"
          :value="isibangunan.rtivalue[0].kendaraan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col
        v-for="(item, index) in isibangunan.rtivalue[0].kendaraan"
        :key="index"
        cols="2"
      >
        <v-text-field
          v-show="item === 'Mobil'"
          :rules="nomorRules"
          color="teal"
          :value="isibangunan.rtivalue[0].jumlah_mobil"
          label="Jumlah Mobil"
          outlined
          dense
          required
        ></v-text-field>
        <v-text-field
          v-show="item === 'Motor'"
          :rules="nomorRules"
          color="teal"
          :value="isibangunan.rtivalue[0].jumlah_motor"
          label="Jumlah Motor"
          outlined
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Secara Keseluruhan Kondisi Rumah"
          :value="isibangunan.rtivalue[0].kondisi_rumah"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider class="pt-0 pb-1"></v-divider>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Transportasi ke Pekerjaan Umum"
          :value="isibangunan.rtivalue[0].transportasi_umum"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Transportasi ke Lahan Pertanian"
          :value="isibangunan.rtivalue[0].transportasi_lahan_pertanian"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Transportasi ke Sekolah"
          :value="isibangunan.rtivalue[0].transportasi_sekolah"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Transportasi ke Berobat"
          :value="isibangunan.rtivalue[0].transportasi_berobat"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Transportasi ke Beribadah"
          :value="isibangunan.rtivalue[0].transportasi_beribadah"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Transportasi ke Rekreasi Terdekat"
          :value="isibangunan.rtivalue[0].transportasi_rekreasi"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Link Foto 360 derajat"
          :value="isibangunan.rtivalue[0].link_foto"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider class="pt-0 pb-1"></v-divider>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Masjid"
          :value="isibangunan.rtivalue[0].akses_jalan.a1 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Musaolla / Surau"
          :value="isibangunan.rtivalue[0].akses_jalan.a2 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Kantor Desa"
          :value="isibangunan.rtivalue[0].akses_jalan.a3 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Kantor Kecamatan"
          :value="isibangunan.rtivalue[0].akses_jalan.a4 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Kantor Kota"
          :value="isibangunan.rtivalue[0].akses_jalan.a5 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke TK"
          :value="isibangunan.rtivalue[0].akses_jalan.a6 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke PAUD"
          :value="isibangunan.rtivalue[0].akses_jalan.a7 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke SD"
          :value="isibangunan.rtivalue[0].akses_jalan.a8 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke SMP"
          :value="isibangunan.rtivalue[0].akses_jalan.a9 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke SMA"
          :value="isibangunan.rtivalue[0].akses_jalan.a10 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Perguruan Tinggi"
          :value="isibangunan.rtivalue[0].akses_jalan.a11 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Sekolah Agama SD Sederajat"
          :value="isibangunan.rtivalue[0].akses_jalan.a12 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Sekolah Agama SMP Sederajat"
          :value="isibangunan.rtivalue[0].akses_jalan.a13 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Sekolah Agama SMA Sederajat"
          :value="isibangunan.rtivalue[0].akses_jalan.a14 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Pesantren"
          :value="isibangunan.rtivalue[0].akses_jalan.a15 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Pasar"
          :value="isibangunan.rtivalue[0].akses_jalan.a16 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Rumah Sakit"
          :value="isibangunan.rtivalue[0].akses_jalan.a17 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Poliklinik"
          :value="isibangunan.rtivalue[0].akses_jalan.a18 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Rumah Sakit Bersalin"
          :value="isibangunan.rtivalue[0].akses_jalan.a19 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Puskesmas"
          :value="isibangunan.rtivalue[0].akses_jalan.a20 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Puskesma Pembantu / Pustu"
          :value="isibangunan.rtivalue[0].akses_jalan.a21 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Polindes"
          :value="isibangunan.rtivalue[0].akses_jalan.a22 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Poskesdes"
          :value="isibangunan.rtivalue[0].akses_jalan.a23 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Posyandu"
          :value="isibangunan.rtivalue[0].akses_jalan.a24 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Apotik"
          :value="isibangunan.rtivalue[0].akses_jalan.a25 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Toko Obat"
          :value="isibangunan.rtivalue[0].akses_jalan.a26 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Dokter Spesialis"
          :value="isibangunan.rtivalue[0].akses_jalan.a27 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Dokter Umum"
          :value="isibangunan.rtivalue[0].akses_jalan.a28 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Bidan"
          :value="isibangunan.rtivalue[0].akses_jalan.a29 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Tenaga Kesehatan"
          :value="isibangunan.rtivalue[0].akses_jalan.a30 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jarak ke Dukun"
          :value="isibangunan.rtivalue[0].akses_jalan.a31 + format.jarak"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider class="pt-0 pb-1"></v-divider>
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Kartu Keluarga</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.TotalKK }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Penduduk</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totalPenduduk }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Laki-Laki</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totalLakiLaki }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Perempuan</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totalPerempuan }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Menetap</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totalMenetap }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Merantau</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totalMerantau }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Disabilitas</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totaldisabilitas }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="4">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card color="white lighten-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">Total Stunting</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ isibangunan.totalStanting }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
      <v-col cols="12" class="pt-2">
        <br />
        <h4>Daftar Kartu Keluarga</h4>
        <v-divider class="pt3 pb-3"></v-divider>

        <div>
          <v-card elevation="2" color="teal lighten-3">
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-left">Nomor Kartu Keluarga</th>
                    <th class="text-left">Status Kemiskinan</th>
                    <th class="text-left">Status KK</th>

                    <th class="text-center">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in isibangunan.rtivalue[0].dataBangunan"
                    :key="item.id"
                  >
                    <td>{{ item.kartu_keluarga[0].nokk }}</td>
                    <td>{{ item.kartu_keluarga[0].status_miskin }}</td>
                    <td>{{ item.kartu_keluarga[0].status_kk }}</td>
                    <td class="text-center">
                      <v-btn
                        class="lighten-4"
                        @click="showdetailPenduduk(item)"
                        outlined
                        small
                        color="blue"
                      >
                        <v-icon dense center> mdi-information </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <br />
    <v-btn
      color="primary"
      target="_blank"
      :href="`https://maps.google.com/?q=${detailrticom.link_bangunan}`"
    >
      <v-icon dense center> mdi-map </v-icon> &nbsp;Lokasi Rumah Tinggal Isi
      &nbsp; <v-icon dense center> mdi-arrow-right </v-icon></v-btn
    >
    <v-dialog scrollable v-model="dialogpenduduk" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2">Anggota Keluarga</strong>
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogpenduduk = false"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>
        <v-card-text>
          <div v-for="itemindi in individuItem" :key="itemindi.id">
            <h3 class="cyan--text pt-3 pb-5">
              Data Individu ( {{ itemindi.StatusKeluarga }} )
            </h3>

            <v-row dense>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Nomor Induk Kependudukan (NIK)"
                  :value="itemindi.nik"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Nama Lengkap"
                  :value="itemindi.nama"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Jenis Kelamin"
                  :value="itemindi.jk"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Tempat Lahir"
                  :value="itemindi.tmpt_lahir"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Tanggal Lahir"
                  :value="itemindi.tgl_lahir"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Tinggal"
                  :value="itemindi.status_tinggal"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Agama"
                  :value="itemindi.agama"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Perkawinan"
                  :value="itemindi.status_kawin"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="itemindi.status_kawin === 'Kawin'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Tahun Pernikahan"
                    :value="itemindi.tahun_kawin"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Kewarganegaraan"
                  :value="itemindi.kewarganegaraan"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Hak Pilih Politik"
                  :value="itemindi.hak_politik"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-divider class="pa-0"></v-divider>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Pekerjaan"
                  :value="itemindi.status_pekerjaan"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="itemindi.status_pekerjaan === 'Bekerja'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Pekerjaan"
                    :value="itemindi.pekerjaan"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Pendidikan tertinggi yang ditamatkan"
                  :value="itemindi.pendidikan"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <template v-if="itemindi.status_jkn === 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Peserta Jaminan Kesehatan Nasional"
                    :value="itemindi.status_jkn"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Jaminan Kesehatan Nasional"
                    :value="itemindi.id_jkn"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="itemindi.status_jkn !== 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Peserta Jaminan Kesehatan Nasional"
                    :value="itemindi.status_jkn"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <template v-if="itemindi.penyakit === 'Ada'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Penyakit yang diderita 1 tahun terakhir"
                    :value="itemindi.penyakit"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Penyakit yang diderita"
                    :value="itemindi.id_penyakit"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="itemindi.penyakit !== 'Ada'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Penyakit yang diderita 1 tahun terakhir"
                    :value="itemindi.penyakit"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <template v-if="itemindi.disabilitas === 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Disabilitas"
                    :value="itemindi.disabilitas"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Data Disabilitas"
                    :value="itemindi.id_disabilitas"
                    filled
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="itemindi.disabilitas !== 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Disabilitas"
                    :value="itemindi.disabilitas"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Stunting"
                  :value="itemindi.stanting"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card color="grey lighten-4">
                  <v-card-title class="layout justify-center pa-0">
                    <h5>Foto KTP</h5></v-card-title
                  >
                  <img
                    v-bind:src="`https://apisipandu.fsetiawan.my.id/storage/FotoKTP/${itemindi.foto_ktp}`"
                    style="width: 100%; height: 420px"
                  />
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card color="grey lighten-4">
                  <v-card-title class="layout justify-center pa-0">
                    <h5>Foto Selfie</h5></v-card-title
                  >
                  <img
                    v-bind:src="`https://apisipandu.fsetiawan.my.id/storage/FotoSelfie/${itemindi.foto_selfie}`"
                    style="width: 100%; height: 420px"
                  />
                </v-card>
              </v-col>
              <v-col>
                <v-divider></v-divider>
                <br />
              </v-col>
            </v-row>
          </div>
          <br />
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>



<script>
export default {
  props: {
    isibangunan: Object,
    detailrticom: Object,
  },

  data() {
    return {
      format: {
        jarak: "  KM",
        luas: " m2",
      },
      dialogpenduduk: false,
      individuItem: [{}],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    showdetailPenduduk(item) {
      this.individuItem = item.anggota_kk;
      (this.dialogpenduduk = true), console.log(item.anggota_kk);
    },
  },
};
</script>