<template>
  <v-card elevation="0">
    <v-row dense>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="wirausahavalue.nama_usaha"
          label="Nama Usaha"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="wirausahavalue.pemilik_usaha"
          label="Nama Pemilik Usaha"
          filled
          dense
          required
        ></v-text-field>
      </v-col>

      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="wirausahavalue.jenis_usaha"
          label="Jenis Usaha"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="wirausahavalue.tahun_usaha"
          :items="years"
          item-text="year"
          item-value="year"
          color="teal"
          label="Tahun Mulai Usaha"
          filled
          dense
        ></v-select
      ></v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="wirausahavalue.status_operasional"
          :items="itemsoperasional"
          item-text="status_operational"
          item-value="status_operational"
          color="teal"
          label="Pilih Status Operasional"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="2" sm="2">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="wirausahavalue.jam_buka"
              label="Jam Buka"
              readonly
              filled
              dense
              color="teal"
              required
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="wirausahavalue.jam_buka"
            full-width
            @click:minute="$refs.menu.save(wirausahavalue.jam_buka)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" sm="2">
        <v-menu
          ref="menu"
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="wirausahavalue.jam_tutup"
              label="Jam Tutup"
              readonly
              color="teal"
              filled
              dense
              required
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu3"
            v-model="wirausahavalue.jam_tutup"
            full-width
            @click:minute="$refs.menu.save(wirausahavalue.jam_tutup)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="wirausahavalue.modal_usaha"
          :items="itemsmodalusaha"
          item-text="sumber_modal"
          item-value="sumber_modal"
          color="teal"
          label="Pilih Sumber Modal Usaha"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="wirausahavalue.izin_usaha"
          :items="itemsyatidak"
          item-text="optional"
          item-value="optional"
          color="teal"
          label="Memiliki Ijin Usaha"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="wirausahavalue.pajak_konsumen"
          :items="itemsyatidak"
          item-text="optional"
          item-value="optional"
          color="teal"
          label="Mengenakan Pajak Kepada Konsumen"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="wirausahavalue.link_foto"
          label="Link Foto 360 derajat"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="wirausahavalue.no_telp"
          label="Nomor Telepon"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <br />
    <v-card-actions>
      <v-btn class="white--text" @click="stepSebelum" color="teal" outlined
        ><v-icon color="black" left>mdi-arrow-left</v-icon>Sebelumnya
      </v-btn>
      <!-- <v-btn class="white--text" @click="cekSarana" color="green"
        >Cek Sarana
      </v-btn> -->
      <v-spacer></v-spacer>
      <template v-if="toStep !== index">
        <v-btn
          v-if="
            wirausahavalue.nama_usaha !== '' &&
            wirausahavalue.pemilik_usaha !== '' &&
            wirausahavalue.jenis_usaha !== '' &&
            wirausahavalue.tahun_usaha !== '' &&
            wirausahavalue.status_operasional !== '' &&
            wirausahavalue.jam_buka !== '' &&
            wirausahavalue.jam_tutup !== '' &&
            wirausahavalue.modal_usaha !== '' &&
            wirausahavalue.izin_usaha !== '' &&
            wirausahavalue.pajak_konsumen !== '' &&
            wirausahavalue.link_foto !== ''
          "
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          v-if="
            wirausahavalue.nama_usaha !== '' &&
            wirausahavalue.pemilik_usaha !== '' &&
            wirausahavalue.jenis_usaha !== '' &&
            wirausahavalue.tahun_usaha !== '' &&
            wirausahavalue.status_operasional !== '' &&
            wirausahavalue.jam_buka !== '' &&
            wirausahavalue.jam_tutup !== '' &&
            wirausahavalue.modal_usaha !== '' &&
            wirausahavalue.izin_usaha !== '' &&
            wirausahavalue.pajak_konsumen !== '' &&
            wirausahavalue.link_foto !== ''
          "
          class="white--text"
          @click="simpanData"
          color="teal"
          >Simpan Data
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="simpanData"
          color="teal"
          >Simpan Data
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>



<script>
export default {
  props: {
    index: Number,
    toStep: Number,
    itemsoperasional: Array,
    itemsmodalusaha: Array,
    itemsyatidak: Array,
  },
  data() {
    return {
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],

      menu2: false,
      time: null,
      menu3: false,

      wirausahavalue: {
        nama_usaha: "",
        pemilik_usaha: "",
        jenis_usaha: "",
        tahun_usaha: "",
        status_operasional: "",
        jam_buka: null,
        jam_tutup: null,
        modal_usaha: "",
        izin_usaha: "",
        pajak_konsumen: "",
        link_foto: "",
        no_telp: "",
      },
    };
  },

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1945 },
        (value, index) => 1946 + index
      );
    },
  },
  methods: {
    stepSebelum: function () {
      var hasilstep = this.index + 1;
      this.$emit("data-step", hasilstep);
    },
    stepLanjut: function () {
      var hasilsteplanjut = this.index + 3;
      this.$emit("data-steplanjut", hasilsteplanjut);
      this.$emit("data-wirausaha", this.wirausahavalue);
    },
    simpanData: function () {
      this.$emit("data-wirausaha", this.wirausahavalue);
      this.$emit("data-final", this.dialogfinal);
    },
  },
};
</script>