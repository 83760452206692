<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Data Peternakan - Usulan Bantuan</h4></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      outlined
                      :rules="wajibRules"
                      v-model="editedItem.kategori"
                      :items="itemskategori"
                      item-text="text"
                      item-value="text"
                      color="teal"
                      label="Pilih Kategori"
                      dense
                    ></v-select>
                  </v-col>
                  <template v-if="editedItem.kategori === 'Kelompok'">
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Kelompok"
                        outlined
                        :roles="wajibRules"
                        v-model="editedItem.nm_kelompok"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Jumlah Anggota"
                        outlined
                        :roles="nomorRules"
                        v-model="editedItem.jumlah_anggota"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Penyuluh"
                        outlined
                        :roles="wajibRules"
                        v-model="editedItem.nm_penyuluh"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>
                  <template v-if="editedItem.kategori === 'Perorangan'">
                    <v-col cols="12" md="9">
                      <v-text-field
                        label="NIK"
                        outlined
                        maxlength="16"
                        :counter="16"
                        v-model="editedItem.nik"
                        :rules="nomorduaRules"
                        color="teal"
                        required
                        dense
                        hint="masukan no NIK lalu tekan -> cek nik"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pl-7">
                      <v-btn
                        small
                        dark
                        @click="cekKAKA()"
                        outlined
                        color="teal"
                      >
                        CEK NIK
                      </v-btn>
                      <h6 v-if="this.statusdata === 1" class="teal--text">
                        {{ this.pesancek }}
                      </h6>
                      <h6 v-else-if="this.statusdata === 0" class="red--text">
                        {{ this.pesancek }}
                      </h6>
                      <h5 v-else></h5>
                    </v-col>
                    <v-row
                      class="pl-1 pr-1"
                      dense
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                    >
                      <v-col cols="12" md="6">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="hasildatakk.nik"
                          readonly
                          color="teal"
                          label="Nomor Induk Kependudukan (NIK)"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="hasildatakk.nama"
                          readonly
                          color="teal"
                          label="Nama Lengkap"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="editedItem.jenis_ternak"
                          color="teal"
                          label="Jenis Ternak"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="editedItem.luas_kandang"
                          color="teal"
                          label="Luasan Kandang"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="editedItem.luas_penggembala"
                          color="teal"
                          label="Luasan Pengembala"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialogDelete" persistent max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Yakin hapus data Usulan Bantuan ini ?</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br />
            <h4 class="brown--text">Kategori :{{ editedItem.kategori }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-card v-else elevation="0">
        <v-card-text>
          <v-data-table
            multi-sort
            :headers="headers"
            :items="hasildatas"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="center">
                <v-btn
                  class="lighten-4 mx-2"
                  @click="editItem(item)"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon dense> mdi-pencil </v-icon>
                </v-btn>
                <v-btn small @click="deleteItem(item)" outlined color="error">
                  <v-icon dense center> mdi-delete </v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "masterbarangvue",
  data() {
    return {
      date: null,

      modal: false,
      menu2: false,
      // ITEMs

      itemsmakanan: [{}],
      itemskesehatan: [{}],
      itemsrencana: [{}],
      hasildatas: [],

      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      // VALIDASI

      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      headers: [
        { text: "Kategori", value: "kategori" },
        { text: "Nama Kelompok", value: "nm_kelompok" },
        { text: "Nomor NIK", value: "nik" },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        kategori: "",
        nik: "",
        id_nik: "",
        id_nokk: "",
        nm_kelompok: "",
        jumlah_anggota: "",
        jenis_ternak: "",
        luas_kandang: "",
        luas_penggembala: "",
        nm_penyuluh: "",
      },
      defaultItem: {
        id: 0,
        kategori: "",
        nik: "",
        id_nik: "",
        id_nokk: "",
        nm_kelompok: "",
        jumlah_anggota: "",
        jenis_ternak: "",
        luas_kandang: "",
        luas_penggembala: "",
        nm_penyuluh: "",
      },
      nomorduaRules: [
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      hasildatakk: {
        id_nik: null,
        nik: null,
        nama: null,
        id_nokk: null,
      },
      pesancek: "",
      statusdata: null,

      itemskategori: ["Perorangan", "Kelompok"],

      itemsyatidak: [{}],
      itemsjk: [{}],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Usulan Bantuan"
        : "Form Edit Data Usulan Bantuan";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {
    this.getDataUsulanBantuanPeternakanAll();
    this.detailUsers();
    this.getDusun();
  },
  methods: {
    cekKAKA: function (row) {
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          // console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          this.kota = this.detailusers.kota;
          this.kecamatan = this.detailusers.kecamatan;
          this.kelurahan = this.detailusers.kelurahan;
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getDataUsulanBantuanPeternakanAll: function () {
      AuthService.getDataUsulanBantuanPeternakanAll().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          // console.log(this.hasildatas);
        }.bind(this)
      );
    },
    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          // console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      // console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteDataUsulanBantuanPeternakan(
        credentials
      );
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.getDataUsulanBantuanPeternakanAll();
        this.detailUsers();
        this.getDusun();

        this.$router
          .push("/admin-kelurahan/peternakan/usulan-bantuan")
          .catch(() => {});

        this.dialogDelete = this.closeDelete();
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          // console.log(this.editedItem);
          const credentials = {
            kategori: this.editedItem.kategori,
            nm_kelompok: this.editedItem.nm_kelompok,
            jumlah_anggota: this.editedItem.jumlah_anggota,
            nm_penyuluh: this.editedItem.nm_penyuluh,

            // id_nik: this.hasildatakk.id_nik,
            // id_nokk: this.hasildatakk.id_nokk,
            nik: this.hasildatakk.nik,

            jenis_ternak: this.editedItem.jenis_ternak,
            luas_kandang: this.editedItem.luas_kandang,
            luas_penggembala: this.editedItem.luas_penggembala,
          };
          const response = await AuthService.tambahDataUsulanBantuanPeternakan(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getDataUsulanBantuanPeternakanAll();
            this.detailUsers();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/peternakan/usulan-bantuan")
              .catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();
            console.log("berhasil simpan data");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            kategori: this.editedItem.kategori,
            nm_kelompok: this.editedItem.nm_kelompok,
            jumlah_anggota: this.editedItem.jumlah_anggota,
            nm_penyuluh: this.editedItem.nm_penyuluh,

            // id_nik: this.hasildatakk.id_nik,
            // id_nokk: this.hasildatakk.id_nokk,
            nik: this.hasildatakk.nik,

            jenis_ternak: this.editedItem.jenis_ternak,
            luas_kandang: this.editedItem.luas_kandang,
            luas_penggembala: this.editedItem.luas_penggembala,
          };
          const response = await AuthService.editDataUsulanBantuanPeternakan(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getDataUsulanBantuanPeternakanAll();
            this.detailUsers();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/peternakan/usulan-bantuan")
              .catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
