<template>
  <v-card-text class="pt-5">
    <v-row dense>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Nomor Bangunan"
          :value="detailwirausahacom.no_bangunan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Dusun"
          :value="detailwirausahacom.dusun"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Kelurahan/Desa"
          :value="detailwirausahacom.kelurahan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Kecamatan"
          :value="detailwirausahacom.kecamatan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Nama Usaha"
          :value="detailwirausahacom.nama_usaha"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Pemilik Usaha"
          :value="detailwirausahacom.nama_pemilik"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jenis Usaha"
          :value="detailwirausahacom.jenis_usaha"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Status Operasional"
          :value="detailwirausahacom.status_operational"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Tahun Mulai Usaha"
          :value="detailwirausahacom.tahun_mulai"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Sumber Modal Usaha"
          :value="detailwirausahacom.sumber_modal"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Memiliki Ijin Usaha"
          :value="detailwirausahacom.izin_usaha"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Mengenakan Pajak Konsumen"
          :value="detailwirausahacom.pajak_konsumen"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="12">
        <v-text-field
          color="teal"
          label="Link Foto 360 derajat"
          :value="detailwirausahacom.link_foto"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <br />
    <v-btn
      color="primary"
      target="_blank"
      :href="`https://maps.google.com/?q=${detailwirausahacom.link_bangunan}`"
    >
      <v-icon dense center> mdi-map </v-icon> &nbsp;Lokasi Wirausaha &nbsp;
      <v-icon dense center> mdi-arrow-right </v-icon></v-btn
    >
  </v-card-text>
</template>



<script>
export default {
  props: {
    detailwirausahacom: Object,
  },

  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>