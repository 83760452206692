<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Data Peternakan - Data Kelompok Ternak</h4></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="550px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      label="Nama Kelompok"
                      outlined
                      v-model="editedItem.nama_kelompok"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Nama Ketua"
                      outlined
                      v-model="editedItem.nm_ketua"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Nama Sekretaris"
                      outlined
                      v-model="editedItem.nm_sekretaris"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Nama Bendahara"
                      outlined
                      v-model="editedItem.nm_bendahara"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Jenis Usaha"
                      outlined
                      v-model="editedItem.jenis_usaha"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Alamat Usaha"
                      outlined
                      v-model="editedItem.alamat"
                      :rules="wajibRules"
                      color="teal"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h4>Tentukan Lokasi Kelompok Usaha Pada Peta :</h4>
                  </v-col>
                  <v-col v-if="editedIndex === -1" cols="12">
                    <MapMitra @data-peta="petaValue" />
                  </v-col>
                  <v-col v-else cols="12">
                    <MapMitraEdit
                      v-bind:markerslat="editedItem.lat"
                      v-bind:markerslong="editedItem.long"
                      @data-peta="petaValue"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialogDelete" persistent max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Yakin hapus data Kelompok Ternak ?</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br />
            <h4 class="brown--text">
              Nama Kelompok :{{ editedItem.nama_kelompok }}
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-card v-else elevation="0">
        <v-card-text>
          <v-data-table
            multi-sort
            :headers="headers"
            :items="hasildatas"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="center">
                <v-btn
                  class="lighten-4 mx-2"
                  @click="editItem(item)"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon dense> mdi-pencil </v-icon>
                </v-btn>
                <v-btn small @click="deleteItem(item)" outlined color="error">
                  <v-icon dense center> mdi-delete </v-icon>
                </v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.lokasi`]="{ item }">
              <h4 class="dark--text font-weight-bold">
                <v-btn
                  target="_blank"
                  :href="`https://maps.google.com/?q=${item.lokasi}`"
                  icon
                  color="pink"
                >
                  <v-icon>mdi-map-marker</v-icon>
                </v-btn>
              </h4>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import MapMitra from "@/components/MapMitra.vue";
import MapMitraEdit from "@/components/MapMitraEdit.vue";
export default {
  name: "masterbarangvue",
  components: {
    MapMitra,
    MapMitraEdit,
  },
  data() {
    return {
      date: null,

      modal: false,
      menu2: false,
      // ITEMS

      itemsmakanan: [{}],
      itemskesehatan: [{}],
      itemsrencana: [{}],
      hasildatas: [],

      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      // VALIDASI

      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      headers: [
        { text: "Nama Kelompok", value: "nama_kelompok", width: "160px" },
        { text: "Jenis Usaha", value: "jenis_usaha", width: "160px" },
        { text: "Alamat Usaha", value: "alamat", width: "200px" },
        { text: "Ketua", value: "nm_ketua", width: "170px" },
        { text: "Sekretaris", value: "nm_sekretaris", width: "120px" },
        { text: "Bendahara", value: "nm_bendahara", width: "120px" },
        { text: "Lokasi", value: "lokasi", width: "120px" },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "220px",
        },
      ],
      nomorduaRules: [
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,

        nama_kelompok: "",
        jenis_usaha: "",
        alamat: "",
        nm_ketua: "",
        nm_sekretaris: "",
        nm_bendahara: "",
        lat: "",
        long: "",
        lokasi: "",
      },
      defaultItem: {
        id: 0,

        nama_kelompok: "",
        jenis_usaha: "",
        alamat: "",
        nm_ketua: "",
        nm_sekretaris: "",
        nm_bendahara: "",
        lat: "",
        long: "",
        lokasi: "",
      },

      hasildatakk: {
        id_nik: null,
        nik: null,
        nama: null,
        id_nokk: null,
      },
      pesancek: "",
      statusdata: null,

      itemsbelum: ["Sudah", "Belum"],

      itemsyatidak: [{}],
      itemsjk: [{}],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Kelompok Ternak"
        : "Form Edit Kelompok Ternak";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {
    this.getKelompokTernakPeternakanAll();
    this.detailUsers();
    this.getDusun();
  },
  methods: {
    petaValue(petaValue) {
      this.editedItem.lat = petaValue.latitude;
      this.editedItem.long = petaValue.longitude;
    },
    cekKAKA: function (row) {
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          this.kota = this.detailusers.kota;
          this.kecamatan = this.detailusers.kecamatan;
          this.kelurahan = this.detailusers.kelurahan;
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getKelompokTernakPeternakanAll: function () {
      AuthService.getKelompokTernakPeternakanAll().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteKelompokTernakPeternakan(
        credentials
      );
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.getKelompokTernakPeternakanAll();
        this.detailUsers();
        this.getDusun();

        this.$router
          .push("/admin-kelurahan/peternakan/kelompok-ternak")
          .catch(() => {});

        this.dialogDelete = this.closeDelete();
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;

        this.statusdata = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan

          const credentials = {
            nama_kelompok: this.editedItem.nama_kelompok,
            nm_ketua: this.editedItem.nm_ketua,
            nm_sekretaris: this.editedItem.nm_sekretaris,
            nm_bendahara: this.editedItem.nm_bendahara,
            jenis_usaha: this.editedItem.jenis_usaha,
            alamat: this.editedItem.alamat,

            lat: this.editedItem.lat,
            long: this.editedItem.long,
          };
          const response = await AuthService.tambahKelompokTernakPeternakan(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getKelompokTernakPeternakanAll();
            this.detailUsers();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/peternakan/kelompok-ternak")
              .catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();
            console.log("berhasil simpan data");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            nama_kelompok: this.editedItem.nama_kelompok,
            nm_ketua: this.editedItem.nm_ketua,
            nm_sekretaris: this.editedItem.nm_sekretaris,
            nm_bendahara: this.editedItem.nm_bendahara,
            jenis_usaha: this.editedItem.jenis_usaha,
            alamat: this.editedItem.alamat,

            lat: this.editedItem.lat,
            long: this.editedItem.long,
          };
          const response = await AuthService.editKelompokTernakPeternakan(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getKelompokTernakPeternakanAll();
            this.detailUsers();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/peternakan/kelompok-ternak")
              .catch(() => {});
            this.dialog = false;

            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
