<template>
  <v-app id="inspire">
    <v-navigation-drawer fixed v-model="drawer" app>
      <v-sheet color="white lighten-4" class="pa-3">
        <!-- <v-avatar class="mb-4" size="62">
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
        </v-avatar> -->
        <v-container class="py-0 fill-height">
          <v-avatar class="profile" color="grey" size="40" tile>
            <v-img src="@/assets/liver.jpg"></v-img>
          </v-avatar>

          <h3 class="text-uppercase pl-2">{{ role }}</h3>

          <v-spacer></v-spacer>
        </v-container>
      </v-sheet>

      <v-divider></v-divider>
      <v-list v-show="role === 'superadmin'">
        <v-list-item active-class="teal--text" to="/dashboard/beranda" link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>

        <v-list-group
          active-class="teal--text"
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-wrap"
                v-text="child.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-show="role === 'provinsi'">
        <v-list-item
          active-class="teal--text"
          to="/admin-provinsi/beranda"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-show="role === 'kota'">
        <v-list-item active-class="teal--text" to="/admin-kota/beranda" link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-show="role === 'kecamatan'">
        <v-list-item
          active-class="teal--text"
          to="/admin-kecamatan/beranda"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-show="role === 'kelurahan'">
        <v-list-item
          active-class="teal--text"
          to="/admin-kelurahan/beranda"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar elevation="2" app dark color="white" class="black--text">
      <v-app-bar-nav-icon
        color="black"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <!-- <v-toolbar-title>SIPANDU KOTA PEKANBARU</v-toolbar-title> -->

      <v-spacer></v-spacer>
      <v-divider class="mx-4" vertical></v-divider>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar v-bind="attrs" v-on="on">
            <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
          </v-list-item-avatar>
          <h4>Hai. {{ nama }}</h4>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ nama }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group color="teal">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pengaturan Akun</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profil Saya</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="logout()">Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import AuthService from "@/services/AuthService.js";
export default {
  name: "AppLayout",
  data: () => ({
    dialog: false,
    nama: "",
    role: "",
    email: "",
    drawer: true,
    items: [
      {
        title: "Pengguna",
        action: "mdi-account-key",
        to: "/dashboard/pengguna",
        items: [{ title: "Data Pengguna", to: "/dashboard/pengguna" }],
      },
      {
        action: "mdi-bank",
        items: [
          { title: "Data Provinsi", to: "/dashboard/provinsi" },
          { title: "Data Kabupaten/Kota", to: "/dashboard/kota" },
          { title: "Data Kecamatan", to: "/dashboard/kecamatan" },
          { title: "Data Kelurahan/Desa", to: "/dashboard/kelurahan" },
          { title: "Data Dusun", to: "/dashboard/dusun" },
        ],
        title: "Data Wilayah",
      },
    ],
  }),

  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("/auth/login");
    }
    this.email = this.$store.getters.getUser.email;
    this.nama = this.$store.getters.getUser.name;
    this.role = this.$store.getters.getUser.role;
    // console.log(this.$store.getters.getUser.name);
    // this.secretMessage = await AuthService.getSecretContent();
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>
