<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet color="white lighten-4" class="pa-3">
        <!-- <v-avatar class="mb-4" size="62">
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
        </v-avatar> -->
        <v-container class="py-0 fill-height">
          <!-- <v-avatar class="profile" color="white" size="40">
            <v-img src="@/assets/prm.png"></v-img>
          </v-avatar> -->
          <v-img
            max-height="42px"
            max-width="33px"
            src="@/assets/prm.png"
          ></v-img>
          <h3 class="text-uppercase pl-2">ADMIN DESA</h3>

          <v-spacer></v-spacer>
        </v-container>
      </v-sheet>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          active-class="teal--text"
          to="/admin-kelurahan/beranda"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item>
        <h6 class="pa-3 grey--text">Data Master</h6>
        <v-list-group
          dense
          active-class="teal--text"
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="teal--text"
            v-for="child in item.items"
            :to="child.to"
            link
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-wrap"
                v-text="child.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <br />

        <!-- <h6 class="pa-3 grey--text">Data Rekapitulasi</h6>
        <h6 class="pa-3 grey--text">Data Statistik</h6> -->
      </v-list>
    </v-navigation-drawer>
    <v-app-bar elevation="2" app dark color="white" class="black--text">
      <v-app-bar-nav-icon
        color="black"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <h4 class="teal--text text-uppercase">
          Kelurahan / Desa - {{ this.kelurahan }}
        </h4>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar v-bind="attrs" v-on="on">
            <v-img src="@/assets/ava.jpg"></v-img>
          </v-list-item-avatar>
          <h4>Hai. {{ nama }}</h4>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img src="@/assets/ava.jpg"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ nama }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group color="teal">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn
                    block
                    class="red--text font-weight-bold"
                    @click="logout"
                    >LogOut</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "DashDesaLay",
  data: () => ({
    dialog: false,
    nama: "",
    role: "",
    email: "",
    kelurahan: "",
    drawer: true,
    items: [
      {
        title: "Penduduk",
        action: "mdi-monitor",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/penduduk/create" },
          { title: "Data Kartu Keluarga", to: "/admin-kelurahan/penduduk/kk" },
          { title: "Data Penduduk", to: "/admin-kelurahan/pendudukwarga" },
        ],
      },
      {
        title: "Bangunan",
        action: "mdi-bank",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/bangunan/create" },
          { title: "Data Bangunan", to: "/admin-kelurahan/bangunan/data" },
        ],
      },
      {
        title: "Bansos",
        action: "mdi-hand-heart-outline",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/bansos/create" },
          {
            title: "Data Bantuan Sosial",
            to: "/admin-kelurahan/bansos/data",
          },
        ],
      },
      {
        title: "Akses Jalan",
        action: "mdi-road-variant",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/akses-jalan/create" },
          {
            title: "Data Akses Jalan",
            to: "/admin-kelurahan/akses-jalan/data",
          },
        ],
      },
      {
        title: "Data Lahan",
        action: "mdi-pine-tree",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/lahan/create" },
          {
            title: "Data Lahan",
            to: "/admin-kelurahan/lahan/data",
          },
        ],
      },
      {
        title: "Data DTKS",
        action: "mdi-nature-people",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/dtks/create" },
          {
            title: "Data DTKS",
            to: "/admin-kelurahan/dtks/data",
          },
        ],
      },
      {
        title: "Data Sagasaja",
        action: "mdi-human",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/sagasaja/create" },
          {
            title: "Data Sagasaja",
            to: "/admin-kelurahan/sagasaja/data",
          },
        ],
      },
      {
        title: "Data SPPT-PBB",
        action: "mdi-cash",
        items: [
          { title: "Input Data", to: "/admin-kelurahan/sppt-pbb/create" },
          {
            title: "Data SPPT-PBB",
            to: "/admin-kelurahan/sppt-pbb/data",
          },
        ],
      },

      {
        title: "Data Dasawisma",
        action: "mdi-folder",
        items: [
          { title: "PKK", to: "/admin-kelurahan/dasawisma/pkk" },
          {
            title: "Kelahiran Bayi",
            to: "/admin-kelurahan/dasawisma/kelahiran-bayi",
          },
          {
            title: "Kematian Bayi",
            to: "/admin-kelurahan/dasawisma/kematian-bayi",
          },
        ],
      },
      {
        title: "Data Posyandu",
        action: "mdi-folder",
        items: [
          {
            title: "Data Balita",
            to: "/admin-kelurahan/posyandu/balita",
          },
          {
            title: "Ibu Hamil",
            to: "/admin-kelurahan/posyandu/ibu-hamil",
          },
          {
            title: "Keluarga Berencana",
            to: "/admin-kelurahan/posyandu/kb",
          },
        ],
      },
      {
        title: "Data Perikanan",
        action: "mdi-folder",
        items: [
          {
            title: "Ikan Budidaya",
            to: "/admin-kelurahan/perikanan/ikan-budidaya",
          },
          {
            title: "Ikan Tanggkap",
            to: "/admin-kelurahan/perikanan/ikan-tangkap",
          },
        ],
      },
      {
        title: "Data Peternakan",
        action: "mdi-folder",
        items: [
          {
            title: "Ternak Unggas",
            to: "/admin-kelurahan/peternakan/ternak-unggas",
          },
          {
            title: "Populasi",
            to: "/admin-kelurahan/peternakan/populasi",
          },
          {
            title: "Kemitraan",
            to: "/admin-kelurahan/peternakan/kemitraan",
          },
          {
            title: "Usulan Bantuan",
            to: "/admin-kelurahan/peternakan/usulan-bantuan",
          },
          {
            title: "Penyakit Ternak",
            to: "/admin-kelurahan/peternakan/penyakit-ternak",
          },
          {
            title: "Data Walet",
            to: "/admin-kelurahan/peternakan/walet",
          },
          {
            title: "Hewan Peliharan",
            to: "/admin-kelurahan/peternakan/hewan-peliharaan",
          },
          {
            title: "Kelompok Ternak",
            to: "/admin-kelurahan/peternakan/kelompok-ternak",
          },
          {
            title: "Data UPH",
            to: "/admin-kelurahan/peternakan/uph",
          },
        ],
      },
      {
        title: "Data Pertanian",
        action: "mdi-folder",
        items: [
          {
            title: "Potensi Petani",
            to: "/admin-kelurahan/pertanian/potensi-petani",
          },
          {
            title: "Penyakit Tanaman",
            to: "/admin-kelurahan/pertanian/penyakit-tanaman",
          },
          {
            title: "Data Alsintan",
            to: "/admin-kelurahan/pertanian/alsintan",
          },
          {
            title: "Alat Pasca Panen",
            to: "/admin-kelurahan/pertanian/pasca-panen",
          },
          {
            title: "Kelompok Tani",
            to: "/admin-kelurahan/pertanian/kelompok-tani",
          },
          {
            title: "Pemilikan Lahan Sawah",
            to: "/admin-kelurahan/pertanian/lahan-sawah",
          },
          {
            title: "Bantuan Pertanian",
            to: "/admin-kelurahan/pertanian/bantuan-pertanian",
          },
        ],
      },
    ],
  }),

  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("/");
    }
    this.email = this.$store.getters.getUser.email;
    this.nama = this.$store.getters.getUser.name;
    this.role = this.$store.getters.getUser.role;
    this.kelurahan = this.$store.getters.getUser.kelurahan;
    // console.log(this.$store.getters.getUser.name);
    // this.secretMessage = await AuthService.getSecretContent();
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>
