<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Form Input Data Bantuan Sosial</h4></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-card v-else elevation="1">
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="simpanBansos"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="nokk"
                :rules="nokkRules"
                label="Nomor Kartu Keluarga"
                required
                filled
                counter="16"
                maxlength="16"
                dense
                color="teal"
                hint="masukan no kartu keluarga lalu tekan -> cek kk"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-btn small dark @click="cekKAKA()" outlined color="teal">
                CEK KK
              </v-btn>
              <h5 v-if="this.statusdata === 1" class="teal--text">
                {{ this.pesancek }}
              </h5>
              <h5 v-else-if="this.statusdata === 0" class="red--text">
                {{ this.pesancek }}
              </h5>
              <h5 v-else></h5>
            </v-col>
          </v-row>
          <v-divider class="pb-3"></v-divider>
          <v-row
            dense
            v-show="this.hasildatakk.nik !== null && this.statusdata === 1"
          >
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="hasildatakk.nik"
                readonly
                color="teal"
                label="Nomor Induk Kependudukan (NIK)"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="hasildatakk.nama"
                readonly
                color="teal"
                label="Nama Lengkap"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="hasildatakk.status"
                readonly
                color="teal"
                label="Status Keluarga"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="tahun"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Pilih Tahun Bantuan"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="bulan"
                :items="itemsbulan"
                item-text="bulan"
                item-value="bulan"
                color="teal"
                label="Pilih Bulan Bantuan"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="kategori_bantuan"
                :items="itemkategoribansos"
                item-text="kategori_bantuan"
                item-value="kategori_bantuan"
                color="teal"
                label="Pilih Kategori Bantuan"
                filled
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="jenis_bantuan"
                :rules="wajibRules"
                label="Jenis Bantuan"
                required
                filled
                dense
                color="teal"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="besar_bantuan"
                label="Nilai Besar Bantuan"
                required
                filled
                dense
                @input="handleInput"
                color="teal"
              ></v-text-field
            ></v-col>
          </v-row>
          <br />

          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="this.hasildatakk.nik !== null && this.statusdata === 1"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              color="teal"
              class="mr-0 white--text"
            >
              Simpan Data
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <br />

    <v-dialog v-model="dialogfinal" persistent max-width="450px">
      <v-form
        @submit.prevent="simpanBangungan"
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin menyimpan data bangunan ?</h5></v-card-title
          >
          <v-divider></v-divider>

          <v-spacer></v-spacer>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click="dialogfinal = false"
              >Batal</v-btn
            >

            <v-btn
              dialogMeninggal="true"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              class="white--text"
              color="teal"
              >Proses Simpan Data</v-btn
            >
            <br />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>



<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";

export default {
  name: "BansosForm",

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
  },
  data() {
    return {
      // Items Bansos
      itemkategoribansos: [{}],
      itemsbulan: [
        "JANUARI",
        "FEBRUARI",
        "MARET",
        "APRIL",
        "MEI",
        "JUNI",
        "JULI",
        "AGUSTUS",
        "SEPTEMBER",
        "OKTOBER",
        "NOVEMBER",
        "DESEMBER",
      ],

      // Data Bansos
      nokk: "",
      tahun: "",
      bulan: "",
      kategori_bantuan: "",
      jenis_bantuan: "",
      besar_bantuan: "",
      hasildatakk: {
        nik: null,
        nama: null,
        status: null,
        id_nokk: null,
        id_nik: null,
        nokk: null,
      },
      pesancek: "",
      rp: "Rp.",
      statusdata: null,

      // Loading bansos

      // DATE TGL LAHIR
      activePicker: null,
      date: null,
      menu: false,

      // VALIDASI

      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      // Loder
      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,
      dialogfinal: false,
      valid: true,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.textRows.activePicker = "YEAR"));
    },
    value: {
      handler(after) {
        this.besar_bantuan = this.format(after);
      },
      immediate: true,
    },
  },
  methods: {
    // RUPIAH FORMAT
    format: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInput() {
      this.besar_bantuan = this.format(this.besar_bantuan);
      this.$emit(
        "v-textfield",
        (this.besar_bantuan + "").replace(/[0-9]/g, "")
      );
    },
    // DATA FINAL

    dialogFinal(dialogFinal) {
      this.dialogfinal = true;
      console.log("PREVIEW DATA");
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    getKategoriBantuan: function () {
      AuthService.getKategoriBantuan().then(
        function (response) {
          this.itemkategoribansos = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(response.data.data);
        }.bind(this)
      );
    },
    cekKAKA: function (row) {
      const credentials = {
        nokk: this.nokk,
      };
      AuthService.cekNikKk(credentials).then(
        function (response) {
          if (response.success === true) {
            (this.statusdata = 1),
              (this.hasildatakk.nik = response.data.anggotaKK[0].nik);
            this.hasildatakk.nama = response.data.anggotaKK[0].nama;
            this.hasildatakk.status = response.data.anggotaKK[0].StatusKeluarga;
            this.hasildatakk.id_nokk = response.data.id;
            this.hasildatakk.id_nik = response.data.anggotaKK[0].id;
            this.hasildatakk.nokk = response.data.nokk;
            this.pesancek = "Data Ditemukan Pada Database";
          } else {
            (this.statusdata = 0),
              (this.pesancek = "Data Kartu Keluarga Tidak Ditemukan");
          }

          //   this.textRows.forEach((cekisi) => {
          //     cekisi.hasilcek = response.message;
          //     row.ceknikst = response.success;
          //     if (row.ceknikst === true) {
          //       row.id_nokk = response.message.id;
          //       row.nokk = response.message.nokk;
          //     }
          //     // console.log(cekisi.hasilcek.nokk);
          //   });

          // console.log(row);
          // console.log(row.ceknikst);
        }.bind(this)
      );
    },

    async simpanBansos() {
      try {
        this.loading = true;
        const credentials = {
          tahun: this.tahun,
          bulan: this.bulan,
          kategori_bantuan: this.kategori_bantuan,
          nokk: this.hasildatakk.nokk,
          id_nokk: this.hasildatakk.id_nokk,
          nik: this.hasildatakk.nik,
          id_nik: this.hasildatakk.id_nik,

          jenis_bantuan: this.jenis_bantuan,
          besar_bantuan: this.besar_bantuan.replace(/[^0-9]/g, ""),
        };

        const response = await AuthService.tambahBantuan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil simpan bansos");

          this.$router.push("/admin-kelurahan/bansos/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  created: function () {
    this.getDusun();
    this.getKategoriBantuan();
  },
  async mounted() {
    this.detailUsers();
  },
};
</script>








