<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data SPPT-PBB</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title
          ><h5>Apakah yakin hapus data SPPT-PBB ?</h5>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-list-item-content>
            <v-list-item-title>
              <h5 class="teal--text">Nama Wajib Pajak</h5></v-list-item-title
            >
            <v-list-item-subtitle>
              <h3 class="teal--text">
                {{ editedItem.nama_pajak }}
              </h3></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogEdit" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Edit Data SPPT-PBB : {{ editedItem.nama_pajak }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeEdit"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="updatePBB"
            v-model="valid"
            lazy-validation
          >
            <br />

            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  color="teal"
                  :value="this.kecamatan"
                  label="Kecamatan"
                  filled
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  color="teal"
                  :value="this.kelurahan"
                  label="Kelurahan / Desa"
                  filled
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="dusunRules"
                  v-model="editedItem.id_dusun"
                  :items="itemsdusun"
                  item-text="dusun"
                  item-value="id"
                  color="teal"
                  label="Pilih Dusun"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.tahun"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Pembayaran Tahun"
                  filled
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nama_pajak"
                  color="teal"
                  label="Nama Wajib Pajak"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.luas_bumi"
                  color="teal"
                  label="Luas Bumi"
                  filled
                  dense
                  hint=" satuan (m2)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.luas_bangunan"
                  color="teal"
                  label="Luas Bangunan"
                  filled
                  dense
                  hint=" satuan (m2)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.no_bangunan"
                  :items="itemsnobang"
                  item-text="no_bangunan"
                  item-value="no_bangunan"
                  color="teal"
                  label="Pilih Nomor Bangunan"
                  filled
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.status_pembayaran"
                  :items="itemspbb"
                  item-text="status_spt"
                  item-value="status_spt"
                  color="teal"
                  label="Pilih Status Pembayaran"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="dusunRules"
                  v-model="editedItem.status_thn_lalu"
                  :items="itemstahunlalu"
                  item-text="thn_lalu"
                  item-value="thn_lalu"
                  color="teal"
                  label="Pilih Status Pembayaran Tahun Sebelumnya"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col
                v-show="this.editedItem.status_thn_lalu === 'Terhutang'"
                cols="12"
                md="4"
              >
                <v-select
                  v-model="editedItem.thn_hutang"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Pilih Tahun Terhutang"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col
                v-show="this.editedItem.status_thn_lalu === 'Terhutang'"
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.bayr_thn_lalu"
                  color="teal"
                  label="Total Pembayaran biaya tahun sebelumnya"
                  filled
                  dense
                  @input="handleInput"
                  hint=" hanya angka"
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                :disabled="!valid"
                type="submit"
                color="teal"
                class="mr-0 white--text"
              >
                Update Data
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogDetail" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Detail Data SPPT-PBB : {{ editedItem.nama_pajak }}</strong
            >
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogDetail = false"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <br />
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.kecamatan"
                readonly
                color="teal"
                label="Kecamatan"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.kelurahan"
                readonly
                color="teal"
                label="Kelurahan/Desa"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.dusun"
                readonly
                color="teal"
                label="Dusun"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="editedItem.tahun"
                :items="years"
                readonly
                item-text="year"
                item-value="year"
                color="teal"
                label="Pembayaran Tahun"
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.nama_pajak"
                readonly
                color="teal"
                label="Nama Wajib Pajak"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :value="editedItem.luas_bumi + formatuk"
                color="teal"
                readonly
                label="Luas Bumi"
                filled
                dense
                hint=" satuan (m2)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :value="editedItem.luas_bangunan + formatuk"
                color="teal"
                label="Luas Bangunan"
                filled
                readonly
                dense
                hint=" satuan (m2)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :value="editedItem.no_bangunan"
                color="teal"
                label="Nomor Bangunan"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :value="editedItem.status_pembayaran"
                color="teal"
                label="Status Pembayaran"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="editedItem.status_thn_lalu"
                color="teal"
                label="Pilih Status Pembayaran Tahun Sebelumnya"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-show="editedItem.status_thn_lalu === 'Terhutang'"
              cols="12"
              md="4"
            >
              <v-text-field
                :value="editedItem.thn_hutang"
                color="teal"
                label="Pilih Tahun Terhutang"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-show="editedItem.status_thn_lalu === 'Terhutang'"
              cols="12"
              md="4"
            >
              <v-text-field
                :value="editedItem.bayr_thn_lalu"
                color="teal"
                label="Total Pembayaran biaya tahun sebelumnya"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-divider></v-divider>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-2"
              @click="detailData(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>
            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>

            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "SpptData",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
  },
  data() {
    return {
      // Items Sppt
      itemspbb: [{}],
      itemstahunlalu: [{}],
      itemsnobang: [{}],
      // Data sptt
      itemsdusun: [{}],

      formatuk: " m2",
      // Data Bansos

      pesancek: "",
      statusdata: 1,
      datailusi: {},

      // Dialog
      dialogEdit: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,
      dialogDetail: false,

      kecamatan: "",
      kelurahan: "",
      id_dusun: "",

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: "Tahun", value: "tahun" },
        { text: "Nama Wajib Pajak", value: "nama_pajak" },
        { text: "No Bangunan", value: "no_bangunan" },
        { text: "Status Pembayaran", value: "status_pembayaran" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        kecamatan: "",
        kelurahan: "",
        id_dusun: "",
        tahun: "",
        nama_pajak: "",
        luas_bumi: "",
        luas_bangunan: "",
        no_bangunan: null,
        id_bangunan: "",
        status_pembayaran: "",
        status_thn_lalu: "",
        thn_hutang: "",
        bayr_thn_lalu: "",
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    value: {
      handler(after) {
        this.editedItem.bayr_thn_lalu = this.format(after);
      },
      immediate: true,
    },
  },

  created() {
    this.getSpptpbbAll();
    this.getStatusSPTPBB();
    this.getStatusThnLalu();
    this.nomorBangunan();

    this.detailUsers();
    this.getDusun();
  },
  methods: {
    format: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInput() {
      this.editedItem.bayr_thn_lalu = this.format(
        this.editedItem.bayr_thn_lalu
      );
      this.$emit(
        "v-textfield",
        (this.editedItem.bayr_thn_lalu + "").replace(/[0-9]/g, "")
      );
    },
    // Data Bangunan
    getSpptpbbAll: function () {
      AuthService.getSpptpbbAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },
    getStatusSPTPBB: function () {
      AuthService.getStatusSPTPBB().then(
        function (response) {
          this.itemspbb = response.data.data;
        }.bind(this)
      );
    },
    getStatusThnLalu: function () {
      AuthService.getStatusThnLalu().then(
        function (response) {
          this.itemstahunlalu = response.data.data;
        }.bind(this)
      );
    },
    nomorBangunan: function () {
      AuthService.nomorBangunan().then(
        function (response) {
          this.itemsnobang = response.data.data;
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);

      this.dialogEdit = true;

      // this.editedItem.nik = this.editedItem.nik;
      // this.editedItem.nama = this.editedItem.nama;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);

      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },
    detailData(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDetail = true;

      console.log(item.nama_pajak);
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteSpptpbb(credentials);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getSpptpbbAll();

        this.$router.push("/admin-kelurahan/sppt-pbb/data").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.statusdata = 0;
      this.pesancek = "";
      // this.$refs.form.reset();
      this.dialogEdit = false;

      // this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },
    async updatePBB() {
      this.loading = true;
      console.log(this.editedItem);

      if (this.editedItem.bayr_thn_lalu !== null) {
        try {
          const credentials = {
            id: this.editedItem.id,
            id_dusun: this.editedItem.id_dusun,
            nama_pajak: this.editedItem.nama_pajak,
            luas_bumi: this.editedItem.luas_bumi,
            luas_bangunan: this.editedItem.luas_bangunan,
            tahun: this.editedItem.tahun,
            status_pembayaran: this.editedItem.status_pembayaran,
            status_thn_lalu: this.editedItem.status_thn_lalu,
            thn_hutang: this.editedItem.thn_hutang,
            bayr_thn_lalu: this.editedItem.bayr_thn_lalu.replace(/[^0-9]/g, ""),

            no_bangunan: this.editedItem.no_bangunan,
            id_bangunan: this.editedItem.id_bangunan,
          };

          const response = await AuthService.editSpptpbb(credentials);
          console.log("berhasil update PBB");
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 10,
            });
            console.log("berhasil update PBB");
            this.getSpptpbbAll();
            this.$router.push("/admin-kelurahan/sppt-pbb/data").catch(() => {});
            this.loading = false;
            this.closeEdit();
          } else {
            this.$notification.error(response.message, {
              timer: 10,
            });
            this.loading = false;
            this.closeEdit();
          }
        } catch {
          this.loading = false;
          this.closeEdit();
          console.log("gagal");
        }
      } else {
        try {
          const credentials = {
            id: this.editedItem.id,
            id_dusun: this.editedItem.id_dusun,
            nama_pajak: this.editedItem.nama_pajak,
            luas_bumi: this.editedItem.luas_bumi,
            luas_bangunan: this.editedItem.luas_bangunan,
            tahun: this.editedItem.tahun,
            status_pembayaran: this.editedItem.status_pembayaran,
            status_thn_lalu: this.editedItem.status_thn_lalu,
            thn_hutang: this.editedItem.thn_hutang,

            no_bangunan: this.editedItem.no_bangunan,
            id_bangunan: this.editedItem.id_bangunan,
          };

          const response = await AuthService.editSpptpbb(credentials);
          console.log("berhasil update PBB");
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 10,
            });
            console.log("berhasil update PBB");
            this.getSpptpbbAll();
            this.$router.push("/admin-kelurahan/sppt-pbb/data").catch(() => {});
            this.loading = false;
            this.closeEdit();
          } else {
            this.$notification.error(response.message, {
              timer: 10,
            });
            this.loading = false;
            this.closeEdit();
          }
        } catch {
          this.loading = false;
          this.closeEdit();
          console.log("gagal");
        }
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
