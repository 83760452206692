<template>
  <v-card elevation="0">
    <v-row dense>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="saranvalue.jenis_saranaumum"
          :items="dataitem"
          item-text="jenis_sarana_umum"
          item-value="jenis_sarana_umum"
          color="teal"
          label="Pilih Jenis Sarana Umum"
          filled
          dense
        ></v-select>
      </v-col>

      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="saranvalue.nama"
          label="Nama Sarana Umum"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="saranvalue.jenis_bangunan"
          :items="itemsjenisbangunan"
          item-text="jenis_bangunan"
          item-value="jenis_bangunan"
          color="teal"
          label="Pilih Jenis Bangunan"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="saranvalue.status_bangunan"
          :items="itemsstatusbangunan"
          item-text="status_bangunan"
          item-value="status_bangunan"
          color="teal"
          label="Pilih Jenis Status Bangunan"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="saranvalue.status_lahan"
          :items="itemsstatuslahan"
          item-text="status_lahan"
          item-value="status_lahan"
          color="teal"
          label="Pilih Jenis Status Lahan"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="saranvalue.tahun_bangun"
          :items="years"
          item-text="year"
          item-value="year"
          color="teal"
          label="Di Bangun Tahun "
          filled
          dense
        ></v-select
      ></v-col>

      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="saranvalue.penanggung_jawab"
          label="Penanggung Jawab"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="saranvalue.no_telp"
          label="Nomor Telepon"
          filled
          dense
          type="number"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="saranvalue.link_foto"
          label="Link Foto 360 derajat"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <v-card-actions>
      <v-btn class="white--text" @click="stepSebelum" color="teal" outlined
        ><v-icon color="black" left>mdi-arrow-left</v-icon>Sebelumnya
      </v-btn>
      <!-- <v-btn class="white--text" @click="cekSarana" color="green"
        >Cek Sarana
      </v-btn> -->
      <v-spacer></v-spacer>
      <template v-if="toStep !== index">
        <v-btn
          v-if="
            saranvalue.jenis_saranaumum !== '' &&
            saranvalue.nama !== '' &&
            saranvalue.jenis_bangunan !== '' &&
            saranvalue.status_bangunan !== '' &&
            saranvalue.status_lahan !== '' &&
            saranvalue.penanggung_jawab !== '' &&
            saranvalue.link_foto !== '' &&
            saranvalue.no_telp !== '' &&
            saranvalue.tahun_bangun !== ''
          "
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          v-if="
            saranvalue.jenis_saranaumum !== '' &&
            saranvalue.nama !== '' &&
            saranvalue.jenis_bangunan !== '' &&
            saranvalue.status_bangunan !== '' &&
            saranvalue.status_lahan !== '' &&
            saranvalue.penanggung_jawab !== '' &&
            saranvalue.link_foto !== '' &&
            saranvalue.no_telp !== '' &&
            saranvalue.tahun_bangun !== ''
          "
          class="white--text"
          @click="simpanData"
          color="teal"
          >Update Data
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="simpanData"
          color="teal"
          >Update Data
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    index: Number,
    toStep: Number,
    dataitem: Array,
    itemsjenisbangunan: Array,
    itemsstatusbangunan: Array,
    itemsstatuslahan: Array,
    isibangunan: Object,
    cekado: Object,
  },
  data() {
    return {
      dialogfinal: false,
      // VALIDASI
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      // DATA SARANA
      saranvalue: {
        id: "",
        jenis_saranaumum: "",
        nama: "",
        jenis_bangunan: "",
        status_bangunan: "",
        status_lahan: "",
        tahun_bangun: "",
        penanggung_jawab: "",
        no_telp: "",
        link_foto: "",
      },
    };
  },
  mounted() {
    console.log(this.cekado.saranastep);
    if (this.cekado.saranastep === false) {
      this.saranvalue.id = "";
      this.saranvalue.jenis_saranaumum = "";
      this.saranvalue.nama = "";
      this.saranvalue.jenis_bangunan = "";
      this.saranvalue.status_bangunan = "";
      this.saranvalue.status_lahan = "";
      this.saranvalue.tahun_bangun = "";
      this.saranvalue.penanggung_jawab = "";
      this.saranvalue.no_telp = "";
      this.saranvalue.link_foto = "";
    } else {
      this.saranvalue.id = this.isibangunan.saranavalue.id;
      this.saranvalue.jenis_saranaumum =
        this.isibangunan.saranavalue.jenis_saranaumum;
      this.saranvalue.nama = this.isibangunan.saranavalue.nama;
      this.saranvalue.jenis_bangunan =
        this.isibangunan.saranavalue.jenis_bangunan;
      this.saranvalue.status_bangunan =
        this.isibangunan.saranavalue.status_bangunan;
      this.saranvalue.status_lahan = this.isibangunan.saranavalue.status_lahan;
      this.saranvalue.tahun_bangun = parseInt(
        this.isibangunan.saranavalue.tahun_bangun
      );
      this.saranvalue.penanggung_jawab =
        this.isibangunan.saranavalue.penanggung_jawab;
      this.saranvalue.no_telp = this.isibangunan.saranavalue.no_tlp;
      this.saranvalue.link_foto = this.isibangunan.saranavalue.link_foto;
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1045 },
        (value, index) => 1981 + index
      );
    },
  },

  methods: {
    stepSebelum: function () {
      var hasilstep = this.index + 1;
      this.$emit("data-step", hasilstep);
    },
    stepLanjut: function () {
      var hasilsteplanjut = this.index + 3;
      this.$emit("data-steplanjut", hasilsteplanjut);
      this.$emit("data-sarana", this.saranvalue);
    },
    cekSarana: function () {
      this.$emit("data-sarana", this.saranvalue);
    },

    simpanData: function () {
      this.$emit("data-sarana", this.saranvalue);
      this.$emit("data-final", this.dialogfinal);
    },
  },
};
</script>