import { render, staticRenderFns } from "./StsLahan.vue?vue&type=template&id=583a6e18&scoped=true&"
import script from "./StsLahan.vue?vue&type=script&lang=js&"
export * from "./StsLahan.vue?vue&type=script&lang=js&"
import style0 from "./StsLahan.vue?vue&type=style&index=0&id=583a6e18&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583a6e18",
  null
  
)

export default component.exports