<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Data Posyandu - Data Ibu Hamil</h4></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="950px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="NIK"
                      outlined
                      maxlength="16"
                      :counter="16"
                      v-model="editedItem.nik"
                      :rules="nomorduaRules"
                      color="teal"
                      required
                      dense
                      hint="masukan no NIK lalu tekan -> cek nik"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pl-1">
                    <v-btn small dark @click="cekKAKA()" outlined color="teal">
                      CEK NIK
                    </v-btn>
                    <h6 v-if="this.statusdata === 1" class="teal--text">
                      {{ this.pesancek }}
                    </h6>
                    <h6 v-else-if="this.statusdata === 0" class="red--text">
                      {{ this.pesancek }}
                    </h6>
                    <h5 v-else></h5>
                  </v-col>
                  <v-row
                    class="pl-1 pr-1"
                    dense
                    v-show="
                      this.hasildatakk.nik !== null && this.statusdata === 1
                    "
                  >
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.nokk"
                        readonly
                        color="teal"
                        label="Nomor KK"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.nama"
                        readonly
                        color="teal"
                        label="Nama Lengkap"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.jk"
                        readonly
                        color="teal"
                        label="Jenis Kelamin"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.tanggal_lahir"
                        readonly
                        color="teal"
                        label="Tanggal Lahir"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.tempat_lahir"
                        readonly
                        color="teal"
                        label="Tempat Lahir"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.no_bangunan"
                        readonly
                        color="teal"
                        label="No Bangunan "
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.usia_kandungan"
                        :items="itemskandungan"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Pilih Usia Kandungan"
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        color="teal"
                        v-model="editedItem.golongan_darah"
                        label="Golongan Darah"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.index_massa_tubuh"
                        :items="itemsmasa"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Pilih Index Masa Tubuh"
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.kondisi_kandungan"
                        :items="itemskondisi"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Pilih Kondisi Kandungan "
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="8">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.riwayat"
                        :items="itemsriwayat"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Pilih Riwayat Kesehatan"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.tablet_tambah_darah"
                        :items="itemsyatidak"
                        item-text="optional"
                        item-value="optional"
                        color="teal"
                        label="Pemberian Tablet Tambah Darah"
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.lila"
                        :items="itemslila"
                        item-text="optional"
                        item-value="optional"
                        color="teal"
                        label="Pilih LILA"
                        outlined
                        dense
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.riwayat_hamil"
                        :items="itemskehamilan"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Riwayat Kehamilan dan Persalinan"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.riwayat_tt"
                        :items="itemsimunt"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Status Imusisasi T"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        color="teal"
                        v-model="editedItem.cacatan_kesehatan"
                        label="Catatan Kesehatan Lainnya (Abortus, dll)"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <template
                  v-if="this.hasildatakk.nik !== null && this.statusdata === 1"
                >
                  <v-btn
                    v-if="this.editedIndex === -1"
                    dialog="true"
                    :loading="loading"
                    :disabled="!valid"
                    @click="validate"
                    type="submit"
                    class="ma-2 white--text"
                    color="teal"
                  >
                    Simpan Data
                  </v-btn>
                  <v-btn
                    v-else
                    dialog="true"
                    :loading="loading"
                    :disabled="!valid"
                    @click="validate"
                    type="submit"
                    class="ma-2 white--text"
                    color="teal"
                  >
                    Update Data
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn class="ma-2" disabled outlined color="grey">
                    Simpan Data
                  </v-btn>
                </template>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDetail" persistent max-width="950px">
      <v-card>
        <v-card-title><h5>Detail Data Ibu Hamil</h5> </v-card-title>
        <v-divider></v-divider>
        <v-form
          @submit.prevent="handleSubmit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  label="NIK"
                  outlined
                  maxlength="16"
                  :counter="16"
                  v-model="editedItem.nik"
                  :rules="nomorduaRules"
                  color="teal"
                  required
                  readonly
                  dense
                  hint="masukan no NIK lalu tekan -> cek nik"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pl-1">
                <v-btn small dark @click="cekKAKA()" outlined color="teal">
                  CEK NIK
                </v-btn>
                <h6 v-if="this.statusdata === 1" class="teal--text">
                  {{ this.pesancek }}
                </h6>
                <h6 v-else-if="this.statusdata === 0" class="red--text">
                  {{ this.pesancek }}
                </h6>
                <h5 v-else></h5>
              </v-col>
              <v-row
                class="pl-1 pr-1"
                dense
                v-show="this.hasildatakk.nik !== null && this.statusdata === 1"
              >
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.nokk"
                    readonly
                    color="teal"
                    label="Nomor KK"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.nama"
                    readonly
                    color="teal"
                    label="Nama Lengkap"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.jk"
                    readonly
                    color="teal"
                    label="Jenis Kelamin"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.tanggal_lahir"
                    readonly
                    color="teal"
                    label="Tanggal Lahir"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.tempat_lahir"
                    readonly
                    color="teal"
                    label="Tempat Lahir"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.no_bangunan"
                    readonly
                    color="teal"
                    label="No Bangunan "
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.usia_kandungan"
                    :items="itemskandungan"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    readonly
                    label="Pilih Usia Kandungan"
                    outlined
                    dense
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    color="teal"
                    v-model="editedItem.golongan_darah"
                    label="Golongan Darah"
                    outlined
                    :rules="wajibRules"
                    dense
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.index_massa_tubuh"
                    :items="itemsmasa"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    label="Pilih Index Masa Tubuh"
                    outlined
                    dense
                    readonly
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.kondisi_kandungan"
                    :items="itemskondisi"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    label="Pilih Kondisi Kandungan "
                    outlined
                    dense
                    readonly
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="8">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.riwayat"
                    :items="itemsriwayat"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    readonly
                    label="Pilih Riwayat Kesehatan"
                    outlined
                    dense
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.tablet_tambah_darah"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Pemberian Tablet Tambah Darah"
                    outlined
                    dense
                    readonly
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.lila"
                    :items="itemslila"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Pilih LILA"
                    outlined
                    readonly
                    dense
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.riwayat_hamil"
                    :items="itemskehamilan"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    label="Riwayat Kehamilan dan Persalinan"
                    outlined
                    dense
                    readonly
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.riwayat_tt"
                    :items="itemsimunt"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    label="Status Imusisasi T"
                    outlined
                    readonly
                    dense
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    color="teal"
                    v-model="editedItem.cacatan_kesehatan"
                    label="Catatan Kesehatan Lainnya (Abortus, dll)"
                    outlined
                    :rules="wajibRules"
                    dense
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" @click="closeDetails" outlined color="grey">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialogDelete" persistent max-width="400px">
        <v-card>
          <v-card-title><h5>Yakin hapus data Ibu Hamil ?</h5> </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br />
            <h4 class="brown--text">NIK :{{ editedItem.nik }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-card v-else elevation="0">
        <v-card-text>
          <v-data-table
            multi-sort
            :headers="headers"
            :items="hasildatas"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="center">
                <v-btn
                  class="lighten-4 mx-1"
                  @click="detailItem(item)"
                  outlined
                  small
                  color="cyan"
                >
                  <v-icon dense center> mdi-information </v-icon>
                </v-btn>
                <v-btn
                  class="lighten-4 mx-2"
                  @click="editItem(item)"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon dense> mdi-pencil </v-icon>
                </v-btn>
                <v-btn small @click="deleteItem(item)" outlined color="error">
                  <v-icon dense center> mdi-delete </v-icon>
                </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.usaha_pertanian`]="{ item }">
              <v-chip
                class="ma-1"
                color="brown"
                label
                small
                text-color="white"
                v-for="(items, index) in item.usaha_pertanian"
                :key="index"
              >
                <v-icon small left> mdi-label </v-icon>
                <h4 class="font-weight-bold">
                  {{ items }}
                </h4>
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "masterbarangvue",
  data() {
    return {
      date: null,

      modal: false,
      menu2: false,
      // ITEMs

      itemsmakanan: [{}],
      itemskesehatan: [{}],
      itemsrencana: [{}],
      hasildatas: [],

      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,
      dialogDetail: false,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      // VALIDASI
      nomorduaRules: [
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      headers: [
        { text: "Nomor KK", value: "nokk" },
        { text: "NIK ", value: "nik" },
        {
          text: "Nama Lengkap",
          value: "nama",
        },

        {
          text: "Tanggal Lahir",
          value: "tanggal_lahir",
        },
        {
          text: "Usia Kandungan",
          value: "usia_kandungan",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        id_nik: "",
        nik: "",
        nokk: "",
        id_nokk: "",
        usia_kandungan: null,
        golongan_darah: null,
        index_massa_tubuh: null,
        kondisi_kandungan: null,
        riwayat: null,
        riwayat_hamil: null,
        riwayat_tt: null,
        hipertensi: null,
        diabetes: null,
        autoimun: null,
        u35: null,
        jantung: null,
        tyroid: null,
        alergi: null,
        asma: null,
        tb: null,
        hepatitis_b: null,
        jiwa: null,
        sifilis: null,
        ohda: null,
        tablet_tambah_darah: null,
        lila: null,
        keguguran: null,
        kembar: null,
        lahir_mati: null,
        tt1: null,
        tt2: null,
        tt3: null,
        tt4: null,
        tt5: null,
        cacatan_kesehatan: null,
        kehamilan: null,
        imunt: null,
      },
      defaultItem: {
        id: 0,
        id_nik: "",
        nik: "",
        id_nokk: "",
        usia_kandungan: null,
        golongan_darah: null,
        index_massa_tubuh: null,
        kondisi_kandungan: null,
        riwayat: null,
        riwayat_hamil: null,
        riwayat_tt: null,

        hipertensi: null,
        diabetes: null,
        autoimun: null,
        u35: null,
        jantung: null,
        tyroid: null,
        alergi: null,
        asma: null,
        tb: null,
        hepatitis_b: null,
        jiwa: null,
        sifilis: null,
        ohda: null,
        tablet_tambah_darah: null,
        lila: null,
        keguguran: null,
        kembar: null,
        lahir_mati: null,
        tt1: null,
        tt2: null,
        tt3: null,
        tt4: null,
        tt5: null,
        cacatan_kesehatan: null,

        kehamilan: null,
        imunt: null,
      },

      hasildatakk: {
        id_nik: null,

        nik: null,
        nama: null,
        id_nokk: null,
        nokk: null,
        jk: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        no_bangunan: null,
      },
      pesancek: "",
      statusdata: null,

      itemskandungan: [
        "1 Bulan",
        "2 Bulan",
        "3 Bulan",
        "4 Bulan",
        "5 Bulan",
        "6 Bulan",
        "7 Bulan",
        "8 Bulan",
        "9 Bulan",
        ">9 Bulan",
      ],

      itemsmasa: ["Kurus", "Normal", "Gemuk", "Obesitas"],
      itemskondisi: ["Resiko", "Tidak Beresiko"],
      itemslila: ["KEK", "Normal"],
      itemsriwayat: [
        "Hipertensi",
        "Diabetes",
        "Autoimun",
        "Usia diatas 35 Tahun",
        "Jantung",
        "Tyroid",
        "Alergi",
        "Asma",
        "TB",
        "Hepatitis B",
        "Jiwa",
        "Sifilis",
        "OHDA",
      ],
      itemsimunt: ["TT ke 1", "TT ke 2", "TT ke 3", "TT ke 4", "TT ke 5"],
      itemskehamilan: ["Keguguran", "Kembar", "Lahir Mati"],

      itemsjk: [{}],
      itemsyatidak: [{}],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Ibu Hamil "
        : "Form Edit Data Ibu Hamil ";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {
    this.getDataIbuHamilPosyanduAll();

    this.detailUsers();
    this.getDusun();
    this.getYatidak();
  },
  methods: {
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    cekKAKA: function (row) {
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNiks(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;

            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            // baru
            this.hasildatakk.jk = response.data.message.jk;
            this.hasildatakk.tempat_lahir = response.data.message.tmpt_lahir;
            this.hasildatakk.tanggal_lahir = response.data.message.tgl_lahir;
            this.hasildatakk.no_bangunan = response.data.message.no_bangunan;
            this.hasildatakk.nokk = response.data.message.nokk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          this.kota = this.detailusers.kota;
          this.kecamatan = this.detailusers.kecamatan;
          this.kelurahan = this.detailusers.kelurahan;
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getDataIbuHamilPosyanduAll: function () {
      AuthService.getDataIbuHamilPosyanduAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
          console.log(this.hasildatas);
        }.bind(this)
      );
    },
    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNiks(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;

            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            // baru
            this.hasildatakk.jk = response.data.message.jk;
            this.hasildatakk.tempat_lahir = response.data.message.tmpt_lahir;
            this.hasildatakk.tanggal_lahir = response.data.message.tgl_lahir;
            this.hasildatakk.no_bangunan = response.data.message.no_bangunan;
            this.hasildatakk.nokk = response.data.message.nokk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
      this.dialog = true;
    },

    detailItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNiks(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;

            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            // baru
            this.hasildatakk.jk = response.data.message.jk;
            this.hasildatakk.tempat_lahir = response.data.message.tmpt_lahir;
            this.hasildatakk.tanggal_lahir = response.data.message.tgl_lahir;
            this.hasildatakk.no_bangunan = response.data.message.no_bangunan;
            this.hasildatakk.nokk = response.data.message.nokk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
      this.dialogDetail = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteDataIbuHamilPosyandu(
        credentials
      );
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.getDataIbuHamilPosyanduAll();
        this.detailUsers();
        this.getYatidak();
        this.getDusun();

        this.$router
          .push("/admin-kelurahan/posyandu/ibu-hamil")
          .catch(() => {});

        this.dialogDelete = this.closeDelete();
        this.hasildatakk = [{}];
        this.statusdata = null;
        this.pesancek = null;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.statusdata = null;
      });
    },
    closeDetails() {
      this.dialogDetail = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.statusdata = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          //   console.log(this.editedItem);

          // proses riwayat

          this.editedItem.riwayat.forEach((item) => {
            // console.log(item);
            if (item === "Hipertensi") {
              this.editedItem.hipertensi = "Ya";
            }
            if (item === "Diabetes") {
              this.editedItem.diabetes = "Ya";
            }
            if (item === "Autoimun") {
              this.editedItem.autoimun = "Ya";
            }
            if (item === "Usia diatas 35 Tahun") {
              this.editedItem.u35 = "Ya";
            }
            if (item === "Jantung") {
              this.editedItem.jantung = "Ya";
            }
            if (item === "Tyroid") {
              this.editedItem.tyroid = "Ya";
            }
            if (item === "Alergi") {
              this.editedItem.alergi = "Ya";
            }
            if (item === "Asma") {
              this.editedItem.asma = "Ya";
            }
            if (item === "TB") {
              this.editedItem.tb = "Ya";
            }
            if (item === "Hepatitis B") {
              this.editedItem.hepatitis_b = "Ya";
            }
            if (item === "Jiwa") {
              this.editedItem.jiwa = "Ya";
            }
            if (item === "Sifilis") {
              this.editedItem.sifilis = "Ya";
            }
            if (item === "OHDA") {
              this.editedItem.ohda = "Ya";
            }
          });

          this.editedItem.riwayat_hamil.forEach((item) => {
            // console.log(item);
            if (item === "Keguguran") {
              this.editedItem.keguguran = "Ya";
            }
            if (item === "Kembar") {
              this.editedItem.kembar = "Ya";
            }
            if (item === "Lahir Mati") {
              this.editedItem.lahir_mati = "Ya";
            }
          });
          this.editedItem.riwayat_tt.forEach((item) => {
            // console.log(item);
            if (item === "TT ke 1") {
              this.editedItem.tt1 = "Ya";
            }
            if (item === "TT ke 2") {
              this.editedItem.tt2 = "Ya";
            }
            if (item === "TT ke 3") {
              this.editedItem.tt3 = "Ya";
            }
            if (item === "TT ke 4") {
              this.editedItem.tt4 = "Ya";
            }
            if (item === "TT ke 5") {
              this.editedItem.tt5 = "Ya";
            }
          });
          const credentials = {
            id_nokk: this.hasildatakk.id_nokk,
            id_nik: this.hasildatakk.id_nik,
            nik: this.hasildatakk.nik,
            nama: this.hasildatakk.nama,
            nokk: this.hasildatakk.nokk,
            tempat_lahir: this.hasildatakk.tempat_lahir,
            tanggal_lahir: this.hasildatakk.tanggal_lahir,
            jenis_kelamin: this.hasildatakk.jk,
            no_bangunan: this.hasildatakk.no_bangunan,

            golongan_darah: this.editedItem.golongan_darah,
            usia_kandungan: this.editedItem.usia_kandungan,
            kondisi_kandungan: this.editedItem.kondisi_kandungan,
            index_massa_tubuh: this.editedItem.index_massa_tubuh,
            tablet_tambah_darah: this.editedItem.tablet_tambah_darah,
            lila: this.editedItem.lila,
            cacatan_kesehatan: this.editedItem.cacatan_kesehatan,

            riwayat: this.editedItem.riwayat,
            riwayat_hamil: this.editedItem.riwayat_hamil,
            riwayat_tt: this.editedItem.riwayat_tt,

            hipertensi: this.editedItem.hipertensi,
            diabetes: this.editedItem.diabetes,
            autoimun: this.editedItem.autoimun,
            u35: this.editedItem.u35,
            jantung: this.editedItem.jantung,
            tyroid: this.editedItem.tyroid,
            alergi: this.editedItem.alergi,
            asma: this.editedItem.asma,
            tb: this.editedItem.tb,
            hepatitis_b: this.editedItem.hepatitis_b,
            jiwa: this.editedItem.jiwa,
            sifilis: this.editedItem.sifilis,
            ohda: this.editedItem.ohda,

            keguguran: this.editedItem.keguguran,
            kembar: this.editedItem.kembar,
            lahir_mati: this.editedItem.lahir_mati,

            tt1: this.editedItem.tt1,
            tt2: this.editedItem.tt2,
            tt3: this.editedItem.tt3,
            tt4: this.editedItem.tt4,
            tt5: this.editedItem.tt5,

            // simpan riwayat
          };
          const response = await AuthService.tambahDataIbuHamilPosyandu(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getDataIbuHamilPosyanduAll();
            this.detailUsers();
            this.getYatidak();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/posyandu/ibu-hamil")
              .catch(() => {});
            this.dialog = false;
            this.statusdata = null;

            this.$refs.form.reset();
            console.log("berhasil simpan data");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update
          this.editedItem.riwayat.forEach((item) => {
            // console.log(item);
            if (item === "Hipertensi") {
              this.editedItem.hipertensi = "Ya";
            } else {
              this.editedItem.hipertensi = null;
            }
            if (item === "Diabetes") {
              this.editedItem.diabetes = "Ya";
            } else {
              this.editedItem.diabetes = null;
            }
            if (item === "Autoimun") {
              this.editedItem.autoimun = "Ya";
            } else {
              this.editedItem.autoimun = null;
            }
            if (item === "Usia diatas 35 Tahun") {
              this.editedItem.u35 = "Ya";
            } else {
              this.editedItem.u35 = null;
            }
            if (item === "Jantung") {
              this.editedItem.jantung = "Ya";
            } else {
              this.editedItem.jantung = null;
            }
            if (item === "Tyroid") {
              this.editedItem.tyroid = "Ya";
            } else {
              this.editedItem.tyroid = null;
            }
            if (item === "Alergi") {
              this.editedItem.alergi = "Ya";
            } else {
              this.editedItem.alergi = null;
            }
            if (item === "Asma") {
              this.editedItem.asma = "Ya";
            } else {
              this.editedItem.asma = null;
            }
            if (item === "TB") {
              this.editedItem.tb = "Ya";
            } else {
              this.editedItem.tb = null;
            }
            if (item === "Hepatitis B") {
              this.editedItem.hepatitis_b = "Ya";
            } else {
              this.editedItem.hepatitis_b = null;
            }
            if (item === "Jiwa") {
              this.editedItem.jiwa = "Ya";
            } else {
              this.editedItem.jiwa = null;
            }
            if (item === "Sifilis") {
              this.editedItem.sifilis = "Ya";
            } else {
              this.editedItem.sifilis = null;
            }
            if (item === "OHDA") {
              this.editedItem.ohda = "Ya";
            } else {
              this.editedItem.ohda = null;
            }
          });

          this.editedItem.riwayat_hamil.forEach((item) => {
            // console.log(item);
            if (item === "Keguguran") {
              this.editedItem.keguguran = "Ya";
            } else {
              this.editedItem.keguguran = null;
            }
            if (item === "Kembar") {
              this.editedItem.kembar = "Ya";
            } else {
              this.editedItem.kembar = null;
            }
            if (item === "Lahir Mati") {
              this.editedItem.lahir_mati = "Ya";
            } else {
              this.editedItem.lahir_mati = null;
            }
          });
          this.editedItem.riwayat_tt.forEach((item) => {
            // console.log(item);
            if (item === "TT ke 1") {
              this.editedItem.tt1 = "Ya";
            } else {
              this.editedItem.tt1 = null;
            }
            if (item === "TT ke 2") {
              this.editedItem.tt2 = "Ya";
            } else {
              this.editedItem.tt2 = null;
            }
            if (item === "TT ke 3") {
              this.editedItem.tt3 = "Ya";
            } else {
              this.editedItem.tt3 = null;
            }
            if (item === "TT ke 4") {
              this.editedItem.tt4 = "Ya";
            } else {
              this.editedItem.tt4 = null;
            }
            if (item === "TT ke 5") {
              this.editedItem.tt5 = "Ya";
            } else {
              this.editedItem.tt5 = null;
            }
          });
          const credentials = {
            id: this.editedItem.id,
            id_nokk: this.hasildatakk.id_nokk,
            id_nik: this.hasildatakk.id_nik,
            nik: this.hasildatakk.nik,
            nama: this.hasildatakk.nama,
            nokk: this.hasildatakk.nokk,
            tempat_lahir: this.hasildatakk.tempat_lahir,
            tanggal_lahir: this.hasildatakk.tanggal_lahir,
            jenis_kelamin: this.hasildatakk.jk,
            no_bangunan: this.hasildatakk.no_bangunan,

            golongan_darah: this.editedItem.golongan_darah,
            usia_kandungan: this.editedItem.usia_kandungan,
            kondisi_kandungan: this.editedItem.kondisi_kandungan,
            index_massa_tubuh: this.editedItem.index_massa_tubuh,
            tablet_tambah_darah: this.editedItem.tablet_tambah_darah,
            lila: this.editedItem.lila,
            cacatan_kesehatan: this.editedItem.cacatan_kesehatan,

            riwayat: this.editedItem.riwayat,
            riwayat_hamil: this.editedItem.riwayat_hamil,
            riwayat_tt: this.editedItem.riwayat_tt,

            hipertensi: this.editedItem.hipertensi,
            diabetes: this.editedItem.diabetes,
            autoimun: this.editedItem.autoimun,
            u35: this.editedItem.u35,
            jantung: this.editedItem.jantung,
            tyroid: this.editedItem.tyroid,
            alergi: this.editedItem.alergi,
            asma: this.editedItem.asma,
            tb: this.editedItem.tb,
            hepatitis_b: this.editedItem.hepatitis_b,
            jiwa: this.editedItem.jiwa,
            sifilis: this.editedItem.sifilis,
            ohda: this.editedItem.ohda,

            keguguran: this.editedItem.keguguran,
            kembar: this.editedItem.kembar,
            lahir_mati: this.editedItem.lahir_mati,

            tt1: this.editedItem.tt1,
            tt2: this.editedItem.tt2,
            tt3: this.editedItem.tt3,
            tt4: this.editedItem.tt4,
            tt5: this.editedItem.tt5,
          };
          const response = await AuthService.editDataIbuHamilPosyandu(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getDataIbuHamilPosyanduAll();
            this.detailUsers();
            this.getYatidak();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/posyandu/ibu-hamil")
              .catch(() => {});
            this.dialog = false;
            this.statusdata = null;

            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
