<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Data Statistik - Rumah Tinggal Isi</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="blue"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />
    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <!-- PART SATU-->

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KONDISI RUMAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS TEMPAT TINGGAL</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatadua"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak2[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS LAHAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatiga"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak3[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JENIS LANTAI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataempat"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak4[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JENIS DINDING</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalima"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak5[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK STATUS JENDELA</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataenam"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak6[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK BAHAN ATAP</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatujuh"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak7[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PENERANGAN RUMAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalapan"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduak8[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- PART DUA-->

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK ENERGI MASAK</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatax"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PEMBUANGAN SAMPAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataduax"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk2[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK FASILITAS MCK</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatigax"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk3[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK SUMBER AIR MANDI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataempatx"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk4[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK FASILITAS BAB</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="7" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalimax"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :height="1000"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduajk5[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- PART TIGA-->

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK SUMBER AIR MINUM</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK PEMBUANGAN LIMBAH</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataduaz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz2[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK SUTET</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatigaz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz3[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK BANTARAN SUNGAI</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataempatz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz4[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK LERENG BUKIT</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalimaz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz5[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JARINGAN TELEPON</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDataenamz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz6[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK JARINGAN GSM</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatatujuhz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz7[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>GRAFIK KONDISI JARINGAN</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-card elevation="0" class="pa-4">
                    <Pie
                      :chart-options="chartOptions"
                      :chart-data="chartDatalapanz"
                      :chart-id="chartId"
                      :dataset-id-key="datasetIdKey"
                      :plugins="plugins"
                      color="primary"
                      :css-classes="cssClasses"
                      :styles="styles"
                      :width="width"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-expansion-panels hover accordion>
                    <v-expansion-panel
                      v-for="(itemdata, index) in hasildatas.kelurahan"
                      :key="index"
                    >
                      <v-expansion-panel-header
                        ><strong>{{
                          itemdata.total
                        }}</strong></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <br />

                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartDataduakz8[index]"
                          :chart-id="chartId"
                          :dataset-id-key="datasetIdKey"
                          :plugins="plugins"
                          color="primary"
                          :css-classes="cssClasses"
                          :styles="styles"
                          :width="width"
                        />
                      </v-expansion-panel-content> </v-expansion-panel
                  ></v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,
      sloadingdua: true,
      firstLoaddua: true,
      sloadingtiga: true,
      firstLoadtiga: true,

      // chart DUA
      chartDatadua: "",
      chartDatatiga: "",
      chartDataempat: "",
      chartDatalima: "",
      chartDataenam: "",
      chartDatatujuh: "",
      chartDatalapan: "",

      chartDataduax: "",
      chartDatatigax: "",
      chartDataempatx: "",
      chartDatalimax: "",

      chartDataduaz: "",
      chartDatatigaz: "",
      chartDataempatz: "",
      chartDatalimaz: "",
      chartDataenamz: "",
      chartDatatujuhz: "",
      chartDatalapanz: "",

      // kelurahan p1
      chartDataduak: [],
      chartDataduak2: [],
      chartDataduak3: [],
      chartDataduak4: [],
      chartDataduak5: [],
      chartDataduak6: [],
      chartDataduak7: [],
      chartDataduak8: [],

      // kelurahan part 2
      chartDataduajk: [],
      chartDataduajk2: [],
      chartDataduajk3: [],
      chartDataduajk4: [],
      chartDataduajk5: [],

      // kelurahan part 3
      chartDataduakz: [],
      chartDataduakz2: [],
      chartDataduakz3: [],
      chartDataduakz4: [],
      chartDataduakz5: [],
      chartDataduakz6: [],
      chartDataduakz7: [],
      chartDataduakz8: [],

      // Chart
      hasildatas: [{}],
      hasildatasdua: [{}],
      hasildatastiga: [{}],
      series: [44, 55, 41, 17, 15],
      chartData: "",
      chartDatax: "",
      chartDataz: "",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataRumahTinggalIsi();
    this.stsDataRumahTinggalIsiI();
    this.stsDataRumahTinggalIsiII();
  },
  methods: {
    stsDataRumahTinggalIsi: function () {
      ServicePublik.stsDataRumahTinggalIsi().then(
        function (response) {
          this.hasildatas = response.data.data[0];

          this.firstLoad = false;
          this.sloading = false;

          const responseData = this.hasildatas.data.kondisi_rumah;
          const responseDatadua = this.hasildatas.data.status_tempat;
          const responseDatatiga = this.hasildatas.data.status_lahan;
          const responseDataempat = this.hasildatas.data.jenis_lantai;
          const responseDatalima = this.hasildatas.data.jenis_dinding;
          const responseDataenam = this.hasildatas.data.status_jendela;
          const responseDatatujuh = this.hasildatas.data.bahan_atap;
          const responseDatalapan = this.hasildatas.data.penerangan_rumah;
          //   console.log(this.hasildatas);
          const responseDataKelurahan = this.hasildatas.kelurahan;

          // GRAFIK SATU
          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDatadua = {
            labels: responseDatadua.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatadua.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TIGA
          this.chartDatatiga = {
            labels: responseDatatiga.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatiga.map((item) => item.total),
              },
            ],
          };

          // GRAFIK EMPAT
          this.chartDataempat = {
            labels: responseDataempat.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempat.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LIMA
          this.chartDatalima = {
            labels: responseDatalima.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalima.map((item) => item.total),
              },
            ],
          };

          // GRAFIK ENAM
          this.chartDataenam = {
            labels: responseDataenam.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataenam.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TUJUH
          this.chartDatatujuh = {
            labels: responseDatatujuh.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatujuh.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LAPAN
          this.chartDatalapan = {
            labels: responseDatalapan.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalapan.map((item) => item.total),
              },
            ],
          };

          // KELURAHAN
          responseDataKelurahan.forEach((number, index) => {
            this.chartDataduak[index] = {
              labels: number.data.kondisi_rumah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.kondisi_rumah.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak2[index] = {
              labels: number.data.status_tempat.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_tempat.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak3[index] = {
              labels: number.data.status_lahan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_lahan.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak4[index] = {
              labels: number.data.jenis_lantai.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.jenis_lantai.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak5[index] = {
              labels: number.data.jenis_dinding.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.jenis_dinding.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak6[index] = {
              labels: number.data.status_jendela.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_jendela.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak7[index] = {
              labels: number.data.bahan_atap.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.bahan_atap.map((item) => item.total),
                },
              ],
            };
            this.chartDataduak8[index] = {
              labels: number.data.penerangan_rumah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.penerangan_rumah.map((item) => item.total),
                },
              ],
            };
          });
        }.bind(this)
      );
    },
    stsDataRumahTinggalIsiI: function () {
      ServicePublik.stsDataRumahTinggalIsiI().then(
        function (response) {
          this.hasildatasdua = response.data.data[0];

          this.firstLoaddua = false;
          this.sloadingdua = false;

          const responseDatax = this.hasildatasdua.energi_masak;
          const responseDataduax = this.hasildatasdua.pembuangan_sampah;
          const responseDatatigax = this.hasildatasdua.fasilitas_mck;
          const responseDataempatx = this.hasildatasdua.sumber_air_mandi;
          const responseDatalimax = this.hasildatasdua.fasilitas_bab;

          //   console.log(this.hasildatas);
          const responseDataKelurahans = this.hasildatasdua.kelurahan;
          // GRAFIK SATU
          this.chartDatax = {
            labels: responseDatax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDataduax = {
            labels: responseDataduax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataduax.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TIGA
          this.chartDatatigax = {
            labels: responseDatatigax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatigax.map((item) => item.total),
              },
            ],
          };

          // GRAFIK EMPAT
          this.chartDataempatx = {
            labels: responseDataempatx.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempatx.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LIMA
          this.chartDatalimax = {
            labels: responseDatalimax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalimax.map((item) => item.total),
              },
            ],
          };

          responseDataKelurahans.forEach((number, index) => {
            this.chartDataduajk[index] = {
              labels: number.data.energi_masak.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.energi_masak.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk2[index] = {
              labels: number.data.pembuangan_sampah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.pembuangan_sampah.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk3[index] = {
              labels: number.data.fasilitas_mck.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.fasilitas_mck.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk4[index] = {
              labels: number.data.sumber_air_mandi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.sumber_air_mandi.map((item) => item.total),
                },
              ],
            };
            this.chartDataduajk5[index] = {
              labels: number.data.fasilitas_bab.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.fasilitas_bab.map((item) => item.total),
                },
              ],
            };
          });
        }.bind(this)
      );
    },
    stsDataRumahTinggalIsiII: function () {
      ServicePublik.stsDataRumahTinggalIsiII().then(
        function (response) {
          this.hasildatastiga = response.data.data[0];

          this.firstLoadtiga = false;
          this.sloadingtiga = false;

          const responseDataz = this.hasildatastiga.data.sumber_air_mandi;
          const responseDataduaz = this.hasildatastiga.data.pembuangan_limbah;
          const responseDatatigaz = this.hasildatastiga.data.sutet;
          const responseDataempatz = this.hasildatastiga.data.bantaran_sungai;
          const responseDatalimaz = this.hasildatastiga.data.lereng_bukit;
          const responseDataenamz = this.hasildatastiga.data.jaringan_telepon;
          const responseDatatujuhz = this.hasildatastiga.data.jaringan_gsm;
          const responseDatalapanz = this.hasildatastiga.data.status_jaringan;

          //   console.log(this.hasildatas);
          const responseDataKelurahan = this.hasildatastiga.kelurahan;

          // GRAFIK SATU
          this.chartDataz = {
            labels: responseDataz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDataduaz = {
            labels: responseDataduaz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataduaz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK TIGA
          this.chartDatatigaz = {
            labels: responseDatatigaz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatigaz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK EMPAT
          this.chartDataempatz = {
            labels: responseDataempatz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempatz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK LIMA
          this.chartDatalimaz = {
            labels: responseDatalimaz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalimaz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK ENAM
          this.chartDataenamz = {
            labels: responseDataenamz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataenamz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK TUJUH
          this.chartDatatujuhz = {
            labels: responseDatatujuhz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatujuhz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK LAPAN
          this.chartDatalapanz = {
            labels: responseDatalapanz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalapanz.map((item) => item.total),
              },
            ],
          };

          // KELURAHAN
          responseDataKelurahan.forEach((number, index) => {
            this.chartDataduakz[index] = {
              labels: number.data.sumber_air_mandi.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.sumber_air_mandi.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz2[index] = {
              labels: number.data.pembuangan_limbah.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.pembuangan_limbah.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz3[index] = {
              labels: number.data.sutet.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.sutet.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz4[index] = {
              labels: number.data.bantaran_sungai.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.bantaran_sungai.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz5[index] = {
              labels: number.data.lereng_bukit.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.lereng_bukit.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz6[index] = {
              labels: number.data.jaringan_telepon.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.jaringan_telepon.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz7[index] = {
              labels: number.data.jaringan_gsm.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.jaringan_gsm.map((item) => item.total),
                },
              ],
            };
            this.chartDataduakz8[index] = {
              labels: number.data.status_jaringan.map(
                (item) => item.nama + " : " + item.total + " " + "%"
              ),
              datasets: [
                {
                  borderColor: "rgba(200, 200, 200, 0.75)",
                  label: "Total Data",

                  backgroundColor: [
                    "#41B883",
                    "#b52d07",
                    "#b57007",
                    "#127347",
                    "#f589a8",
                    "#174ceb",
                    "#91a8ed",
                    "#c591ed",
                    "#eb0cdf",
                    "#6b284c",
                  ],
                  // data: [40, 20, 80, 10],
                  data: number.data.status_jaringan.map((item) => item.total),
                },
              ],
            };
          });
        }.bind(this)
      );
    },
  },
  mounted() {
    // // ronde satu
    // this.renderChart(this.chartData);
    // this.renderChart(this.chartDatadua);
    // this.renderChart(this.chartDatatiga);
    // this.renderChart(this.chartDataempat);
    // this.renderChart(this.chartDatalima);
    // this.renderChart(this.chartDataenam);
    // this.renderChart(this.chartDatatujuh);
    // this.renderChart(this.chartDatalapan);
    // //ronde duo
    // this.renderChart(this.chartDatax);
    // this.renderChart(this.chartDataduax);
    // this.renderChart(this.chartDatatigax);
    // this.renderChart(this.chartDataempatx);
    // this.renderChart(this.chartDatalimax);
    // // ronde tiga
    // this.renderChart(this.chartDataz);
    // this.renderChart(this.chartDataduaz);
    // this.renderChart(this.chartDatatigaz);
    // this.renderChart(this.chartDataempatz);
    // this.renderChart(this.chartDatalimaz);
    // this.renderChart(this.chartDataenamz);
    // this.renderChart(this.chartDatatujuhz);
    // this.renderChart(this.chartDatalapanz);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
