import { render, staticRenderFns } from "./DataWalet.vue?vue&type=template&id=0577f080&scoped=true&"
import script from "./DataWalet.vue?vue&type=script&lang=js&"
export * from "./DataWalet.vue?vue&type=script&lang=js&"
import style0 from "./DataWalet.vue?vue&type=style&index=0&id=0577f080&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0577f080",
  null
  
)

export default component.exports