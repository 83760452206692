import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import VueNotification from "@kugatsu/vuenotification";
import NProgress from '../node_modules/nprogress/nprogress.css' 
import Vue2Leaflet from 'vue2-leaflet';



Vue.config.productionTip = false
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

delete Icon.Default.prototype._getIconUrl;

Icon.Default.imagePath = '.';
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
Axios.defaults.headers.common['Content-Type'] = `multipart/form-data`;
Vue.use(VueNotification, {
 
  timer: 20,
  position: "topRight",
  
  error: {
    background: "#F56565",
    color: "white"
  },
  success: {
    background: "#38A169",
    color: "white"
  },
  warning: {
    background: "#ED8936",
    color: "white"
  },
 
});

new Vue({
  router,
  store,
  vuetify,
  Axios,
  VueNotification,
  NProgress,
  
  render: h => h(App)
}).$mount('#app')

