<template>
  <v-container fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-card class="mt-12 pl-3 pr-3 pb-12 pt-12 rounded-card">
              <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                  <v-img
                    max-height="80"
                    max-width="60"
                    src="@/assets/prm.png"
                  ></v-img>
                </v-col>
              </v-row>
              <h3 class="text-center black--text text--accent-3">
                SATU DATA DESA INDONESIA <br />
                KOTA PARIAMAN
              </h3>

              <h5 class="text-center mt-7 mb-4">
                Silahkan Login Untuk Mengakses Dashboard
              </h5>
              <v-form
                @submit.prevent="handleSubmit"
                v-model="valid"
                lazy-validation
                ref="form"
                class="pa-2"
              >
                <v-text-field
                  color="teal"
                  v-model="email"
                  :rules="emailRules"
                  :counter="30"
                  label="Email Adress "
                  required
                  filled
                  dense
                ></v-text-field>
                <v-text-field
                  label="Password *"
                  filled
                  dense
                  v-model="password"
                  :rules="passwordRules"
                  color="teal"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-2"
                  class="input-group--focused"
                  @click:append="show2 = !show2"
                ></v-text-field>

                <v-btn
                  large
                  block
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="white--text mt-3"
                  color="teal"
                >
                  LOGIN
                </v-btn>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";
export default {
  name: "Login",
  data: () => {
    return {
      show1: true,
      show2: false,

      errors: null,
      loader: null,
      loading: false,
      valid: true,

      email: "",
      password: "",

      emailRules: [
        (v) => !!v || "E-mail  tidak boleh kosong",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],

      passwordRules: [(v) => !!v || "Password tidak boleh kosong"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    async handleSubmit() {
      try {
        const credentials = {
          email: this.email,
          password: this.password,
        };
        this.loading = true;
        const response = await AuthService.login(credentials);
        this.message = response.email;
        const token = response.tokens;
        const user = response;

        console.log(response.role);
        this.$store.dispatch("login", { token, user });
        this.$notification.success(response.message, {
          timer: 5,
        });
        window.localStorage.setItem("lbUser", JSON.stringify(response.role));
        if (response.role === "superadmin") {
          this.$router.push("/dashboard/beranda");
          this.loading = false;
        } else if (response.role === "provinsi") {
          this.$router.push("/admin-provinsi/beranda");
          this.loading = false;
        } else if (response.role === "kota") {
          this.$router.push("/admin-kota/beranda");
          this.loading = false;
        } else if (response.role === "kecamatan") {
          this.$router.push("/admin-kecamatan/beranda");
          this.loading = false;
        } else if (response.role === "kelurahan") {
          this.$router.push("/admin-kelurahan/beranda");
          this.loading = false;
        } else {
          this.$router.push("/404");
          this.loading = false;
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.data.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
</style>
