<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>Data Perikanan - Ikan Budidaya</h4></v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      outlined
                      :rules="wajibRules"
                      v-model="editedItem.kategori"
                      :items="itemsikan"
                      item-text="kategori_ikan"
                      item-value="kategori_ikan"
                      color="teal"
                      label="Pilih Kategori"
                      dense
                    ></v-select>
                  </v-col>

                  <template v-if="editedItem.kategori === 'Pelaku Usaha'">
                    <v-row class="pl-1">
                      <v-col cols="12" md="9">
                        <v-text-field
                          label="NIK"
                          outlined
                          maxlength="16"
                          :counter="16"
                          v-model="editedItem.nik"
                          :rules="nomorduaRules"
                          color="teal"
                          required
                          dense
                          hint="masukan no NIK lalu tekan -> cek nik"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pl-5" cols="12" md="3">
                        <v-btn
                          small
                          dark
                          @click="cekKAKA()"
                          outlined
                          color="teal"
                        >
                          CEK NIK
                        </v-btn>
                        <h5 v-if="this.statusdata === 1" class="teal--text">
                          {{ this.pesancek }}
                        </h5>
                        <h5 v-else-if="this.statusdata === 0" class="red--text">
                          {{ this.pesancek }}
                        </h5>
                        <h5 v-else></h5>
                      </v-col>
                    </v-row>

                    <v-row
                      class="pl-1 pr-1"
                      dense
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                    >
                      <v-col cols="12" md="6">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="hasildatakk.nik"
                          readonly
                          color="teal"
                          label="Nomor Induk Kependudukan (NIK)"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          :rules="wajibRules"
                          v-model="hasildatakk.nama"
                          readonly
                          color="teal"
                          label="Nama Lengkap"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-col
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                      cols="12"
                    >
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.jenis_budidaya"
                        :items="itemsjenisbudidaya"
                        item-text="judul"
                        item-value="name"
                        color="teal"
                        label="Pilih Jenis Budidaya"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>

                    <v-col
                      v-for="(item, index) in editedItem.jenis_budidaya"
                      :key="index"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'Kolam Air Tenang'"
                        color="teal"
                        v-model="editedItem.air_tenang"
                        label="Luas Kolam Air Tenang (M2)"
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kolam Air Deras'"
                        color="teal"
                        v-model="editedItem.air_deras"
                        label="Luas Kolam Air Deras (M2) "
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kolam Terpal'"
                        color="teal"
                        v-model="editedItem.terpal"
                        label="Luas Kolam Terpal (M2) "
                        outlined
                        :rules="nomorduaRules"
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Keramba'"
                        color="teal"
                        v-model="editedItem.keramba"
                        label="Luas Keramba (M2) "
                        outlined
                        dense
                        :rules="nomorduaRules"
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-show="
                        this.hasildatakk.nik !== null && this.statusdata === 1
                      "
                      cols="12"
                    >
                      <v-text-field
                        label="Jumlah Produksi Rata-Rata Triwulan (Kg)"
                        outlined
                        v-model="editedItem.jumlah_produksi"
                        :rules="nomorduaRules"
                        color="teal"
                        required
                        hint="hanya berisi angka dengan satuan Kg"
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>
                  <template v-if="editedItem.kategori === 'Kelompok'">
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Kelompok"
                        outlined
                        v-model="editedItem.nm_kelompok"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Alamat Kelompok"
                        outlined
                        v-model="editedItem.alamat_kelompok"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Ketua"
                        outlined
                        v-model="editedItem.nm_ketua"
                        color="teal"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Jumlah Anggota"
                        outlined
                        v-model="editedItem.jumlah_anggota"
                        color="teal"
                        :rules="nomorduaRules"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-3">
                      <v-btn small dark @click="addTextRow()" color="teal">
                        <v-icon small dense>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-row
                      dense
                      v-for="row in textRows"
                      :key="row.index"
                      class="pl-1 pr-1"
                    >
                      <v-col cols="12" md="10">
                        <v-text-field
                          label="Nama Anggota"
                          outlined
                          v-model="row.nm_anggota"
                          color="teal"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          small
                          dark
                          @click="deleteTextRow(row)"
                          color="red"
                        >
                          <v-icon small dense>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-col cols="12">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.jenis_budidaya"
                        :items="itemsjenisbudidaya"
                        item-text="text"
                        item-value="text"
                        color="teal"
                        label="Pilih Jenis Budidaya"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>
                    <v-col
                      v-for="(item, index) in editedItem.jenis_budidaya"
                      :key="index"
                      cols="12"
                    >
                      <v-text-field
                        v-show="item === 'Kolam Air Tenang'"
                        color="teal"
                        v-model="editedItem.air_tenang"
                        label="Luas Kolam Air Tenang (M2)"
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kolam Air Deras'"
                        color="teal"
                        v-model="editedItem.air_deras"
                        label="Luas Kolam Air Deras (M2) "
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kolam Terpal'"
                        color="teal"
                        v-model="editedItem.terpal"
                        label="Luas Kolam Terpal (M2) "
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Keramba'"
                        color="teal"
                        v-model="editedItem.keramba"
                        label="Luas Keramba (M2) "
                        outlined
                        dense
                        hint="hanya berisi angka dengan satuan m2"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Jumlah Produksi Rata-Rata Triwulan (Kg)"
                        outlined
                        v-model="editedItem.jumlah_produksi"
                        :rules="nomorduaRules"
                        color="teal"
                        required
                        hint="hanya berisi angka dengan satuan Kg"
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <template v-if="this.statusdata === 1"> </template>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title><h5>Yakin hapus data Ikan Budidaya ?</h5> </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogEdit" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Edit Data Ikan Budidaya : {{ editedItem.nik }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeEdit"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="updateSAGA"
            v-model="valid"
            lazy-validation
          >
            <br />

            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="nik"
                  :rules="nokkRules"
                  label="Nomor Induk Kependudukan (NIK)"
                  required
                  filled
                  counter="16"
                  maxlength="16"
                  dense
                  color="teal"
                  hint="input no NIK tekan -> cek kk"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn small dark @click="cekKAKA" outlined color="teal">
                  CEK NIK
                </v-btn>
                <h5 v-if="this.statusdata === 1" class="teal--text">
                  {{ this.pesancek }}
                </h5>
                <h5 v-else-if="this.statusdata === 0" class="red--text">
                  {{ this.pesancek }}
                </h5>
                <h5 v-else></h5>
              </v-col>
            </v-row>
            <v-divider class="pb-3"></v-divider>
            <v-row
              dense
              v-show="this.editedItem.nik !== null && this.statusdata === 1"
            >
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nik"
                  readonly
                  color="teal"
                  label="Nomor Induk Kependudukan (NIK)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.penerima"
                  readonly
                  color="teal"
                  label="Nama Lengkap"
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.tahun"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Pilih Tahun"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.perguruan_tinggi"
                  color="teal"
                  label="Masukan Perguruan Tinggi"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.asal_sekolah"
                  color="teal"
                  label="Masukan Asal Sekolah"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <template v-if="this.nik === editedItem.nik">
                <v-btn
                  v-show="editedItem.nik !== null && this.statusdata === 1"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  color="teal"
                  class="mr-0 white--text"
                >
                  Update Data
                </v-btn>
              </template>
              <template v-else>
                <v-btn disabled color="grey" class="mr-0 white--text">
                  Update Data
                </v-btn>
              </template>
            </v-card-actions>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogDetail" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Detail Data Ikan Budidaya : {{ editedItem.kategori }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeDetail"
              >close</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <br />
          <div v-if="editedItem.kategori === 'Pelaku Usaha'">
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nik"
                  readonly
                  color="teal"
                  label="NIK"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.jenis_budidaya"
                  readonly
                  color="teal"
                  label="Jenis Budidaya "
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <template v-if="editedItem.air_tenang !== '0'">
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.air_tenang"
                    readonly
                    color="teal"
                    label="Kolam Air Tenang  (m2)"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="editedItem.air_deras !== '0'">
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.air_deras"
                    readonly
                    color="teal"
                    label="Kolam Air Deras (m2)"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="editedItem.terpal !== '0'">
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.terpal"
                    readonly
                    color="teal"
                    label="Kolam Terpal  (m2)"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="editedItem.keramba !== '0'">
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.keramba"
                    readonly
                    color="teal"
                    label="Keramba"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.jumlah_produksi"
                  readonly
                  color="teal"
                  label="Jumlah Rroduksi Rata-Rata Triwulan (Kg)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nm_kelompok"
                  readonly
                  color="teal"
                  label="Nama Kelompok"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.alamat_kelompok"
                  readonly
                  color="teal"
                  label="Alamat Kelompok"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nm_ketua"
                  readonly
                  color="teal"
                  label="Nama Ketua "
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.jumlah_anggota"
                  readonly
                  color="teal"
                  label="Jumlah Anggota "
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                v-for="(item, index) in this.anggota"
                :key="index"
                cols="4"
              >
                <v-text-field
                  :rules="wajibRules"
                  v-model="item.nm_anggota"
                  readonly
                  color="teal"
                  label="Nama Anggota "
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.jenis_budidaya"
                  readonly
                  color="teal"
                  label="Jenis Budidaya "
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <template
                v-if="
                  editedItem.air_tenang !== '0' &&
                  editedItem.air_tenang !== null
                "
              >
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.air_tenang"
                    readonly
                    color="teal"
                    label="Kolam Air Tenang  (m2)"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <template
                v-if="
                  editedItem.air_deras !== '0' && editedItem.air_deras !== null
                "
              >
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.air_deras"
                    readonly
                    color="teal"
                    label="Kolam Air Deras (m2)"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <template
                v-if="editedItem.terpal !== '0' && editedItem.terpal !== null"
              >
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.terpal"
                    readonly
                    color="teal"
                    label="Kolam Terpal  (m2)"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <template
                v-if="editedItem.keramba !== '0' && editedItem.keramba !== null"
              >
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="editedItem.keramba"
                    readonly
                    color="teal"
                    label="Keramba"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.jumlah_produksi"
                  readonly
                  color="teal"
                  label="Jumlah Rroduksi Rata-Rata Triwulan (Kg)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <br />
          <v-divider></v-divider>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-1"
              @click="detailData(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>
            <v-btn
              class="lighten-4 mx-1"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>

            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.jumlah_produksi`]="{ item }">
          <h4 class="dark--text font-weight-bold">
            {{ item.jumlah_produksi }} Kg
          </h4>
        </template>
        <template v-slot:[`item.jenis_budidaya`]="{ item }">
          <v-chip
            class="ma-2"
            color="brown"
            label
            text-color="white"
            v-for="(items, index) in item.jenis_budidaya.split(',')"
            :key="index"
          >
            <v-icon left> mdi-label </v-icon>
            <h4 class="font-weight-bold">
              {{ items }}
            </h4>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "SagaData",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Ikan Budidaya"
        : "Form Edit Data Ikan Budidaya";
    },
  },
  data() {
    return {
      //Data Ikanbudidaya
      itemsikan: [{}],

      anggota: [
        { nm_anggota: "SARTO" },
        { nm_anggota: "AWALUDIN" },
        { nm_anggota: "PIAN" },
      ],

      // Item Bududaya
      itemsjenisbudidaya: [
        "Kolam Air Tenang",
        "Kolam Air Deras",
        "Kolam Terpal",
        "Keramba",
      ],
      // itemsjenisbudidaya: [
      //   {
      //     judul: "Kolam Air Tenang",
      //     name: "Air Tenang",
      //   },
      //   {
      //     judul: "Kolam Air Deras",
      //     name: "Air Deras",
      //   },
      //   {
      //     judul: "Kolam Terpal",
      //     name: "Terpal",
      //   },
      //   {
      //     judul: "Keramba",
      //     name: "Keramba",
      //   },
      // ],

      statusdata: null,
      datailusi: {},

      hasildatakk: {
        id_nik: null,
        nik: null,
        nama: null,
        id_nokk: null,
      },
      pesancek: "",

      // Dialog
      dialogEdit: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,
      nik: "",
      dialogDetail: false,

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      nomorduaRules: [
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: "Kategori", value: "kategori" },
        { text: "Jenis Budidaya", value: "jenis_budidaya" },
        { text: "Jumlah Produksi", value: "jumlah_produksi" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        kategori: "",
        jenis_budidaya: "",
        nik: "",
        jumlah_produksi: "",

        air_deras: "",
        air_tenang: "",
        terpal: "",
        keramba: "",

        nm_kelompok: null,
        alamat_kelompok: null,
        nm_ketua: null,
        jumlah_anggota: null,
        nm_anggota: null,
      },
      defaultItem: {
        id: 0,
        kategori: "",
        jenis_budidaya: "",
        nik: "",
        jumlah_produksi: "",

        air_deras: "",
        air_tenang: "",
        terpal: "",
        keramba: "",

        nm_kelompok: null,
        alamat_kelompok: null,
        nm_ketua: null,
        jumlah_anggota: null,
        nm_anggota: null,
      },

      textRows: [
        {
          nm_anggota: null,
        },
      ],
      removeTextRow: false,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getIkanBudidayaAll();
    this.getIkanKategori();
    this.detailUsers();
    this.getDusun();
  },

  methods: {
    addTextRow() {
      this.textRows.push({});
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
      }
    },
    deleteTextRow(row) {
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
        this.textRows.splice(this.textRows.indexOf(row), 1);
      }
      if (this.textRows.length <= 1) {
        this.removeTextRow = false;
      }
    },
    cekKAKA: function (row) {
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },
    getIkanKategori: function () {
      AuthService.getIkanKategori().then(
        function (response) {
          this.itemsikan = response.data.data;
        }.bind(this)
      );
    },
    getIkanBudidayaAll: function () {
      AuthService.getIkanBudidayaAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailData(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDetail = true;
      this.anggota = JSON.parse(item.nm_anggota);

      console.log(this.anggota);
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;

      this.nik = this.editedItem.nik;
      this.editedItem.jenis_budidaya = item.jenis_budidaya.split(",");
      this.textRows = JSON.parse(item.nm_anggota);

      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
      this.dialog = true;
      // console.log(this.editedItem.jenis_budidaya);
      console.log(JSON.parse(item.nm_anggota));

      // this.editedItem.nik = this.editedItem.nik;
      // this.editedItem.nama = this.editedItem.nama;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteIkanBudidaya(credentials);
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getIkanBudidayaAll();
        this.getIkanKategori();

        this.$router
          .push("/admin-kelurahan/perikanan/ikan-budidaya")
          .catch(() => {});

        this.dialogDelete = false;
        this.closeDetail();
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDetail() {
      this.dialogDetail = false;
      this.editedItem = this.defaultItem;
      this.editedIndex = -1;
      this.$refs.form.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.statusdata = 0;
      this.pesancek = "";
      // this.$refs.form.reset();
      this.dialogEdit = false;

      // this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },

    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          // console.log(this.editedItem.jenis_budidaya);

          // console.log(JSON.stringify(this.textRows));
          const credentials = {
            kategori: this.editedItem.kategori,
            nik: this.editedItem.nik,
            jumlah_produksi: this.editedItem.jumlah_produksi,
            jenis_budidaya: this.editedItem.jenis_budidaya.toString(),

            air_deras: this.editedItem.air_deras,
            air_tenang: this.editedItem.air_tenang,
            terpal: this.editedItem.terpal,
            keramba: this.editedItem.keramba,

            nm_kelompok: this.editedItem.nm_kelompok,
            alamat_kelompok: this.editedItem.alamat_kelompok,
            nm_ketua: this.editedItem.nm_ketua,
            jumlah_anggota: this.editedItem.jumlah_anggota,

            nm_anggota: this.textRows,
          };
          const response = await AuthService.tambahIkanBudidaya(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getIkanBudidayaAll();
            this.getIkanKategori();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/perikanan/ikan-budidaya")
              .catch(() => {});
            this.dialog = false;
            this.dialogDetail = false;
            this.close();

            this.$refs.form.reset();
            console.log("berhasil simpan ikanbudidaya");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });
            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            kategori: this.editedItem.kategori,
            nik: this.editedItem.nik,
            jumlah_produksi: this.editedItem.jumlah_produksi,
            jenis_budidaya: this.editedItem.jenis_budidaya.toString(),

            air_deras: this.editedItem.air_deras,
            air_tenang: this.editedItem.air_tenang,
            terpal: this.editedItem.terpal,
            keramba: this.editedItem.keramba,

            nm_kelompok: this.editedItem.nm_kelompok,
            alamat_kelompok: this.editedItem.alamat_kelompok,
            nm_ketua: this.editedItem.nm_ketua,
            jumlah_anggota: this.editedItem.jumlah_anggota,

            nm_anggota: this.textRows,
          };
          const response = await AuthService.editIkanBudidaya(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getIkanBudidayaAll();
            this.getIkanKategori();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/perikanan/ikan-budidaya")
              .catch(() => {});
            this.dialog = false;
            this.dialogDetail = false;
            this.close();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
