<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Pengguna</h4></v-toolbar-title>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" persistent max-width="550px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="cekSimpan"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="ma-0 pa-1" cols="12" sm="6" md="6">
                      <v-text-field
                        label="Nama Pengguna *"
                        outlined
                        v-model="editedItem.name"
                        :rules="namaRules"
                        color="teal"
                      ></v-text-field>
                    </v-col>
                    <v-col class="ma-0 pa-1" cols="12" sm="6" md="6">
                      <v-text-field
                        label="Email Address *"
                        outlined
                        v-model="editedItem.email"
                        :rules="emailRules"
                        color="teal"
                      ></v-text-field>
                    </v-col>
                    <v-col class="ma-0 pa-1" cols="12" sm="6" md="6">
                      <v-text-field
                        label="Password *"
                        outlined
                        v-model="password"
                        :rules="passwordRules"
                        color="teal"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-2"
                        hint="At least 8 characters"
                        value="wqfasds"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                    <v-col class="ma-0 pa-1" cols="12" sm="6" md="6">
                      <v-text-field
                        label="Konfirmasi Password *"
                        outlined
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        color="teal"
                        v-model="confirmPassword"
                        :rules="
                          confirmPasswordRules.concat(passwordConfirmationRule)
                        "
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                      <v-select
                        color="teal"
                        @change="cekRole()"
                        :items="levels"
                        v-model="level"
                        item-text="roles"
                        item-value="id"
                        label="Pilih Role / Level *"
                        outlined
                        :rules="levelRules"
                        required
                      >
                      </v-select>
                    </v-col>

                    <template v-if="this.level === 'provinsi'">
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          :rules="provRules"
                          :items="countries"
                          v-model="country"
                          item-text="provinsi"
                          item-value="id"
                          label="Pilih Provinsi *"
                          outlined
                          required
                        >
                        </v-select>
                      </v-col>
                    </template>
                    <template v-if="this.level === 'kota'">
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          @change="cekNull()"
                          :rules="provRules"
                          :items="countries"
                          v-model="country"
                          item-text="provinsi"
                          item-value="id"
                          label="Pilih Provinsi *"
                          outlined
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          :items="states"
                          v-model="state"
                          item-text="kota"
                          item-value="id"
                          label="Pilih Kabupaten/Kota *"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                    </template>
                    <template v-if="this.level === 'kecamatan'">
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          @change="cekNull()"
                          :rules="provRules"
                          :items="countries"
                          v-model="country"
                          item-text="provinsi"
                          item-value="id"
                          label="Pilih Provinsi *"
                          outlined
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          @change="getNullKecs()"
                          :items="states"
                          v-model="state"
                          item-text="kota"
                          item-value="id"
                          label="Pilih Kabupaten/Kota *"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          :items="kecs"
                          v-model="kec"
                          item-text="kecamatan"
                          item-value="id"
                          label="Pilih Kecamatan *"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                    </template>
                    <template v-if="this.level === 'kelurahan'">
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          @change="cekNull()"
                          :rules="provRules"
                          :items="countries"
                          v-model="country"
                          item-text="provinsi"
                          item-value="id"
                          label="Pilih Provinsi *"
                          outlined
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          @change="getNullKecs()"
                          :items="states"
                          v-model="state"
                          item-text="kota"
                          item-value="id"
                          label="Pilih Kabupaten/Kota *"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          @change="getNullKels()"
                          :items="kecs"
                          v-model="kec"
                          item-text="kecamatan"
                          item-value="id"
                          label="Pilih Kecamatan *"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                      <v-col class="ma-0 pa-1" cols="12" sm="12" md="12">
                        <v-select
                          color="teal"
                          :items="lurahs"
                          v-model="lurah"
                          item-text="kelurahan"
                          item-value="id"
                          label="Pilih Kelurahan/Desa *"
                          outlined
                          required
                        ></v-select>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-card>

    <br />
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="6" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin untuk mengahpus data ?</h5>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <h4 class="teal--text">Nama : {{ editedItem.name }}</h4>
            <h4 class="teal--text">Email : {{ editedItem.email }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table :headers="headers" :items="hasildatas" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-row justify="center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="lighten-4 mx-2"
                  @click="editItem(item)"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon color="light-blue" dark v-bind="attrs" v-on="on">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit : {{ item.dusun }}</span>
            </v-tooltip>
            <!-- <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon center> mdi-pencil </v-icon>
            </v-btn> -->
            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon center>mdi-delete</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:no-data> </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  async mounted() {
    this.secretMessage = await AuthService.dataPengguna();
    this.hasildatas = this.secretMessage.data.data;
    this.firstLoad = false;
    this.sloading = false;
  },
  name: "Pengguna",
  data() {
    return {
      // provinsi select
      country: null,
      countries: {},
      // Kota select
      state: null,
      states: [
        {
          kota: "",
        },
      ],
      // Kecamatan Select
      kecs: [
        {
          kecamatan: "",
        },
      ],
      kec: null,
      // Kelurahan Select
      lurahs: [
        {
          kelurahan: "",
        },
      ],
      lurah: null,

      show1: true,
      show2: false,

      level: null,
      levels: [
        {
          roles: "Admin Provinsi",
          id: "provinsi",
        },
        {
          roles: "Admin Kabupaten/Kota",
          id: "kota",
        },
        {
          roles: "Admin Kecamatan",
          id: "kecamatan",
        },
        {
          roles: "Admin Kelurahan/Desa",
          id: "kelurahan",
        },
      ],

      hasildatas: [],
      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,
      password: "",
      confirmPassword: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      namaRules: [(v) => !!v || "Nama provinsi tidak boleh kosong"],
      emailRules: [
        (v) => !!v || "E-mail  tidak boleh kosong",
        (v) => /.+@.+/.test(v) || "Email Harus Valid",
      ],
      levelRules: [(v) => !!v || "Level atau Role is required"],

      provRules: [(v) => !!v || "Nama Provinsi tidak boleh kosong"],
      passwordRules: [(v) => !!v || "Password is required"],
      confirmPasswordRules: [(v) => !!v || "Password is required"],

      headers: [
        { text: "Nama Pengguna", value: "name" },
        { text: "Email Address", value: "email" },
        { text: "Level / Role Akses", value: "role" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        country: null,
        state: null,
        kec: null,
        lurah: null,
        password: "",
        name: "",
        email: "",
      },
      defaultItem: {
        country: null,
        state: null,
        kec: null,
        lurah: null,
        password: "",
        name: "",
        email: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Pengguna"
        : "Form Edit Data Pengguna";
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password must match";
    },
  },
  methods: {
    cekRole() {
      this.hasilrole = this.level;
      // console.log(this.hasilrole);
    },
    getCountries: function () {
      AuthService.getCamat().then(
        function (response) {
          this.countries = response.data.data;

          console.log(this.countries);
        }.bind(this)
      );
    },
    cekNull() {
      (this.state = null), this.getStates();
    },
    getStates: function () {
      const credentials = {
        id_provinsi: this.country,
      };
      AuthService.getKotaByid(credentials).then(
        function (response) {
          this.states = response.data;
        }.bind(this)
      );
    },
    getNullKecs() {
      (this.kec = null), this.getKecs();
    },
    getKecs: function () {
      const credentials = {
        id_provinsi: this.country,
        id_kota: this.state,
      };
      AuthService.getKecByid(credentials).then(
        function (response) {
          this.kecs = response.data;
          console.log(this.kecs);
        }.bind(this)
      );
    },
    getNullKels() {
      (this.lurah = null), this.getKels();
    },
    getKels: function () {
      const credentials = {
        id_provinsi: this.country,
        id_kota: this.state,
        id_kecamatan: this.kec,
      };
      AuthService.getLurahByid(credentials).then(
        function (response) {
          this.lurahs = response.data;
          console.log(this.lurahs);
        }.bind(this)
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.country === null,
          this.state === null,
          this.kec === null,
          this.lurah === null,
          this.$refs.form.reset();
      });
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deletePengguna(credentials);
      this.message = response.message;
      if (response.status === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });

        this.$router.push("/dashboard/pengguna").catch(() => {});
        this.secretMessage = await AuthService.dataPengguna();
        this.hasildatas = this.secretMessage.data;
        this.dialogDelete = this.closeDelete();
        console.log(this.secretMessage.data);
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },

    cekSimpan() {
      console.log(this.state);

      if (this.hasilrole === "provinsi" && this.country === null) {
        this.$notification.error("Provinsi Tidak Boleh Kosong", {
          timer: 5,
        });
      } else if (this.hasilrole === "kota" && this.state === null) {
        this.$notification.error("Kabupaten/kota Tidak Boleh Kosong", {
          timer: 5,
        });
      } else if (this.hasilrole === "kecamatan" && this.kec === null) {
        this.$notification.error("Kecamatan Tidak Boleh Kosong", {
          timer: 5,
        });
      } else if (this.hasilrole === "kelurahan" && this.lurah === null) {
        this.$notification.error("Kelurahan Tidak Boleh Kosong", {
          timer: 5,
        });
      } else {
        this.handleSubmit();
      }
    },

    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan

          const credentials = {
            id_provinsi: this.country,
            id_kota: this.state,
            id_kecamatan: this.kec,
            id_kelurahan: this.lurah,

            name: this.editedItem.name,
            email: this.editedItem.email,
            password: this.password,
            role: this.hasilrole,
          };
          const response = await AuthService.simpanPengguna(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/dashboard/pengguna").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.dataPengguna();
            this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan pengguna");
            console.log(this.secretMessage.data);
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update

          const credentials = {
            id: this.editedItem.id,
            id_provinsi: this.country,
            kota: this.editedItem.kota,
          };
          const response = await AuthService.ubahKota(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/dashboard/pengguna").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.dataPengguna();
            this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },
  created: function () {
    this.getCountries();
  },
};
</script>

