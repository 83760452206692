<template>
  <v-card elevation="0">
    <v-row dense>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="rumahkosongvalue.nama_pemilik"
          label="Nama Pemilik Bangunan"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahkosongvalue.kondisi_rumah"
          :items="itemskondisirumah"
          item-text="kodisi_rumah"
          item-value="kodisi_rumah"
          color="teal"
          label="Pilih Kondisi Rumah"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahkosongvalue.sptpbb"
          :items="itemspbb"
          item-text="status_spt"
          item-value="status_spt"
          color="teal"
          label="Pilih Status SPPT-PBB"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="rumahkosongvalue.link_foto"
          label="Link Foto 360 deraja"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="rumahkosongvalue.no_telp"
          label="Nomor Telepon"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <br />
    <v-card-actions>
      <v-btn class="white--text" @click="stepSebelum" color="teal" outlined
        ><v-icon color="black" left>mdi-arrow-left</v-icon>Sebelumnya
      </v-btn>
      <!-- <v-btn class="white--text" @click="cekSarana" color="green"
        >Cek Sarana
      </v-btn> -->
      <v-spacer></v-spacer>
      <template v-if="toStep !== index">
        <v-btn
          v-if="
            rumahkosongvalue.nama_pemilik !== '' &&
            rumahkosongvalue.kondisi_rumah !== '' &&
            rumahkosongvalue.sptpbb !== '' &&
            rumahkosongvalue.link_foto !== ''
          "
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          v-if="
            rumahkosongvalue.nama_pemilik !== '' &&
            rumahkosongvalue.kondisi_rumah !== '' &&
            rumahkosongvalue.sptpbb !== '' &&
            rumahkosongvalue.link_foto !== ''
          "
          class="white--text"
          @click="simpanData"
          color="teal"
          >Update Data
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="simpanData"
          color="teal"
          >Update Data
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>



<script>
export default {
  props: {
    index: Number,
    toStep: Number,
    itemskondisirumah: Array,
    itemspbb: Array,
    isibangunan: Object,
    cekado: Object,
  },
  data() {
    return {
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],

      menu2: false,
      time: null,
      menu3: false,

      rumahkosongvalue: {
        id: "",
        nama_pemilik: "",
        kondisi_rumah: "",
        sptpbb: "",
        link_foto: "",
        no_telp: "",
      },
    };
  },

  mounted() {
    console.log(this.cekado.rtkstep);
    if (this.cekado.rtkstep === false) {
      this.rumahkosongvalue.id = "";
      this.rumahkosongvalue.nama_pemilik = "";
      this.rumahkosongvalue.kondisi_rumah = "";
      this.rumahkosongvalue.sptpbb = "";
      this.rumahkosongvalue.link_foto = "";
      this.rumahkosongvalue.no_telp = "";
    } else {
      this.rumahkosongvalue.id = this.isibangunan.rtkvalue.id;
      this.rumahkosongvalue.nama_pemilik =
        this.isibangunan.rtkvalue.nama_pemilik;
      this.rumahkosongvalue.kondisi_rumah =
        this.isibangunan.rtkvalue.kondisi_rumah;
      this.rumahkosongvalue.sptpbb = this.isibangunan.rtkvalue.sptpbb;
      this.rumahkosongvalue.link_foto = this.isibangunan.rtkvalue.link_foto;
      this.rumahkosongvalue.no_telp = this.isibangunan.rtkvalue.no_telp;
    }
  },

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1945 },
        (value, index) => 1946 + index
      );
    },
  },
  methods: {
    stepSebelum: function () {
      var hasilstep = this.index + 1;
      this.$emit("data-step", hasilstep);
    },
    stepLanjut: function () {
      var hasilsteplanjut = this.index + 3;
      this.$emit("data-steplanjut", hasilsteplanjut);

      this.$emit("data-rkt", this.rumahkosongvalue);
    },
    simpanData: function () {
      this.$emit("data-rkt", this.rumahkosongvalue);
      this.$emit("data-final", this.dialogfinal);
    },
  },
};
</script>