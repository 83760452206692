<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Bantuan Sosial</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title><h5>Apakah yakin hapus data bantuan ?</h5> </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-list-item-content>
            <v-list-item-title>
              <h5 class="teal--text">
                Nomor Kartu Keluarga
              </h5></v-list-item-title
            >
            <v-list-item-subtitle>
              <h3 class="teal--text">
                {{ editedItem.nokk }}
              </h3></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogEdit" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Edit Data Bantuan : {{ editedItem.nokk }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeEdit"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="updateBansos"
            v-model="valid"
            lazy-validation
          >
            <br />

            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.nokk"
                  :rules="nokkRules"
                  label="Nomor Kartu Keluarga"
                  required
                  filled
                  counter="16"
                  maxlength="16"
                  dense
                  color="teal"
                  hint="input no kartu keluarga tekan -> cek kk"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn small dark @click="cekKAKA" outlined color="teal">
                  CEK KK
                </v-btn>
                <h5 v-if="this.statusdata === 1" class="teal--text">
                  {{ this.pesancek }}
                </h5>
                <h5 v-else-if="this.statusdata === 0" class="red--text">
                  {{ this.pesancek }}
                </h5>
                <h5 v-else></h5>
              </v-col>
            </v-row>
            <v-divider class="pb-3"></v-divider>
            <v-row
              dense
              v-show="this.editedItem.nik !== null && this.statusdata === 1"
            >
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nik"
                  readonly
                  color="teal"
                  label="Nomor Induk Kependudukan (NIK)"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nama"
                  readonly
                  color="teal"
                  label="Nama Lengkap"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.StatusKeluarga"
                  readonly
                  color="teal"
                  label="Status Keluarga"
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.tahun"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Pilih Tahun Bantuan"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.bulan"
                  :items="itemsbulan"
                  item-text="bulan"
                  item-value="bulan"
                  color="teal"
                  label="Pilih Bulan Bantuan"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.kategori_bantuan"
                  :items="itemkategoribansos"
                  item-text="kategori_bantuan"
                  item-value="kategori_bantuan"
                  color="teal"
                  label="Pilih Kategori Bantuan"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.jenis_bantuan"
                  :rules="wajibRules"
                  label="Jenis Bantuan"
                  required
                  filled
                  dense
                  color="teal"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nilai_bantuan"
                  label="Nilai Besar Bantuan"
                  required
                  filled
                  dense
                  @input="handleInput"
                  color="teal"
                ></v-text-field
              ></v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-show="editedItem.nik !== null && this.statusdata === 1"
                :loading="loading"
                :disabled="!valid"
                @click="validate"
                type="submit"
                color="teal"
                class="mr-0 white--text"
              >
                Update Data
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>

            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "DataBansos",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },
  },
  data() {
    return {
      itemkategoribansos: [{}],
      itemsbulan: [
        "JANUARI",
        "FEBRUARI",
        "MARET",
        "APRIL",
        "MEI",
        "JUNI",
        "JULI",
        "AGUSTUS",
        "SEPTEMBER",
        "OKTOBER",
        "NOVEMBER",
        "DESEMBER",
      ],

      // Data Bansos

      pesancek: "",
      statusdata: 1,
      datailusi: {},

      // Dialog
      dialogEdit: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: "NO KK", value: "nokk" },
        { text: "NIK", value: "nik" },
        { text: "Nama", value: "kepala_keluarga.nama" },
        { text: "Kategori", value: "kategori_bantuan" },
        { text: "Jenis", value: "jenis_bantuan" },
        { text: "Tahun", value: "tahun" },
        { text: "Bulan", value: "bulan" },
        { text: "Besar Bantuan", value: "nilai_bantuan" },

        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      individuItem: {
        id: 0,
      },

      editedIndex: -1,
      editedItem: {
        id: 0,
        id_nokk: null,
        id_nik: null,
        nik: "",
        nama: "",
        StatusKeluarga: "",
        nokk: "",
        tahun: "",
        bulan: "",
        kategori_bantuan: "",
        nilai_bantuan: "",
        jenis_bantuan: "",
        kepala_keluarga: {
          id_nik: "",
          nama: "",
          nik: "",
          StatusKeluarga: "",
        },
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    value: {
      handler(after) {
        this.editedItem.nilai_bantuan = this.format(after);
      },
      immediate: true,
    },
  },

  created() {
    this.getBantuanAll();
    this.getKategoriBantuan();
    this.detailUsers();
    this.getDusun();
  },
  methods: {
    format: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInput() {
      this.editedItem.nilai_bantuan = this.format(
        this.editedItem.nilai_bantuan
      );
      this.$emit(
        "v-textfield",
        (this.editedItem.nilai_bantuan + "").replace(/[0-9]/g, "")
      );
    },
    cekKAKA: function () {
      const credentials = {
        nokk: this.editedItem.nokk,
      };
      AuthService.cekNikKk(credentials).then(
        function (response) {
          if (response.success === true) {
            this.editedItem.id_nokk = response.data.id;
            this.editedItem.id_nik = response.data.anggotaKK[0].id;
            this.editedItem.nik = response.data.anggotaKK[0].nik;
            this.editedItem.nama = response.data.anggotaKK[0].nama;
            this.editedItem.StatusKeluarga =
              response.data.anggotaKK[0].StatusKeluarga;

            // pesan
            this.statusdata = 1;
            this.pesancek = "Data Ditemukan Pada Database";
          } else {
            this.statusdata = 0;
            this.pesancek = "Data Tidak Ditemukan Pada Database";
          }
        }.bind(this)
      );
    },
    getKategoriBantuan: function () {
      AuthService.getKategoriBantuan().then(
        function (response) {
          this.itemkategoribansos = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(response.data.data);
        }.bind(this)
      );
    },
    // Data Bangunan
    getBantuanAll: function () {
      AuthService.getBantuanAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);

      this.statusdata = 1;
      this.pesancek = "";
      this.dialogEdit = true;

      this.editedItem.nik = this.editedItem.kepala_keluarga.nik;
      this.editedItem.nama = this.editedItem.kepala_keluarga.nama;
      this.editedItem.StatusKeluarga =
        this.editedItem.kepala_keluarga.StatusKeluarga;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },

    closeTambah() {
      this.dialogTambahPenduduk = false;

      this.$refs.form.reset();
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteBantuan(credentials);
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getBantuanAll();

        this.$router.push("/admin-kelurahan/bansos/data").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.statusdata = 0;
      this.pesancek = "";
      // this.$refs.form.reset();
      this.dialogEdit = false;

      // this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },
    async updateBansos() {
      this.loading = true;

      try {
        const credentials = {
          id: this.editedItem.id,
          tahun: this.editedItem.tahun,
          bulan: this.editedItem.bulan,
          kategori_bantuan: this.editedItem.kategori_bantuan,
          nokk: this.editedItem.nokk,
          id_nokk: this.editedItem.id_nokk,
          nik: this.editedItem.nik,
          id_nik: this.editedItem.id_nik,

          jenis_bantuan: this.editedItem.jenis_bantuan,
          besar_bantuan: this.editedItem.nilai_bantuan.replace(/[^0-9]/g, ""),
        };

        const response = await AuthService.editBantuan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil update bansos");
          this.getBantuanAll();
          this.getKategoriBantuan();
          this.$router.push("/admin-kelurahan/bansos/data").catch(() => {});
          this.loading = false;
          this.closeEdit();
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.closeEdit();
        }
      } catch {
        this.loading = false;
        this.closeEdit();
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
    this.handleInput();
  },
};
</script>



<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>





