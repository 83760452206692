<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Form Input Data Bangunan</h4></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-stepper elevation="1" v-model="e1" v-else>
      <v-stepper-header>
        <v-stepper-step color="teal" :complete="e1 > 1" :step="1">
          Data Bangunan
        </v-stepper-step>
        <div v-for="(item, index) in kategori_bangunan" :key="index">
          <v-stepper-step
            color="teal"
            :complete="e1 > index + 2"
            :step="index + 2"
          >
            {{ item }}
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items>
        <v-form @submit.prevent="handleSubmit" lazy-validation ref="form">
          <v-stepper-content step="1">
            <v-card elevation="0" class="mb-2" color="white">
              <v-row dense>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    color="teal"
                    label="Nomor Bangunan"
                    filled
                    dense
                    maxlength="6"
                    :rules="nobangRules"
                    v-model="no_bangunan"
                    :counter="6"
                    required
                  ></v-text-field>
                  <v-select
                    :rules="wajibRules"
                    v-model="kategori_bangunan"
                    :items="itemskatbangunan"
                    item-text="kategori_bangunan"
                    item-value="kategori_bangunan"
                    color="teal"
                    label="Pilih Kategori Bangunan"
                    filled
                    multiple
                    dense
                    required
                  ></v-select>
                  <v-select
                    :rules="wajibRules"
                    v-model="jaringan_telepon"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Pilih Jaringan Telepon / Internet Kabel"
                    filled
                    dense
                  ></v-select>
                  <v-select
                    :rules="wajibRules"
                    v-model="jaringan_gsm"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Pilih Jaringan Telepon GSM"
                    filled
                    dense
                  ></v-select>
                  <v-select
                    :rules="wajibRules"
                    v-model="internet_gsm"
                    :items="itemsinet"
                    item-text="status_jaringan"
                    item-value="status_jaringan"
                    color="teal"
                    label="Pilih Jaringan Internet GSM"
                    filled
                    dense
                  ></v-select>

                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        color="teal"
                        :value="this.kecamatan"
                        label="Kecamatan"
                        filled
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        color="teal"
                        :value="this.kelurahan"
                        label="Kelurahan/Desa"
                        filled
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-select
                    :rules="dusunRules"
                    v-model="id_dusun"
                    :items="itemsdusun"
                    item-text="dusun"
                    item-value="id"
                    color="teal"
                    label="Pilih Dusun"
                    filled
                    dense
                  ></v-select>
                </v-col>

                <v-col dense class="mb-2" cols="12" md="6">
                  <v-text-field
                    color="teal"
                    label="Latitude"
                    filled
                    dense
                    :rules="wajibRules"
                    v-model="lat"
                    required
                    readonly
                  ></v-text-field>
                  <v-text-field
                    color="teal"
                    label="Longitude"
                    filled
                    dense
                    :rules="wajibRules"
                    v-model="lng"
                    required
                    readonly
                  ></v-text-field>
                  <MapsCom @data-peta="petaValue" />
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  v-if="
                    no_bangunan !== '' &&
                    kategori_bangunan !== '' &&
                    lat !== '' &&
                    lng !== '' &&
                    id_dusun !== '' &&
                    jaringan_telepon !== '' &&
                    jaringan_gsm !== '' &&
                    internet_gsm !== ''
                  "
                  :disabled="!valid"
                  class="ma-2 white--text"
                  @click="nextPetama"
                  color="teal"
                  >Selanjutnya<v-icon color="white" right
                    >mdi-arrow-right</v-icon
                  >
                </v-btn>
                <v-btn v-else disabled class="ma-2 white--text" color="teal"
                  >selanjutnya<v-icon color="white" right
                    >mdi-arrow-right</v-icon
                  >
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <div v-for="(item, index) in kategori_bangunan" :key="index">
            <v-stepper-content :step="index + 2">
              <v-form
                @submit.prevent="simpanBangungan"
                v-model="valid"
                lazy-validation
                ref="form"
              >
                <div v-if="item === 'Sarana Umum'">
                  <SaranaUmumCom
                    v-bind:index="index"
                    v-bind:toStep="toStep"
                    v-bind:dataitem="itemssarana"
                    v-bind:itemsjenisbangunan="itemsjenisbangunan"
                    v-bind:itemsstatusbangunan="itemsstatusbangunan"
                    v-bind:itemsstatuslahan="itemsstatuslahan"
                    @data-sarana="saranaUmumValue"
                    @data-step="stepValue"
                    @data-steplanjut="stepLanjutValue"
                    @data-final="dialogFinal"
                  />
                </div>

                <div v-if="item === 'Wirausaha'">
                  <WiraUsahaCom
                    v-bind:index="index"
                    v-bind:toStep="toStep"
                    v-bind:itemsoperasional="itemsoperasional"
                    v-bind:itemsmodalusaha="itemsmodalusaha"
                    v-bind:itemsyatidak="itemsyatidak"
                    @data-step="stepValue"
                    @data-wirausaha="wirausahaValue"
                    @data-final="dialogFinal"
                    @data-steplanjut="stepLanjutValue"
                  />
                </div>
                <div v-if="item === 'Rumah Tinggal Kosong'">
                  <RumahKosCom
                    v-bind:index="index"
                    v-bind:toStep="toStep"
                    v-bind:itemskondisirumah="itemskondisirumah"
                    v-bind:itemspbb="itemspbb"
                    @data-rkt="rktValue"
                    @data-step="stepValue"
                    @data-steplanjut="stepLanjutValue"
                    @data-final="dialogFinal"
                  />
                </div>
                <div v-if="item === 'Rumah Tinggal Isi'">
                  <RumahTinggalCom
                    v-bind:index="index"
                    v-bind:toStep="toStep"
                    v-bind:itemskondisirumah="itemskondisirumah"
                    v-bind:itemsyatidak="itemsyatidak"
                    v-bind:itemstempat_tinggal="itemstempat_tinggal"
                    v-bind:itemsstatus_lahan="itemsstatus_lahan"
                    v-bind:itemsjenis_lantai="itemsjenis_lantai"
                    v-bind:itemsdinding="itemsdinding"
                    v-bind:itemsjendela="itemsjendela"
                    v-bind:itemsbahan_atap="itemsbahan_atap"
                    v-bind:itemspenerangan_rumah="itemspenerangan_rumah"
                    v-bind:itemsenergi_masak="itemsenergi_masak"
                    v-bind:itemssumber_kayu="itemssumber_kayu"
                    v-bind:itemsbuang_sampah="itemsbuang_sampah"
                    v-bind:itemsmck="itemsmck"
                    v-bind:itemsair_mandi="itemsair_mandi"
                    v-bind:itemsair_besar="itemsair_besar"
                    v-bind:itemsair_minum="itemsair_minum"
                    v-bind:itemsbuang_limbah="itemsbuang_limbah"
                    v-bind:itemstransportasi="itemstransportasi"
                    @data-rti="rtiValue"
                    @data-step="stepValue"
                    @data-steplanjut="stepLanjutValue"
                    @data-final="dialogFinal"
                  />
                </div>
              </v-form>
            </v-stepper-content>
          </div>
        </v-form>
      </v-stepper-items>
    </v-stepper>

    <br />

    <v-dialog v-model="dialogfinal" persistent max-width="450px">
      <v-form
        @submit.prevent="simpanBangungan"
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin menyimpan data bangunan ?</h5></v-card-title
          >
          <v-divider></v-divider>

          <v-spacer></v-spacer>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click="dialogfinal = false"
              >Batal</v-btn
            >

            <v-btn
              dialogMeninggal="true"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              class="white--text"
              color="teal"
              >Proses Simpan Data</v-btn
            >
            <br />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>



<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";
import SaranaUmumCom from "@/components/SaranaUmum.vue";
import WiraUsahaCom from "@/components/WirausahaComp.vue";
import RumahKosCom from "@/components/RumahKosCom.vue";
import RumahTinggalCom from "@/components/RumahTinggalCom.vue";
import MapsCom from "@/components/MapsCom.vue";

export default {
  name: "BangunanForm",
  components: {
    SaranaUmumCom,
    WiraUsahaCom,
    RumahKosCom,
    RumahTinggalCom,
    MapsCom,
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1980 },
        (value, index) => 1981 + index
      );
    },
  },
  data() {
    return {
      //
      // Items SARANA UMUM
      itemssarana: [{}],
      itemsjenisbangunan: [{}],
      itemsstatusbangunan: [{}],
      itemsstatuslahan: [{}],
      itemsinet: [{}],

      // Items Wirausaha
      itemsoperasional: [{}],
      itemsmodalusaha: [{}],
      itemsyatidak: [{}],

      // Items Rumah kosong
      itemskondisirumah: [{}],
      itemspbb: [{}],

      // Items Rumah Tinggal Isi
      itemstempat_tinggal: [{}],
      itemsstatus_lahan: [{}],
      itemsjenis_lantai: [{}],
      itemsdinding: [{}],
      itemsjendela: [{}],
      itemsbahan_atap: [{}],
      itemspenerangan_rumah: [{}],
      itemsenergi_masak: [{}],
      itemssumber_kayu: [{}],
      itemsbuang_sampah: [{}],
      itemsmck: [{}],
      itemsair_mandi: [{}],
      itemsair_besar: [{}],
      itemsair_minum: [{}],
      itemsbuang_limbah: [{}],
      itemskendaraan: [{}],
      itemstransportasi: [{}],

      activePicker: null,
      date: null,
      menu: false,
      // DATE TGL LAHIR
      e1: 1,
      toStep: null,
      valid: true,

      // ITEMS BANGUNAN
      itemskatbangunan: [{}],

      // DATA BANGUNAN
      no_bangunan: "",
      kategori_bangunan: "",
      lat: "",
      lng: "",
      id_dusun: "",
      // baru
      jaringan_telepon: "",
      jaringan_gsm: "",
      internet_gsm: "",

      // ITEM DATA KK
      detailusers: [{}],

      itemsdusun: [{}],

      // VALIDASI
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],

      // dialog: false,
      // dialogDelete: false,

      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      dialogfinal: false,

      isisaranaumum: [{}],
      isirkt: [{}],
      isirti: [{}],
      isiwirausaha: [{}],
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.textRows.activePicker = "YEAR"));
    },
  },
  methods: {
    // DATA FINAL

    dialogFinal(dialogFinal) {
      this.dialogfinal = true;
      console.log("PREVIEW DATA");
    },
    // VALUE SARANA UMUM
    petaValue(petaValue) {
      this.lat = petaValue.latitude;
      this.lng = petaValue.longitude;
    },

    nextPetama() {
      this.e1 = 2;
      console.log("ini step pertama");
      this.toStep = this.kategori_bangunan.length - 1;

      console.log(this.kategori_bangunan);
    },
    saranaUmumValue(saranaValue) {
      this.isisaranaumum = saranaValue;
      console.log("ini di SARANA");
      console.log(saranaValue);
    },
    rktValue(rktValue) {
      this.isirkt = rktValue;
      console.log("ini di RKT");
      console.log(rktValue);
    },
    rtiValue(rtiValue) {
      this.isirti = rtiValue;
      console.log("ini di RTI");
      console.log(rtiValue);
    },
    wirausahaValue(wirausahaValue) {
      this.isiwirausaha = wirausahaValue;
      console.log("ini di Wirausaha Value");
      console.log(wirausahaValue);
    },
    stepValue(stepSebelum) {
      this.e1 = stepSebelum;
      console.log("ini step");
      console.log(stepSebelum);
    },
    stepLanjutValue(stepLanjut) {
      this.e1 = stepLanjut;
      console.log("ini step");
      console.log(stepLanjut);
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    getKategoriBangunan: function () {
      AuthService.getKategoriBangunan().then(
        function (response) {
          this.itemskatbangunan = response.data.data;
        }.bind(this)
      );
    },

    // SARANAUMUM
    getJenusSaranaUmum: function () {
      AuthService.getJenusSaranaUmum().then(
        function (response) {
          this.itemssarana = response.data.data;
        }.bind(this)
      );
    },
    getJenisBangunan: function () {
      AuthService.getJenisBangunan().then(
        function (response) {
          this.itemsjenisbangunan = response.data.data;
        }.bind(this)
      );
    },

    getStatusBangunan: function () {
      AuthService.getStatusBangunan().then(
        function (response) {
          this.itemsstatusbangunan = response.data.data;
        }.bind(this)
      );
    },
    getStatusLahan: function () {
      AuthService.getStatusLahan().then(
        function (response) {
          this.itemsstatuslahan = response.data.data;
        }.bind(this)
      );
    },

    // WIRAUSAHA

    getStatusOperational: function () {
      AuthService.getStatusOperational().then(
        function (response) {
          this.itemsoperasional = response.data.data;
        }.bind(this)
      );
    },
    getSumberModal: function () {
      AuthService.getSumberModal().then(
        function (response) {
          this.itemsmodalusaha = response.data.data;
        }.bind(this)
      );
    },
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    getStatusJaringan: function () {
      AuthService.getStatusJaringan().then(
        function (response) {
          this.itemsinet = response.data.data;
        }.bind(this)
      );
    },

    // ITEM RUMAH KOSONG
    getKondisiRumah: function () {
      AuthService.getKondisiRumah().then(
        function (response) {
          this.itemskondisirumah = response.data.data;
        }.bind(this)
      );
    },
    getStatusSPTPBB: function () {
      AuthService.getStatusSPTPBB().then(
        function (response) {
          this.itemspbb = response.data.data;
        }.bind(this)
      );
    },

    // ITEM RUMAH TINGGAL ISI
    getTempatTinggal: function () {
      AuthService.getTempatTinggal().then(
        function (response) {
          this.itemstempat_tinggal = response.data.data;
        }.bind(this)
      );
    },
    getStatusLahanB: function () {
      AuthService.getStatusLahanB().then(
        function (response) {
          this.itemsstatus_lahan = response.data.data;
        }.bind(this)
      );
    },
    getJenisLantai: function () {
      AuthService.getJenisLantai().then(
        function (response) {
          this.itemsjenis_lantai = response.data.data;
        }.bind(this)
      );
    },
    getJenisDinding: function () {
      AuthService.getJenisDinding().then(
        function (response) {
          this.itemsdinding = response.data.data;
        }.bind(this)
      );
    },
    getStatusJendela: function () {
      AuthService.getStatusJendela().then(
        function (response) {
          this.itemsjendela = response.data.data;
        }.bind(this)
      );
    },
    getBahanAtap: function () {
      AuthService.getBahanAtap().then(
        function (response) {
          this.itemsbahan_atap = response.data.data;
        }.bind(this)
      );
    },
    getPeneranganRumah: function () {
      AuthService.getPeneranganRumah().then(
        function (response) {
          this.itemspenerangan_rumah = response.data.data;
        }.bind(this)
      );
    },

    getEnergiMasak: function () {
      AuthService.getEnergiMasak().then(
        function (response) {
          this.itemsenergi_masak = response.data.data;
        }.bind(this)
      );
    },
    getKayuBakar: function () {
      AuthService.getKayuBakar().then(
        function (response) {
          this.itemssumber_kayu = response.data.data;
        }.bind(this)
      );
    },
    getPembuanganSampah: function () {
      AuthService.getPembuanganSampah().then(
        function (response) {
          this.itemsbuang_sampah = response.data.data;
        }.bind(this)
      );
    },
    getFasilitasMCK: function () {
      AuthService.getFasilitasMCK().then(
        function (response) {
          this.itemsmck = response.data.data;
        }.bind(this)
      );
    },
    getSumberAirMandi: function () {
      AuthService.getSumberAirMandi().then(
        function (response) {
          this.itemsair_mandi = response.data.data;
        }.bind(this)
      );
    },
    getFasilitasBAB: function () {
      AuthService.getFasilitasBAB().then(
        function (response) {
          this.itemsair_besar = response.data.data;
        }.bind(this)
      );
    },
    getSumberAirMinum: function () {
      AuthService.getSumberAirMinum().then(
        function (response) {
          this.itemsair_minum = response.data.data;
        }.bind(this)
      );
    },
    getLimbahCair: function () {
      AuthService.getLimbahCair().then(
        function (response) {
          this.itemsbuang_limbah = response.data.data;
        }.bind(this)
      );
    },
    getTransportasi: function () {
      AuthService.getTransportasi().then(
        function (response) {
          this.itemstransportasi = response.data.data;
        }.bind(this)
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      if (this.status_kk !== "Pemecahan") {
        this.loading = true;
        try {
          let formData = new FormData();
          formData.append("foto_kk", this.imageData);
          formData.append("nokk", this.nokk);
          formData.append("status_kk", this.status_kk);
          formData.append("jenis_kk", this.jenis_kk);
          formData.append("no_bangunan", this.no_bangunan);
          formData.append("id_dusun", this.id_dusun);
          formData.append("status_miskin", this.status_miskin);

          // array data
          const items = this.textRows;
          for (let i = 0; i < items.length; i++) {
            for (let key of Object.keys(items[i])) {
              formData.append(`data[${i}][${key}]`, items[i][key]);
            }
          }
          // untuk foto ktp dan foto selfie
          for (let i = 0; i < items.length; i++) {
            formData.append(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
            formData.append(`foto_selfie[${i}]`, items[i]["imageDataSelfie"]);
            console.log(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
          }

          const response = await AuthService.simpanWarga(formData);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/penduduk/create")
              .catch(() => {});
            this.dialog = false;
            // this.secretMessage = await AuthService.getCamat();
            // this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } catch (error) {
          this.message = error.response.data.message;
          this.$notification.error(error.response.message, {
            timer: 5,
          });
          this.loading = false;
        }
      } else {
        this.loading = true;
        try {
          let formData = new FormData();
          formData.append("foto_kk", this.imageData);
          formData.append("nokk", this.nokk);
          formData.append("status_kk", this.status_kk);
          formData.append("jenis_kk", this.jenis_kk);
          formData.append("no_bangunan", this.no_bangunan);
          formData.append("id_dusun", this.id_dusun);
          formData.append("status_miskin", this.status_miskin);

          // array data
          const items = this.textRows;
          for (let i = 0; i < items.length; i++) {
            for (let key of Object.keys(items[i])) {
              formData.append(`data[${i}][${key}]`, items[i][key]);
            }
          }
          // untuk foto ktp dan foto selfie
          for (let i = 0; i < items.length; i++) {
            formData.append(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
            formData.append(`foto_selfie[${i}]`, items[i]["imageDataSelfie"]);
            console.log(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
          }

          const response = await AuthService.pecahKK(formData);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/penduduk/create")
              .catch(() => {});
            this.dialog = false;
            // this.secretMessage = await AuthService.getCamat();
            // this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan pemecahan");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } catch (error) {
          this.message = error.response.data.message;
          this.$notification.error(error.response.message, {
            timer: 5,
          });
          this.loading = false;
        }
      }
    },

    async simpanBangungan() {
      try {
        this.loading = true;
        const credentials = {
          no_bangunan: this.no_bangunan,
          kategori: this.kategori_bangunan,
          link_bangunan: this.link_bangunan,
          jaringan_telepon: this.jaringan_telepon,
          jaringan_gsm: this.jaringan_gsm,
          internet_gsm: this.internet_gsm,
          lat: this.lat,
          long: this.lng,
          id_dusun: this.id_dusun,
          saranavalue: [this.isisaranaumum],
          rtkvalue: [this.isirkt],
          wirausahavalue: [this.isiwirausaha],
          rtivalue: [this.isirti],
        };
        console.log(this.isirkt);

        const response = await AuthService.tambahBangunan(credentials);
        console.log(response);
        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil simpan banguana");
          this.dialogfinal = false;
          this.$router.push("/admin-kelurahan/bangunan/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.dialogfinal = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },
  created: function () {
    this.getDusun();
  },
  async mounted() {
    this.getKategoriBangunan();
    this.detailUsers();
    this.getStatusJaringan();

    // sarana umum
    this.getJenusSaranaUmum();
    this.getJenisBangunan();
    this.getStatusBangunan();
    this.getStatusLahan();

    // wirausaha
    this.getStatusOperational();
    this.getSumberModal();
    this.getYatidak();

    // rumah kosong
    this.getKondisiRumah();
    this.getStatusSPTPBB();

    // rumah tinggal isi
    this.getTempatTinggal();
    this.getStatusLahanB();
    this.getJenisLantai();
    this.getJenisDinding();
    this.getStatusJendela();
    this.getBahanAtap();
    this.getPeneranganRumah();

    this.getEnergiMasak();
    this.getKayuBakar();
    this.getPembuanganSampah();
    this.getFasilitasMCK();
    this.getSumberAirMandi();
    this.getFasilitasBAB();
    this.getSumberAirMinum();
    this.getLimbahCair();
    this.getTransportasi();
  },
};
</script>






