<template>
  <v-container class="py-5 px-6" fluid>
    <v-card flat elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title>
          <h4>Edit Data Bangunan - {{ isibangunan.no_bangunan }}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          class="ma-2 white--text"
          color="black"
          :to="'/admin-kelurahan/bangunan/data'"
          ><v-icon color="white" left>mdi-arrow-left</v-icon>kembali
        </v-btn>
      </v-toolbar>
    </v-card>

    <br />

    <v-card>
      <v-skeleton-loader
        v-if="sloading"
        :loading="firstLoad"
        type="article, actions"
      ></v-skeleton-loader>
      <v-stepper v-else elevation="0" v-model="e1">
        <v-stepper-header>
          <v-stepper-step color="teal" :complete="e1 > 1" :step="1">
            Data Bangunan
          </v-stepper-step>
          <div v-for="(item, index) in isibangunan.kategori" :key="index">
            <v-stepper-step
              color="teal"
              :complete="e1 > index + 2"
              :step="index + 2"
            >
              {{ item }}
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-form @submit.prevent="handleSubmit" lazy-validation ref="form">
            <v-stepper-content step="1">
              <v-card elevation="0" class="mb-2" color="white">
                <v-row dense>
                  <v-col cols="12" md="6" lg="6">
                    <v-text-field
                      color="teal"
                      label="Nomor Bangunan"
                      filled
                      dense
                      maxlength="6"
                      :rules="nobangRules"
                      v-model="editBang.no_bangunan"
                      :counter="6"
                      required
                    ></v-text-field>
                    <v-select
                      :rules="wajibRules"
                      v-model="isibangunan.kategori"
                      :items="itemskatbangunan"
                      item-text="kategori_bangunan"
                      item-value="kategori_bangunan"
                      color="teal"
                      label="Pilih Kategori Bangunan"
                      filled
                      multiple
                      dense
                      required
                    ></v-select>
                    <v-select
                      :rules="wajibRules"
                      v-model="editBang.jaringan_telepon"
                      :items="itemsyatidak"
                      item-text="optional"
                      item-value="optional"
                      color="teal"
                      label="Pilih Jaringan Telepon / Internet Kabel"
                      filled
                      dense
                    ></v-select>
                    <v-select
                      :rules="wajibRules"
                      v-model="editBang.jaringan_gsm"
                      :items="itemsyatidak"
                      item-text="optional"
                      item-value="optional"
                      color="teal"
                      label="Pilih Jaringan Telepon GSM"
                      filled
                      dense
                    ></v-select>
                    <v-select
                      :rules="wajibRules"
                      v-model="editBang.internet_gsm"
                      :items="itemsinet"
                      item-text="status_jaringan"
                      item-value="status_jaringan"
                      color="teal"
                      label="Pilih Jaringan Internet GSM"
                      filled
                      dense
                    ></v-select>

                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="isibangunan.kecamatan"
                          label="Kecamatan"
                          filled
                          readonly
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="isibangunan.kelurahan"
                          label="Kelurahan/Desa"
                          filled
                          dense
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-select
                      :rules="dusunRules"
                      v-model="editBang.id_dusun"
                      :items="itemsdusun"
                      item-text="dusun"
                      item-value="id"
                      color="teal"
                      label="Pilih Dusun"
                      filled
                      dense
                    ></v-select>
                  </v-col>

                  <v-col dense class="mb-2" cols="12" md="6">
                    <v-text-field
                      color="teal"
                      label="Latitude"
                      filled
                      dense
                      :rules="wajibRules"
                      v-model="isibangunan.lat"
                      required
                      readonly
                    ></v-text-field>
                    <v-text-field
                      color="teal"
                      label="Longitude"
                      filled
                      dense
                      :rules="wajibRules"
                      v-model="isibangunan.long"
                      required
                      readonly
                    ></v-text-field>

                    <MapsEdit
                      v-bind:markerslat="markerslat"
                      v-bind:markerslong="markerslong"
                      @data-peta="petaValue"
                    />
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    v-if="
                      editBang.no_bangunan !== '' &&
                      isibangunan.kategori !== '' &&
                      isibangunan.lat !== '' &&
                      isibangunan.long !== '' &&
                      editBang.id_dusun !== ''
                    "
                    :disabled="!valid"
                    class="ma-2 white--text"
                    @click="nextPetama"
                    color="teal"
                    >Selanjutnya<v-icon color="white" right
                      >mdi-arrow-right</v-icon
                    >
                  </v-btn>
                  <v-btn v-else disabled class="ma-2 white--text" color="teal"
                    >selanjutnya<v-icon color="white" right
                      >mdi-arrow-right</v-icon
                    >
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <div v-for="(item, index) in isibangunan.kategori" :key="index">
              <v-stepper-content :step="index + 2">
                <v-form
                  @submit.prevent="simpanBangungan"
                  v-model="valid"
                  lazy-validation
                  ref="form"
                >
                  <div v-if="item === 'Sarana Umum'">
                    <SaranaUmumEdit
                      v-bind:cekado="cekado"
                      v-bind:isibangunan="isibangunan"
                      v-bind:index="index"
                      v-bind:toStep="toStep"
                      v-bind:dataitem="itemssarana"
                      v-bind:itemsjenisbangunan="itemsjenisbangunan"
                      v-bind:itemsstatusbangunan="itemsstatusbangunan"
                      v-bind:itemsstatuslahan="itemsstatuslahan"
                      @data-sarana="saranaUmumValue"
                      @data-step="stepValue"
                      @data-steplanjut="stepLanjutValue"
                      @data-final="dialogFinal"
                    />
                  </div>
                  <div v-if="item === 'Wirausaha'">
                    <WirausahaEdit
                      v-bind:cekado="cekado"
                      v-bind:isibangunan="isibangunan"
                      v-bind:index="index"
                      v-bind:toStep="toStep"
                      v-bind:itemsoperasional="itemsoperasional"
                      v-bind:itemsmodalusaha="itemsmodalusaha"
                      v-bind:itemsyatidak="itemsyatidak"
                      @data-step="stepValue"
                      @data-steplanjut="stepLanjutValue"
                      @data-wirausaha="wirausahaValue"
                      @data-final="dialogFinal"
                    />
                  </div>
                  <div v-if="item === 'Rumah Tinggal Kosong'">
                    <RumahKosEdit
                      v-bind:cekado="cekado"
                      v-bind:isibangunan="isibangunan"
                      v-bind:index="index"
                      v-bind:toStep="toStep"
                      v-bind:itemskondisirumah="itemskondisirumah"
                      v-bind:itemspbb="itemspbb"
                      @data-rkt="rktValue"
                      @data-step="stepValue"
                      @data-steplanjut="stepLanjutValue"
                      @data-final="dialogFinal"
                    />
                  </div>
                  <div v-if="item === 'Rumah Tinggal Isi'">
                    <RumahTinggalEdit
                      v-bind:cekado="cekado"
                      v-bind:isibangunan="isibangunan"
                      v-bind:index="index"
                      v-bind:toStep="toStep"
                      v-bind:itemskondisirumah="itemskondisirumah"
                      v-bind:itemsyatidak="itemsyatidak"
                      v-bind:itemstempat_tinggal="itemstempat_tinggal"
                      v-bind:itemsstatus_lahan="itemsstatus_lahan"
                      v-bind:itemsjenis_lantai="itemsjenis_lantai"
                      v-bind:itemsdinding="itemsdinding"
                      v-bind:itemsjendela="itemsjendela"
                      v-bind:itemsbahan_atap="itemsbahan_atap"
                      v-bind:itemspenerangan_rumah="itemspenerangan_rumah"
                      v-bind:itemsenergi_masak="itemsenergi_masak"
                      v-bind:itemssumber_kayu="itemssumber_kayu"
                      v-bind:itemsbuang_sampah="itemsbuang_sampah"
                      v-bind:itemsmck="itemsmck"
                      v-bind:itemsair_mandi="itemsair_mandi"
                      v-bind:itemsair_besar="itemsair_besar"
                      v-bind:itemsair_minum="itemsair_minum"
                      v-bind:itemsbuang_limbah="itemsbuang_limbah"
                      v-bind:itemstransportasi="itemstransportasi"
                      @data-rti="rtiValue"
                      @data-step="stepValue"
                      @data-steplanjut="stepLanjutValue"
                      @data-final="dialogFinal"
                    />
                  </div>
                </v-form>
              </v-stepper-content>
            </div>
          </v-form>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <br />
    <v-dialog v-model="dialogfinal" persistent max-width="450px">
      <v-form
        @submit.prevent="updateBangungan"
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin update data bangunan ?</h5></v-card-title
          >
          <v-divider></v-divider>

          <v-spacer></v-spacer>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click="dialogfinal = false"
              >Batal</v-btn
            >

            <v-btn
              dialogMeninggal="true"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              class="white--text"
              color="teal"
              >Proses Update Data</v-btn
            >
            <br />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import MapsEdit from "@/components/MapsEdit.vue";
import SaranaUmumEdit from "@/components/SaranaUmumEdit.vue";
import WirausahaEdit from "@/components/WirausahaEdit.vue";
import RumahKosEdit from "@/components/RumahKosEdit.vue";
import RumahTinggalEdit from "@/components/RumahTinggalEdit.vue";

export default {
  components: {
    MapsEdit,
    SaranaUmumEdit,
    WirausahaEdit,
    RumahKosEdit,
    RumahTinggalEdit,
    //tambah
  },
  name: "BangunanEdit",
  props: ["id"],
  data() {
    return {
      // cek edit
      tampungstep: [
        {
          item: "",
          status: "",
        },
      ],
      // Detail Data Master
      isibangunan: [{}],
      markerslat: "",
      markerslong: "",

      //items
      itemsdusun: [{}],
      itemsinet: [{}],

      // Items SARANA UMUM
      itemssarana: [{}],
      itemsjenisbangunan: [{}],
      itemsstatusbangunan: [{}],
      itemsstatuslahan: [{}],

      // Items Wirausaha
      itemsoperasional: [{}],
      itemsmodalusaha: [{}],
      itemsyatidak: [{}],
      // Items Rumah kosong
      itemskondisirumah: [{}],
      itemspbb: [{}],

      // Items Rumah Tinggal Isi
      itemstempat_tinggal: [{}],
      itemsstatus_lahan: [{}],
      itemsjenis_lantai: [{}],
      itemsdinding: [{}],
      itemsjendela: [{}],
      itemsbahan_atap: [{}],
      itemspenerangan_rumah: [{}],
      itemsenergi_masak: [{}],
      itemssumber_kayu: [{}],
      itemsbuang_sampah: [{}],
      itemsmck: [{}],
      itemsair_mandi: [{}],
      itemsair_besar: [{}],
      itemsair_minum: [{}],
      itemsbuang_limbah: [{}],
      itemskendaraan: [{}],
      itemstransportasi: [{}],

      // step 1
      e1: 1,
      toStep: null,
      itemskatbangunan: [{}],

      editBang: {
        no_bangunan: "",
        kategori_bangunan: "",
        jaringan_telepon: "",
        jaringan_gsm: "",
        internet_gsm: "",
        lat: "",
        lng: "",
        kelurahan: "",
        kecamatan: "",
        kota: "",
        id_dusun: "",
      },

      cekado: {
        saranastep: "",
        wirastep: "",
        rtkstep: "",
        rtistep: "",
      },

      // Loading Data
      sloading: true,
      firstLoad: true,
      valid: true,
      loader: null,
      loading: false,
      dialogfinal: false,
      // VALIDATION
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getDetailBang();

    this.detailUsers();
    this.getDusun();
  },
  methods: {
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getDetailBang: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.detailBangunan(credentials).then(
        function (response) {
          this.isibangunan = response.data;
          console.log("Data Detail Bangunan");
          // console.log(this.isibangunan.kategori);

          this.markerslat = this.isibangunan.lat;
          this.markerslong = this.isibangunan.long;

          // Bangunan awal
          this.editBang.no_bangunan = this.isibangunan.no_bangunan;
          this.editBang.kategori_bangunan = this.isibangunan.kategori;
          this.editBang.jaringan_telepon = this.isibangunan.jaringan_telepon;
          this.editBang.jaringan_gsm = this.isibangunan.jaringan_gsm;
          this.editBang.internet_gsm = this.isibangunan.internet_gsm;
          this.editBang.id_dusun = this.isibangunan.id_dusun;

          this.cekado.wirastep =
            this.isibangunan.kategori.includes("Wirausaha");
          this.cekado.saranastep =
            this.isibangunan.kategori.includes("Sarana Umum");
          this.cekado.rtkstep = this.isibangunan.kategori.includes(
            "Rumah Tinggal Kosong"
          );

          this.cekado.rtistep =
            this.isibangunan.kategori.includes("Rumah Tinggal Isi");

          console.log(this.cekado);

          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    getKategoriBangunan: function () {
      AuthService.getKategoriBangunan().then(
        function (response) {
          this.itemskatbangunan = response.data.data;
        }.bind(this)
      );
    },

    dialogFinal(dialogFinal) {
      this.dialogfinal = true;
      console.log("PREVIEW DATA");
    },
    // VALUE SARANA UMUM
    petaValue(petaValue) {
      this.isibangunan.lat = petaValue.latitude;
      this.isibangunan.long = petaValue.longitude;
    },

    nextPetama() {
      this.e1 = 2;
      console.log("ini step pertama");
      this.toStep = this.isibangunan.kategori.length - 1;

      console.log(this.kategori_bangunan);
    },
    saranaUmumValue(saranaValue) {
      this.isisaranaumum = saranaValue;
      console.log("ini di SARANA");
      console.log(saranaValue);
    },
    rktValue(rktValue) {
      this.isirkt = rktValue;
      console.log("ini di RKT");
      console.log(rktValue);
    },
    rtiValue(rtiValue) {
      this.isirti = rtiValue;
      console.log("ini di RTI");
      console.log(rtiValue);
    },
    wirausahaValue(wirausahaValue) {
      this.isiwirausaha = wirausahaValue;
      console.log("ini di Wirausaha Value");
      console.log(wirausahaValue);
    },
    stepValue(stepSebelum) {
      this.e1 = stepSebelum;
      console.log("ini step");
      console.log(stepSebelum);
    },
    stepLanjutValue(stepLanjut) {
      this.e1 = stepLanjut;
      console.log("ini step");
      console.log(stepLanjut);
    },

    // SARANAUMUM
    getJenusSaranaUmum: function () {
      AuthService.getJenusSaranaUmum().then(
        function (response) {
          this.itemssarana = response.data.data;
        }.bind(this)
      );
    },
    getJenisBangunan: function () {
      AuthService.getJenisBangunan().then(
        function (response) {
          this.itemsjenisbangunan = response.data.data;
        }.bind(this)
      );
    },

    getStatusBangunan: function () {
      AuthService.getStatusBangunan().then(
        function (response) {
          this.itemsstatusbangunan = response.data.data;
        }.bind(this)
      );
    },
    getStatusLahan: function () {
      AuthService.getStatusLahan().then(
        function (response) {
          this.itemsstatuslahan = response.data.data;
        }.bind(this)
      );
    },

    // WIRAUSAHA

    getStatusOperational: function () {
      AuthService.getStatusOperational().then(
        function (response) {
          this.itemsoperasional = response.data.data;
        }.bind(this)
      );
    },
    getSumberModal: function () {
      AuthService.getSumberModal().then(
        function (response) {
          this.itemsmodalusaha = response.data.data;
        }.bind(this)
      );
    },
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    getStatusJaringan: function () {
      AuthService.getStatusJaringan().then(
        function (response) {
          this.itemsinet = response.data.data;
        }.bind(this)
      );
    },

    // ITEM RUMAH KOSONG
    getKondisiRumah: function () {
      AuthService.getKondisiRumah().then(
        function (response) {
          this.itemskondisirumah = response.data.data;
        }.bind(this)
      );
    },
    getStatusSPTPBB: function () {
      AuthService.getStatusSPTPBB().then(
        function (response) {
          this.itemspbb = response.data.data;
        }.bind(this)
      );
    },

    // ITEM RUMAH TINGGAL ISI
    getTempatTinggal: function () {
      AuthService.getTempatTinggal().then(
        function (response) {
          this.itemstempat_tinggal = response.data.data;
        }.bind(this)
      );
    },
    getStatusLahanB: function () {
      AuthService.getStatusLahanB().then(
        function (response) {
          this.itemsstatus_lahan = response.data.data;
        }.bind(this)
      );
    },
    getJenisLantai: function () {
      AuthService.getJenisLantai().then(
        function (response) {
          this.itemsjenis_lantai = response.data.data;
        }.bind(this)
      );
    },
    getJenisDinding: function () {
      AuthService.getJenisDinding().then(
        function (response) {
          this.itemsdinding = response.data.data;
        }.bind(this)
      );
    },
    getStatusJendela: function () {
      AuthService.getStatusJendela().then(
        function (response) {
          this.itemsjendela = response.data.data;
        }.bind(this)
      );
    },
    getBahanAtap: function () {
      AuthService.getBahanAtap().then(
        function (response) {
          this.itemsbahan_atap = response.data.data;
        }.bind(this)
      );
    },
    getPeneranganRumah: function () {
      AuthService.getPeneranganRumah().then(
        function (response) {
          this.itemspenerangan_rumah = response.data.data;
        }.bind(this)
      );
    },

    getEnergiMasak: function () {
      AuthService.getEnergiMasak().then(
        function (response) {
          this.itemsenergi_masak = response.data.data;
        }.bind(this)
      );
    },
    getKayuBakar: function () {
      AuthService.getKayuBakar().then(
        function (response) {
          this.itemssumber_kayu = response.data.data;
        }.bind(this)
      );
    },
    getPembuanganSampah: function () {
      AuthService.getPembuanganSampah().then(
        function (response) {
          this.itemsbuang_sampah = response.data.data;
        }.bind(this)
      );
    },
    getFasilitasMCK: function () {
      AuthService.getFasilitasMCK().then(
        function (response) {
          this.itemsmck = response.data.data;
        }.bind(this)
      );
    },
    getSumberAirMandi: function () {
      AuthService.getSumberAirMandi().then(
        function (response) {
          this.itemsair_mandi = response.data.data;
        }.bind(this)
      );
    },
    getFasilitasBAB: function () {
      AuthService.getFasilitasBAB().then(
        function (response) {
          this.itemsair_besar = response.data.data;
        }.bind(this)
      );
    },
    getSumberAirMinum: function () {
      AuthService.getSumberAirMinum().then(
        function (response) {
          this.itemsair_minum = response.data.data;
        }.bind(this)
      );
    },
    getLimbahCair: function () {
      AuthService.getLimbahCair().then(
        function (response) {
          this.itemsbuang_limbah = response.data.data;
        }.bind(this)
      );
    },
    getTransportasi: function () {
      AuthService.getTransportasi().then(
        function (response) {
          this.itemstransportasi = response.data.data;
        }.bind(this)
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    async updateBangungan() {
      // console.log(this.isibangunan.kategori);
      try {
        this.loading = true;
        const credentials = {
          id: this.isibangunan.id,
          no_bangunan: this.editBang.no_bangunan,
          jaringan_telepon: this.editBang.jaringan_telepon,
          jaringan_gsm: this.editBang.jaringan_gsm,
          internet_gsm: this.editBang.internet_gsm,
          kategori: this.isibangunan.kategori,
          link_bangunan: this.link_bangunan,
          lat: this.isibangunan.lat,
          long: this.isibangunan.long,
          id_dusun: this.editBang.id_dusun,
          saranavalue: [this.isisaranaumum],
          rtkvalue: [this.isirkt],
          wirausahavalue: [this.isiwirausaha],
          rtivalue: [this.isirti],
        };

        const response = await AuthService.editBangunan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil update banguanan");
          this.dialogfinal = false;
          this.$router.push("/admin-kelurahan/bangunan/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.dialogfinal = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.getKategoriBangunan();
    this.detailUsers();
    this.getDusun();

    // sarana umum
    this.getJenusSaranaUmum();
    this.getJenisBangunan();
    this.getStatusBangunan();
    this.getStatusLahan();

    // wirausaha
    this.getStatusOperational();
    this.getSumberModal();
    this.getYatidak();
    this.getStatusJaringan();

    // rumah kosong
    this.getKondisiRumah();
    this.getStatusSPTPBB();

    // rumah tinggal isi
    this.getTempatTinggal();
    this.getStatusLahanB();
    this.getJenisLantai();
    this.getJenisDinding();
    this.getStatusJendela();
    this.getBahanAtap();
    this.getPeneranganRumah();

    this.getEnergiMasak();
    this.getKayuBakar();
    this.getPembuanganSampah();
    this.getFasilitasMCK();
    this.getSumberAirMandi();
    this.getFasilitasBAB();
    this.getSumberAirMinum();
    this.getLimbahCair();
    this.getTransportasi();
  },
};
</script>



<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>





