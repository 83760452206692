<template>
  <v-card-text class="pt-5">
    <v-row dense>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Nomor Bangunan"
          :value="detailsaranacom.no_bangunan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Dusun"
          :value="detailsaranacom.dusun"
          readonly
          outlined
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Kelurahan/Desa"
          :value="detailsaranacom.kelurahan"
          readonly
          outlined
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Kecamatan"
          :value="detailsaranacom.kecamatan"
          readonly
          outlined
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jenis Sarana Umum"
          :value="detailsaranacom.jenis_saranaumum"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Nama Sarana Umum"
          :value="detailsaranacom.nama"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Jenis Bangunan"
          :value="detailsaranacom.jenis_bangunan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Status Bangunan"
          :value="detailsaranacom.status_bangunan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Status Lahan"
          :value="detailsaranacom.status_lahan"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Dibangun Tahun"
          :value="detailsaranacom.tahun_bangun"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Penganggu Jawab"
          :value="detailsaranacom.penanggung_jawab"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          color="teal"
          label="Nomor Telpon"
          :value="detailsaranacom.no_tlp"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="12">
        <v-text-field
          color="teal"
          label="Link Foto 360 derajat"
          :value="detailsaranacom.link_foto"
          readonly
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <br />
    <v-btn
      color="primary"
      target="_blank"
      :href="`https://maps.google.com/?q=${detailsaranacom.link_bangunan}`"
    >
      <v-icon dense center> mdi-map </v-icon> &nbsp;Lokasi Sarana Umum&nbsp;
      <v-icon dense center> mdi-arrow-right </v-icon></v-btn
    >
  </v-card-text>
</template>



<script>
export default {
  props: {
    detailsaranacom: Object,
    linkmaps: String,
  },

  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>