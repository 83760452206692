<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0 white--text"
      elevation="0"
      color="gradient"
    >
      <strong>Rumah Data - SAGASAJA</strong>
      <v-btn to="/rumah-data" depressed small class="white--text" color="blue">
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-expansion-panels hover accordion>
        <v-expansion-panel
          v-for="(itemdata, index1) in hasildatas.data.sagasaja"
          :key="index1"
        >
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between mb-0 black--text">
              <strong class="mt-2 text-uppercase">{{ itemdata.nama }}</strong>
              <v-chip class="white--text" color="orange">
                <strong>{{ itemdata.total }}</strong>
              </v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            v-for="(kelurahan, index) in hasildatas.kelurahan"
            :key="index"
          >
            <div class="d-flex justify-space-between mb-0">
              <strong class="mt-2 pl-4">{{ kelurahan.total }}</strong>
              <v-chip class="ma-1 white--text" color="teal">
                <strong>{{ kelurahan.data.sagasaja[index1].total }}</strong>
              </v-chip>
            </div>
            <v-divider></v-divider>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
export default {
  name: "PublikLahan",
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,
      panel: [0],
      // Data
      hasildatas: [{}],
    };
  },

  watch: {},

  created() {
    this.getDataSagasaja();
  },
  methods: {
    // Data
    getDataSagasaja: function () {
      ServicePublik.getDataSagasaja().then(
        function (response) {
          this.hasildatas = response.data.data[0];
          this.hasildatasnol = response.data.data[0];
          this.firstLoad = false;
          this.sloading = false;
          //   console.log(this.hasildatas);
        }.bind(this)
      );
    },
  },

  async mounted() {
    // this.detailUsers();
    // this.getDusun();
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
.gradient {
  background: linear-gradient(
    90deg,
    hsla(136, 48%, 54%, 1) 0%,
    hsla(58, 99%, 48%, 1) 100%
  );
}
</style>
