<template>
  <v-card elevation="0">
    <v-sheet
      outlined
      v-for="row in textRows"
      :key="row.index"
      color="grey"
      class="ma-0"
    >
      <v-card elevation="">
        <v-card-text>
          <v-row dense>
            <v-col md="4">
              <v-alert
                v-if="row.ceknikst === true"
                border="left"
                colored-border
                type="success"
                elevation="0"
              >
                No Kartu Keluarga Ditemukan
              </v-alert>
              <v-alert
                v-else-if="row.ceknikst === false"
                border="left"
                colored-border
                type="warning"
                elevation="0"
              >
                No Kartu Keluarga tidak ada pada database
              </v-alert>
              <v-alert
                v-else
                border="left"
                colored-border
                type="info"
                elevation="0"
              >
                Masukan Nomor Kartu Keluarga yang Tinggal pada Bangunan ini.
                kemudian tekan -> cek kk
              </v-alert>
            </v-col>

            <v-col md="4">
              <v-text-field
                v-if="row.ceknikst !== true"
                :rules="nokkRules"
                counter="16"
                maxlength="16"
                color="teal"
                v-model="row.nokk"
                label="Nomor Kartu Keluarga"
                filled
                dense
                required
              ></v-text-field>
              <v-text-field
                v-else-if="row.ceknikst === true"
                :rules="nokkRules"
                counter="16"
                maxlength="16"
                color="teal"
                v-model="row.nokk"
                label="Nomor Kartu Keluarga"
                filled
                dense
                required
              ></v-text-field>
              <v-text-field
                v-else
                :rules="nokkRules"
                color="teal"
                v-model="row.nokk"
                label="Nomor Kartu Keluarga"
                filled
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col md="2">
              <div class="justify-sm-end">
                <v-btn small dark @click="cekKAKA(row)" outlined color="teal">
                  CEK KK
                </v-btn>
                &nbsp;
                <v-btn
                  small
                  class="white--text"
                  @click="deleteTextRow(row)"
                  color="pink"
                >
                  <v-icon small color="white">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-sheet>
    <br />

    <!-- <div v-for="row in textRows" :key="row.index">
      <h3>{{ row }}</h3>
    </div> -->

    <div class="pa-0 mt-2 mb-3">
      <v-btn small class="white--text" @click="addTextRow()" color="indigo">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <br />

    <v-row dense>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="rumahtinggalvalue.nama_pemilik"
          label="Nama Pemilik Bangunan"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="rumahtinggalvalue.no_telp"
          label="Nomor Telepon"
          filled
          dense
          required
        ></v-text-field>
      </v-col>

      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.tempat_tinggal"
          :items="itemstempat_tinggal"
          item-text="status_tempat"
          item-value="status_tempat"
          color="teal"
          label="Tempat Tinggal yang Ditempati"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.status_lahan"
          :items="itemsstatus_lahan"
          item-text="status_lahan"
          item-value="status_lahan"
          color="teal"
          label="Status Lahan Tempat Tinggal yang Ditempati"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.luas_lahan"
          label="Luas Lahan Bangunan"
          filled
          dense
          hint="hanya berisi angka dengan satuan m2"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.luas_lantai"
          label="Luas Lantai Bangunan"
          filled
          dense
          hint="hanya berisi angka dengan satuan m2"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.jenis_lantai"
          :items="itemsjenis_lantai"
          item-text="jenis_lantai"
          item-value="jenis_lantai"
          color="teal"
          label="Jenis Lantai Tempat Tinggal yang Ditempati"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.dinding"
          :items="itemsdinding"
          item-text="jenis_dinding"
          item-value="jenis_dinding"
          color="teal"
          label="Dinding Sebagian Besar Rumah"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.jendela"
          :items="itemsjendela"
          item-text="status_jendela"
          item-value="status_jendela"
          color="teal"
          label="Status Jendela"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.bahan_atap"
          :items="itemsbahan_atap"
          item-text="bahan_atap"
          item-value="bahan_atap"
          color="teal"
          label="Bahan Atap"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.penerangan_rumah"
          :items="itemspenerangan_rumah"
          item-text="penerangan_rumah"
          item-value="penerangan_rumah"
          color="teal"
          label="Penerangan Rumah"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.energi_masak"
          :items="itemsenergi_masak"
          item-text="energi_masask"
          item-value="energi_masask"
          color="teal"
          label="Energi Untuk Masak"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <template v-if="rumahtinggalvalue.energi_masak === 'Kayu bakar'">
        <v-col cols="4">
          <v-select
            :rules="wajibRules"
            v-model="rumahtinggalvalue.sumber_kayu"
            :items="itemssumber_kayu"
            item-text="kayu_bakar"
            item-value="kayu_bakar"
            color="teal"
            label="Pilih Sumber Kayu Bakar"
            filled
            dense
          ></v-select
        ></v-col>
      </template>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.buang_sampah"
          :items="itemsbuang_sampah"
          item-text="pembuangan_sampah"
          item-value="pembuangan_sampah"
          color="teal"
          label="Tempat Pembuangan Sampah"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.mck"
          :items="itemsmck"
          item-text="fasilitas_mck"
          item-value="fasilitas_mck"
          color="teal"
          label="Fasilitas MCK"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.air_mandi"
          :items="itemsair_mandi"
          item-text="sumber_air_mandi"
          item-value="sumber_air_mandi"
          color="teal"
          label="Sumber Air Mandi Terbanyak Dari"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.air_besar"
          :items="itemsair_besar"
          item-text="fasilitas_bab"
          item-value="fasilitas_bab"
          color="teal"
          label="Fasilitas Buang Air Besar"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.air_minum"
          :items="itemsair_minum"
          item-text="sumber_air_mandi"
          item-value="sumber_air_mandi"
          color="teal"
          label="Sumber Air Minum Terbanyak Dari"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.buang_limbah"
          :items="itemsbuang_limbah"
          item-text="pembuangan_limbah"
          item-value="pembuangan_limbah"
          color="teal"
          label="Tempat Pembuangan Limbah Cair"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.sutet"
          :items="itemsyatidak"
          item-text="optional"
          item-value="optional"
          color="teal"
          label="Bangunan Rumah berada di bawah SUTET/SUTT/SUTTAS"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.sungai"
          :items="itemsyatidak"
          item-text="optional"
          item-value="optional"
          color="teal"
          label="Bangunan Rumah di bantaran sungai"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.bukit"
          :items="itemsyatidak"
          item-text="optional"
          item-value="optional"
          color="teal"
          label="Rumah di lereng bukit/gunung"
          filled
          dense
          required
        ></v-select>
      </v-col>

      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.kendaraan"
          :items="itemskendaraan"
          item-text="kendaraan"
          item-value="kendaraan"
          color="teal"
          label="Memiliki Kendaraan Bermotor"
          filled
          dense
          multiple
          @change="cekKendaraan"
          required
        ></v-select>
      </v-col>

      <v-col v-for="(item, index) in rumahtinggalvalue.kendaraan" :key="index">
        <v-text-field
          v-show="item === 'Mobil'"
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.jumlah_mobil"
          label="Jumlah Mobil"
          filled
          dense
          required
        ></v-text-field>
        <v-text-field
          v-show="item === 'Motor'"
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.jumlah_motor"
          label="Jumlah Motor"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.kondisi_rumah"
          :items="itemskondisirumah"
          item-text="kodisi_rumah"
          item-value="kodisi_rumah"
          color="teal"
          label="Secara Keseluruhan Kondisi Rumah"
          filled
          dense
          required
        ></v-select>
      </v-col>
    </v-row>

    <hr class="grey--text" />
    <br />
    <v-row dense>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.trans_pu"
          :items="itemstransportasi"
          item-text="tansportasi"
          item-value="tansportasi"
          color="teal"
          label="Transportasi ke Pekerjaan Umum"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.trans_lahan"
          :items="itemstransportasi"
          item-text="tansportasi"
          item-value="tansportasi"
          color="teal"
          label="Transportasi ke Lahan Pertanian"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.trans_sekolah"
          :items="itemstransportasi"
          item-text="tansportasi"
          item-value="tansportasi"
          color="teal"
          label="Transportasi ke Sekolah"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.trans_berobat"
          :items="itemstransportasi"
          item-text="tansportasi"
          item-value="tansportasi"
          color="teal"
          label="Transportasi ke Berobat"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.trans_ibadah"
          :items="itemstransportasi"
          item-text="tansportasi"
          item-value="tansportasi"
          color="teal"
          label="Transportasi ke Beribadah"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select
          :rules="wajibRules"
          v-model="rumahtinggalvalue.trans_rekreasi"
          :items="itemstransportasi"
          item-text="tansportasi"
          item-value="tansportasi"
          color="teal"
          label="Transportasi ke Rekreasi Terdekat"
          filled
          dense
          required
        ></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-text-field
          :rules="wajibRules"
          color="teal"
          v-model="rumahtinggalvalue.link_foto"
          label="Link Foto 360 derajat"
          filled
          dense
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <hr class="grey--text" />
    <br />
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a1"
          label="Jarak ke Masjid"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a2"
          label="Jarak ke Musaolla / Surau"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a3"
          label="Jarak ke Kantor Desa"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a4"
          label="Jarak ke Kantor Kecamatan"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a5"
          label="Jarak ke Kantor Kota"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a6"
          label="Jarak ke TK"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a7"
          label="Jarak ke PAUD"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a8"
          label="Jarak ke SD"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a9"
          label="Jarak ke SMP"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a10"
          label="Jarak ke SMA"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a11"
          label="Jarak ke Perguruan Tinggi"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a12"
          label="Jarak ke Sekolah Agama SD Sederajat"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a13"
          label="Jarak ke Sekolah Agama SMP Sederajat"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a14"
          label="Jarak ke Sekolah Agama SMA Sederajat"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a15"
          label="Jarak ke Pesantren"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a16"
          label="Jarak ke Pasar"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a17"
          label="Jarak ke Rumah Sakit"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a18"
          label="Jarak ke Poliklinik"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a19"
          label="Jarak ke Rumah Sakit Bersalin"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a20"
          label="Jarak ke Puskesma"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a21"
          label="Jarak ke Puskesma Pembantu / Pustu"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a22"
          label="Jarak ke Polindes"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a23"
          label="Jarak ke Poskesdes"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a24"
          label="Jarak ke Posyandu"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a25"
          label="Jarak ke Apotik"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a26"
          label="Jarak ke Toko Obat"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a27"
          label="Jarak ke Dokter Spesialis"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a28"
          label="Jarak ke Dokter Umum"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a29"
          label="Jarak ke Bidan"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a30"
          label="Jarak ke Tenaga Kesehatan"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :rules="nomorRules"
          color="teal"
          v-model="rumahtinggalvalue.akses_jalan.a31"
          label="Jarak ke Dukun"
          filled
          dense
          required
          hint="hanya berisi angka dengan satuan KM"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <br />
    <v-card-actions>
      <v-btn class="white--text" @click="stepSebelum" color="teal" outlined
        ><v-icon color="black" left>mdi-arrow-left</v-icon>Sebelumnya
      </v-btn>

      <v-spacer></v-spacer>
      <template v-if="toStep !== index">
        <v-btn
          v-if="
            rumahtinggalvalue.nama_pemilik !== '' &&
            rumahtinggalvalue.link_foto !== ''
          "
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="stepLanjut"
          color="teal"
          >Selanjutnya<v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          v-if="
            rumahtinggalvalue.nama_pemilik !== '' &&
            hitungrow !== 0 &&
            rumahtinggalvalue.link_foto !== ''
          "
          class="white--text"
          @click="simpanData"
          color="teal"
          >Update Data
        </v-btn>
        <v-btn
          v-else
          disabled
          class="white--text"
          @click="simpanData"
          color="teal"
          >Update Data
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>



<script>
import AuthService from "@/services/AuthService.js";
export default {
  props: {
    index: Number,
    toStep: Number,
    itemskondisirumah: Array,
    itemsyatidak: Array,

    itemstempat_tinggal: Array,
    itemsstatus_lahan: Array,
    itemsjenis_lantai: Array,
    itemsdinding: Array,
    itemsjendela: Array,
    itemsbahan_atap: Array,

    itemspenerangan_rumah: Array,
    itemsenergi_masak: Array,
    itemssumber_kayu: Array,
    itemsbuang_sampah: Array,

    itemsmck: Array,
    itemsair_mandi: Array,
    itemsair_besar: Array,
    itemsair_minum: Array,

    itemsbuang_limbah: Array,
    itemstransportasi: Array,
    isibangunan: Object,
    cekado: Object,
  },
  data() {
    return {
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      hitungrow: "",

      itemskendaraan: [
        {
          kendaraan: "Mobil",
        },
        {
          kendaraan: "Motor",
        },
        {
          kendaraan: "Tidak Ada",
        },
      ],

      removeTextRow: false,
      textRows: [
        {
          id_nokk: "",
          nokk: "",
          ceknikst: "null",
        },
      ],

      rumahtinggalvalue: {
        id: "",
        nama_pemilik: "",
        no_telp: "",
        id_nokk: "",
        nokk: "",
        tempat_tinggal: "",
        status_lahan: "",
        luas_lahan: "",
        luas_lantai: "",
        jenis_lantai: "",
        dinding: "",
        jendela: "",
        bahan_atap: "",
        penerangan_rumah: "",
        energi_masak: "",
        sumber_kayu: "",
        buang_sampah: "",
        mck: "",
        air_mandi: "",
        air_besar: "",
        air_minum: "",
        buang_limbah: "",
        sutet: "",
        sungai: "",
        bukit: "",
        kendaraan: "",
        jumlah_motor: "",
        jumlah_mobil: "",
        kondisi_rumah: "",
        trans_pu: "",
        trans_lahan: "",
        trans_sekolah: "",
        trans_berobat: "",
        trans_ibadah: "",
        trans_rekreasi: "",
        link_foto: "",
        // akses rumah
        akses_jalan: {
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: "",
          a6: "",
          a7: "",
          a8: "",
          a9: "",
          a10: "",
          a11: "",
          a12: "",
          a13: "",
          a14: "",
          a15: "",
          a16: "",
          a17: "",
          a18: "",
          a19: "",
          a20: "",
          a21: "",
          a22: "",
          a23: "",
          a24: "",
          a25: "",
          a26: "",
          a27: "",
          a28: "",
          a29: "",
          a30: "",
          a31: "",
        },

        kolamkk: {},
      },
    };
  },

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1945 },
        (value, index) => 1946 + index
      );
    },
  },
  mounted() {
    this.textRows.forEach((isikk) => {
      this.textRows = this.isibangunan.rtivalue[0].tampung;
    });
    this.rumahtinggalvalue.nokk = this.textRows;
    this.hitungrow = this.textRows.length;
    console.log(this.hitungrow);

    // CEK EDIT ATAU TAMBAH
    console.log(this.cekado.rtistep);
    if (this.cekado.rtistep === false) {
      this.rumahtinggalvalue.id = "";
      this.rumahtinggalvalue.nama_pemilik = "";
      this.rumahtinggalvalue.no_telp = "";
      this.rumahtinggalvalue.id_nokk = "";
      this.rumahtinggalvalue.nokk = "";
      this.rumahtinggalvalue.tempat_tinggal = "";
      this.rumahtinggalvalue.status_lahan = "";
      this.rumahtinggalvalue.luas_lahan = "";
      this.rumahtinggalvalue.luas_lantai = "";
      this.rumahtinggalvalue.jenis_lantai = "";
      this.rumahtinggalvalue.dinding = "";
      this.rumahtinggalvalue.jendela = "";
      this.rumahtinggalvalue.bahan_atap = "";
      this.rumahtinggalvalue.penerangan_rumah = "";
      this.rumahtinggalvalue.energi_masak = "";
      this.rumahtinggalvalue.sumber_kayu = "";
      this.rumahtinggalvalue.buang_sampah = "";
      this.rumahtinggalvalue.mck = "";
      this.rumahtinggalvalue.air_mandi = "";
      this.rumahtinggalvalue.air_besar = "";
      this.rumahtinggalvalue.air_minum = "";
      this.rumahtinggalvalue.buang_limbah = "";
      this.rumahtinggalvalue.sutet = "";
      this.rumahtinggalvalue.sungai = "";
      this.rumahtinggalvalue.bukit = "";
      this.rumahtinggalvalue.kendaraan = "";
      this.rumahtinggalvalue.jumlah_motor = "";
      this.rumahtinggalvalue.jumlah_mobil = "";
      this.rumahtinggalvalue.kondisi_rumah = "";
      this.rumahtinggalvalue.trans_pu = "";
      this.rumahtinggalvalue.trans_lahan = "";
      this.rumahtinggalvalue.trans_sekolah = "";
      this.rumahtinggalvalue.trans_berobat = "";
      this.rumahtinggalvalue.trans_ibadah = "";
      this.rumahtinggalvalue.trans_rekreasi = "";
      this.rumahtinggalvalue.link_foto = "";

      // AKSES JALAN
      this.rumahtinggalvalue.akses_jalan.a1 = "";
      this.rumahtinggalvalue.akses_jalan.a2 = "";
      this.rumahtinggalvalue.akses_jalan.a3 = "";
      this.rumahtinggalvalue.akses_jalan.a4 = "";
      this.rumahtinggalvalue.akses_jalan.a5 = "";
      this.rumahtinggalvalue.akses_jalan.a6 = "";
      this.rumahtinggalvalue.akses_jalan.a7 = "";
      this.rumahtinggalvalue.akses_jalan.a8 = "";
      this.rumahtinggalvalue.akses_jalan.a9 = "";
      this.rumahtinggalvalue.akses_jalan.a10 = "";
      this.rumahtinggalvalue.akses_jalan.a11 = "";
      this.rumahtinggalvalue.akses_jalan.a12 = "";
      this.rumahtinggalvalue.akses_jalan.a13 = "";
      this.rumahtinggalvalue.akses_jalan.a14 = "";
      this.rumahtinggalvalue.akses_jalan.a15 = "";
      this.rumahtinggalvalue.akses_jalan.a16 = "";
      this.rumahtinggalvalue.akses_jalan.a17 = "";
      this.rumahtinggalvalue.akses_jalan.a18 = "";
      this.rumahtinggalvalue.akses_jalan.a19 = "";
      this.rumahtinggalvalue.akses_jalan.a20 = "";
      this.rumahtinggalvalue.akses_jalan.a21 = "";
      this.rumahtinggalvalue.akses_jalan.a22 = "";
      this.rumahtinggalvalue.akses_jalan.a23 = "";
      this.rumahtinggalvalue.akses_jalan.a24 = "";
      this.rumahtinggalvalue.akses_jalan.a25 = "";
      this.rumahtinggalvalue.akses_jalan.a26 = "";
      this.rumahtinggalvalue.akses_jalan.a27 = "";
      this.rumahtinggalvalue.akses_jalan.a28 = "";
      this.rumahtinggalvalue.akses_jalan.a29 = "";
      this.rumahtinggalvalue.akses_jalan.a30 = "";
      this.rumahtinggalvalue.akses_jalan.a31 = "";
    } else {
      this.rumahtinggalvalue.id = this.isibangunan.rtivalue[0].id;
      this.rumahtinggalvalue.nama_pemilik =
        this.isibangunan.rtivalue[0].nama_pemilik;
      this.rumahtinggalvalue.no_telp = this.isibangunan.rtivalue[0].no_telp;
      this.rumahtinggalvalue.id_nokk = this.isibangunan.rtivalue[0].id_nokk;
      this.rumahtinggalvalue.nokk = this.textRows;
      this.rumahtinggalvalue.tempat_tinggal =
        this.isibangunan.rtivalue[0].tempat_tinggal;
      this.rumahtinggalvalue.status_lahan =
        this.isibangunan.rtivalue[0].status_lahan;
      this.rumahtinggalvalue.luas_lahan =
        this.isibangunan.rtivalue[0].luas_lahan;
      this.rumahtinggalvalue.luas_lantai =
        this.isibangunan.rtivalue[0].luas_lantai;
      this.rumahtinggalvalue.jenis_lantai =
        this.isibangunan.rtivalue[0].jenis_lantai;
      this.rumahtinggalvalue.dinding = this.isibangunan.rtivalue[0].dinding;
      this.rumahtinggalvalue.jendela = this.isibangunan.rtivalue[0].jendela;
      this.rumahtinggalvalue.bahan_atap = this.isibangunan.rtivalue[0].atap;
      this.rumahtinggalvalue.penerangan_rumah =
        this.isibangunan.rtivalue[0].penerangan;
      this.rumahtinggalvalue.energi_masak =
        this.isibangunan.rtivalue[0].energi_masak;
      this.rumahtinggalvalue.sumber_kayu =
        this.isibangunan.rtivalue[0].kayu_bakar;
      this.rumahtinggalvalue.buang_sampah =
        this.isibangunan.rtivalue[0].tempat_pembuangan_sampah;
      this.rumahtinggalvalue.mck = this.isibangunan.rtivalue[0].fasilitas_mck;
      this.rumahtinggalvalue.air_mandi =
        this.isibangunan.rtivalue[0].sumber_air_mandi;
      this.rumahtinggalvalue.air_besar =
        this.isibangunan.rtivalue[0].fasilitas_bab;
      this.rumahtinggalvalue.air_minum =
        this.isibangunan.rtivalue[0].sumber_air_minum;
      this.rumahtinggalvalue.buang_limbah =
        this.isibangunan.rtivalue[0].tempat_limbah;
      this.rumahtinggalvalue.sutet = this.isibangunan.rtivalue[0].rumah_sutet;
      this.rumahtinggalvalue.sungai =
        this.isibangunan.rtivalue[0].rumah_bantaran_sungai;
      this.rumahtinggalvalue.bukit =
        this.isibangunan.rtivalue[0].rumah_lereng_bukit;
      this.rumahtinggalvalue.kendaraan = this.isibangunan.rtivalue[0].kendaraan;
      this.rumahtinggalvalue.jumlah_motor =
        this.isibangunan.rtivalue[0].jumlah_motor;
      this.rumahtinggalvalue.jumlah_mobil =
        this.isibangunan.rtivalue[0].jumlah_mobil;
      this.rumahtinggalvalue.kondisi_rumah =
        this.isibangunan.rtivalue[0].kondisi_rumah;
      this.rumahtinggalvalue.trans_pu =
        this.isibangunan.rtivalue[0].transportasi_umum;
      this.rumahtinggalvalue.trans_lahan =
        this.isibangunan.rtivalue[0].transportasi_lahan_pertanian;
      this.rumahtinggalvalue.trans_sekolah =
        this.isibangunan.rtivalue[0].transportasi_sekolah;
      this.rumahtinggalvalue.trans_berobat =
        this.isibangunan.rtivalue[0].transportasi_berobat;
      this.rumahtinggalvalue.trans_ibadah =
        this.isibangunan.rtivalue[0].transportasi_beribadah;
      this.rumahtinggalvalue.trans_rekreasi =
        this.isibangunan.rtivalue[0].transportasi_rekreasi;
      this.rumahtinggalvalue.link_foto = this.isibangunan.rtivalue[0].link_foto;

      // AKSES JALAN

      this.rumahtinggalvalue.akses_jalan.a1 =
        this.isibangunan.rtivalue[0].akses_jalan.a1;
      this.rumahtinggalvalue.akses_jalan.a2 =
        this.isibangunan.rtivalue[0].akses_jalan.a2;
      this.rumahtinggalvalue.akses_jalan.a3 =
        this.isibangunan.rtivalue[0].akses_jalan.a3;
      this.rumahtinggalvalue.akses_jalan.a4 =
        this.isibangunan.rtivalue[0].akses_jalan.a4;
      this.rumahtinggalvalue.akses_jalan.a5 =
        this.isibangunan.rtivalue[0].akses_jalan.a5;
      this.rumahtinggalvalue.akses_jalan.a6 =
        this.isibangunan.rtivalue[0].akses_jalan.a6;
      this.rumahtinggalvalue.akses_jalan.a7 =
        this.isibangunan.rtivalue[0].akses_jalan.a7;
      this.rumahtinggalvalue.akses_jalan.a8 =
        this.isibangunan.rtivalue[0].akses_jalan.a8;
      this.rumahtinggalvalue.akses_jalan.a9 =
        this.isibangunan.rtivalue[0].akses_jalan.a9;
      this.rumahtinggalvalue.akses_jalan.a10 =
        this.isibangunan.rtivalue[0].akses_jalan.a10;
      this.rumahtinggalvalue.akses_jalan.a11 =
        this.isibangunan.rtivalue[0].akses_jalan.a11;
      this.rumahtinggalvalue.akses_jalan.a12 =
        this.isibangunan.rtivalue[0].akses_jalan.a12;
      this.rumahtinggalvalue.akses_jalan.a13 =
        this.isibangunan.rtivalue[0].akses_jalan.a13;
      this.rumahtinggalvalue.akses_jalan.a14 =
        this.isibangunan.rtivalue[0].akses_jalan.a14;
      this.rumahtinggalvalue.akses_jalan.a15 =
        this.isibangunan.rtivalue[0].akses_jalan.a15;
      this.rumahtinggalvalue.akses_jalan.a16 =
        this.isibangunan.rtivalue[0].akses_jalan.a16;
      this.rumahtinggalvalue.akses_jalan.a17 =
        this.isibangunan.rtivalue[0].akses_jalan.a17;
      this.rumahtinggalvalue.akses_jalan.a18 =
        this.isibangunan.rtivalue[0].akses_jalan.a18;
      this.rumahtinggalvalue.akses_jalan.a19 =
        this.isibangunan.rtivalue[0].akses_jalan.a19;
      this.rumahtinggalvalue.akses_jalan.a20 =
        this.isibangunan.rtivalue[0].akses_jalan.a20;
      this.rumahtinggalvalue.akses_jalan.a21 =
        this.isibangunan.rtivalue[0].akses_jalan.a21;
      this.rumahtinggalvalue.akses_jalan.a22 =
        this.isibangunan.rtivalue[0].akses_jalan.a22;
      this.rumahtinggalvalue.akses_jalan.a23 =
        this.isibangunan.rtivalue[0].akses_jalan.a23;
      this.rumahtinggalvalue.akses_jalan.a24 =
        this.isibangunan.rtivalue[0].akses_jalan.a24;
      this.rumahtinggalvalue.akses_jalan.a25 =
        this.isibangunan.rtivalue[0].akses_jalan.a25;
      this.rumahtinggalvalue.akses_jalan.a26 =
        this.isibangunan.rtivalue[0].akses_jalan.a26;
      this.rumahtinggalvalue.akses_jalan.a27 =
        this.isibangunan.rtivalue[0].akses_jalan.a27;
      this.rumahtinggalvalue.akses_jalan.a28 =
        this.isibangunan.rtivalue[0].akses_jalan.a28;
      this.rumahtinggalvalue.akses_jalan.a29 =
        this.isibangunan.rtivalue[0].akses_jalan.a29;
      this.rumahtinggalvalue.akses_jalan.a30 =
        this.isibangunan.rtivalue[0].akses_jalan.a30;
      this.rumahtinggalvalue.akses_jalan.a31 =
        this.isibangunan.rtivalue[0].akses_jalan.a31;
    }
  },
  methods: {
    cekKAKA: function (row) {
      const credentials = {
        nokk: row.nokk,
      };
      AuthService.cekKK(credentials).then(
        function (response) {
          this.textRows.forEach((cekisi) => {
            cekisi.hasilcek = response.message;
            row.ceknikst = response.success;
            if (row.ceknikst === true) {
              row.id_nokk = response.message.id;
              row.nokk = response.message.nokk;
            }
            // console.log(cekisi.hasilcek.nokk);
          });

          // console.log(row);
          // console.log(row.ceknikst);
          this.rumahtinggalvalue.nokk = this.textRows;
        }.bind(this)
      );
    },
    cekKendaraan(event) {
      console.log(event);
    },
    stepSebelum: function () {
      var hasilstep = this.index + 1;
      this.$emit("data-step", hasilstep);
    },
    stepLanjut: function () {
      var hasilsteplanjut = this.index + 3;
      this.$emit("data-steplanjut", hasilsteplanjut);

      this.$emit("data-rti", this.rumahtinggalvalue);
    },
    simpanData: function () {
      this.$emit("data-rti", this.rumahtinggalvalue);
      // this.$emit("data-rtinokk", this.textRows);
      this.$emit("data-final", this.dialogfinal);
    },

    // ROW DATA
    addTextRow() {
      this.textRows.push({ ceknikst: null });
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
      }
      this.rumahtinggalvalue.nokk = this.textRows;
      console.log(this.textRows);
    },
    deleteTextRow(row) {
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
        this.textRows.splice(this.textRows.indexOf(row), 1);
        console.log(row);
      }
      if (this.textRows.length <= 1) {
        this.removeTextRow = false;
      }
    },
  },
};
</script>