<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4"> <router-view></router-view> </v-main>
  </v-app>
</template>
<script>
export default {
  name: "PublicLayout",
  data: () => ({
    links: [
      {
        title: "Login",
        to: "/",
      },
    ],
  }),
};
</script>

