<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Kabupaten/Kota</h4></v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-container class="mt-0">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        color="teal"
                        :rules="provRules"
                        :items="countries"
                        v-model="country"
                        item-text="provinsi"
                        item-value="id"
                        label="Pilih Provinsi *"
                        outlined
                        required
                      >
                      </v-select>

                      <v-text-field
                        label="Masukan Nama Kabupaten/Kota *"
                        outlined
                        v-model="editedItem.kota"
                        :rules="namaKotaRules"
                        color="teal"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <small>(*) tidak boleh kosong</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close()" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="6" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin untuk mengahpus data ?</h5>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <h4 class="teal--text">{{ editedItem.kota }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table :headers="headers" :items="hasildatas" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-row justify="center">
            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon left> mdi-pencil </v-icon>
              Edit
            </v-btn>
            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon left> mdi-delete </v-icon>
              Hapus
            </v-btn>
          </v-row>
        </template>
        <template v-slot:no-data> </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  async mounted() {
    this.secretMessage = await AuthService.dataKota();
    this.hasildatas = this.secretMessage.data.data;
    this.firstLoad = false;
    this.sloading = false;
  },
  name: "KotaView",
  data() {
    return {
      country: null,
      countries: {},
      hasildatas: [],
      headers: [
        { text: "Nama Kabupaten/Kota", value: "kota" },
        { text: "Nama Provinsi", value: "provinsi" },
        { text: "Aksi", value: "actions", sortable: false, align: "center" },
      ],

      dialog: false,
      dialogDelete: false,
      search: "",
      kota: "",
      valid: true,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      provRules: [(v) => !!v || "Nama Provinsi tidak boleh kosong"],
      namaKotaRules: [(v) => !!v || "Nama Kabupaten/Kota tidak boleh kosong"],

      editedIndex: -1,
      editedItem: {
        id: 0,

        country: null,
        kota: "",
      },
      defaultItem: {
        kota: "",

        country: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data Kobupaten/Kota"
        : "Form Edit Data Kobupaten/Kota";
    },
  },

  methods: {
    getCountries: function () {
      AuthService.getCamat().then(
        function (response) {
          this.countries = response.data.data;

          console.log(this.countries);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.country = this.editedItem.id_provinsi;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteKota(credentials);
      this.message = response.message;
      if (response.status === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });

        this.$router.push("/dashboard/kota").catch(() => {});

        this.secretMessage = await AuthService.dataKota();
        this.hasildatas = this.secretMessage.data;
        this.dialogDelete = this.closeDelete();
        console.log(this.secretMessage.data);
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
      });
    },

    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          const credentials = {
            id_provinsi: this.country,
            kota: this.editedItem.kota,
          };
          const response = await AuthService.simpanKota(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/dashboard/kota").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.dataKota();
            this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan kecamatan");
            console.log(this.secretMessage.data);
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update

          const credentials = {
            id: this.editedItem.id,
            id_provinsi: this.country,
            kota: this.editedItem.kota,
          };
          const response = await AuthService.ubahKota(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/dashboard/kota").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.dataKota();
            this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },
  created: function () {
    this.getCountries();
  },
};
</script>