import { render, staticRenderFns } from "./IkanBudidaya.vue?vue&type=template&id=102ef4aa&scoped=true&"
import script from "./IkanBudidaya.vue?vue&type=script&lang=js&"
export * from "./IkanBudidaya.vue?vue&type=script&lang=js&"
import style0 from "./IkanBudidaya.vue?vue&type=style&index=0&id=102ef4aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "102ef4aa",
  null
  
)

export default component.exports