<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title
          ><h4>
            Data Pertanian - Alat Pasca Panen dan Kebutuhannya
          </h4></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="550px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" v-bind="attrs" v-on="on" class="white--text"
              ><v-icon color="white" left dark> mdi-plus </v-icon> Tambah Data
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="9">
                    <v-text-field
                      label="NIK"
                      outlined
                      maxlength="16"
                      :counter="16"
                      v-model="editedItem.nik"
                      :rules="nomorduaRules"
                      color="teal"
                      required
                      dense
                      hint="masukan no NIK lalu tekan -> cek nik"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pl-5">
                    <v-btn small dark @click="cekKAKA()" outlined color="teal">
                      CEK NIK
                    </v-btn>
                    <h6 v-if="this.statusdata === 1" class="teal--text">
                      {{ this.pesancek }}
                    </h6>
                    <h6 v-else-if="this.statusdata === 0" class="red--text">
                      {{ this.pesancek }}
                    </h6>
                    <h5 v-else></h5>
                  </v-col>
                  <v-row
                    class="pl-1 pr-1"
                    dense
                    v-show="
                      this.hasildatakk.nik !== null && this.statusdata === 1
                    "
                  >
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.nik"
                        readonly
                        color="teal"
                        label="NIK"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="wajibRules"
                        v-model="hasildatakk.nama"
                        readonly
                        color="teal"
                        label="Nama Lengkap"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="wajibRules"
                        v-model="editedItem.usaha_pertanian"
                        :items="itemstani"
                        item-text="name"
                        item-value="name"
                        color="teal"
                        label="Pilih Jenis Alat Pasca Panen"
                        outlined
                        dense
                        multiple
                        required
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      dense
                      v-for="(item, index) in editedItem.usaha_pertanian"
                      :key="index"
                    >
                      <v-text-field
                        v-show="item === 'Padi'"
                        color="teal"
                        v-model="editedItem.padi"
                        label="Padi - Nama Alat"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Padi'"
                        color="teal"
                        v-model="editedItem.jumlah_padi"
                        label="Padi - Jumlah Produksi"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Jagung'"
                        color="teal"
                        v-model="editedItem.jagung"
                        label="Jagung - Nama Alat"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Jagung'"
                        color="teal"
                        v-model="editedItem.jumlah_jagung"
                        label="Jagung - Jumlah Produksi"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Cabe'"
                        color="teal"
                        v-model="editedItem.cabe_merah"
                        label="Cabe - Nama Alat"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Cabe'"
                        color="teal"
                        v-model="editedItem.jumlah_cabe_merah"
                        label="Cabe - Jumlah Produksi"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Bawang Merah'"
                        color="teal"
                        v-model="editedItem.bawang_merah"
                        label="Bawang Merah - Nama Alat"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Bawang Merah'"
                        color="teal"
                        v-model="editedItem.jumlah_bawang_merah"
                        label="Bawang Merah - Jumlah Produksi"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Kelapa'"
                        color="teal"
                        v-model="editedItem.kelapa"
                        label="Kelapa - Nama Alat"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Kelapa'"
                        color="teal"
                        v-model="editedItem.jumlah_kelapa"
                        label="Kelapa - Jumlah Produksi"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>
                      <v-text-field
                        v-show="item === 'Jahe'"
                        color="teal"
                        v-model="editedItem.jahe"
                        label="Jahe - Nama Alat"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>

                      <v-text-field
                        v-show="item === 'Jahe'"
                        color="teal"
                        v-model="editedItem.jumlah_jahe"
                        label="Jahe - Jumlah Produksi"
                        outlined
                        :rules="wajibRules"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <template
                  v-if="this.hasildatakk.nik !== null && this.statusdata === 1"
                >
                  <v-btn
                    v-if="this.editedIndex === -1"
                    dialog="true"
                    :loading="loading"
                    :disabled="!valid"
                    type="submit"
                    class="ma-2 white--text"
                    color="teal"
                  >
                    Simpan Data
                  </v-btn>
                  <v-btn
                    v-else
                    dialog="true"
                    :loading="loading"
                    :disabled="!valid"
                    type="submit"
                    class="ma-2 white--text"
                    color="teal"
                  >
                    Update Data
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn class="ma-2" disabled outlined color="grey">
                    Simpan Data
                  </v-btn>
                </template>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDetail" persistent max-width="750px">
      <v-card>
        <v-card-title><h5>Detail Data Alat Pasca Panen</h5> </v-card-title>
        <v-divider></v-divider>
        <v-form
          @submit.prevent="handleSubmit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  label="NIK"
                  outlined
                  maxlength="16"
                  :counter="16"
                  v-model="editedItem.nik"
                  :rules="nomorduaRules"
                  color="teal"
                  required
                  readonly
                  dense
                ></v-text-field>
              </v-col>

              <v-row
                class="pl-1 pr-1"
                dense
                v-show="this.hasildatakk.nik !== null && this.statusdata === 1"
              >
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="wajibRules"
                    v-model="hasildatakk.nama"
                    readonly
                    color="teal"
                    label="Nama Lengkap"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.usaha_pertanian"
                    :items="itemstani"
                    item-text="name"
                    item-value="name"
                    color="teal"
                    label="Jenis Usaha Pertanian"
                    outlined
                    dense
                    readonly
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  dense
                  v-for="(item, index) in editedItem.usaha_pertanian"
                  :key="index"
                >
                  <v-text-field
                    v-show="item === 'Padi'"
                    color="teal"
                    readonly
                    v-model="editedItem.padi"
                    label="Padi - Nama Alat"
                    outlined
                    :rules="wajibRules"
                    dense
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Padi'"
                    color="teal"
                    v-model="editedItem.jumlah_padi"
                    label="Padi - Jumlah Produksi"
                    outlined
                    readonly
                    :rules="wajibRules"
                    dense
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Jagung'"
                    color="teal"
                    v-model="editedItem.jagung"
                    label="Jagung - Nama Alat"
                    outlined
                    readonly
                    :rules="wajibRules"
                    dense
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Jagung'"
                    color="teal"
                    v-model="editedItem.jumlah_jagung"
                    label="Jagung - Jumlah Produksi"
                    outlined
                    :rules="wajibRules"
                    dense
                    readonly
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Cabe'"
                    color="teal"
                    v-model="editedItem.cabe_merah"
                    label="Cabe - Nama Alat"
                    outlined
                    :rules="wajibRules"
                    dense
                    readonly
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Cabe'"
                    color="teal"
                    v-model="editedItem.jumlah_cabe_merah"
                    label="Cabe - Jumlah Produksi"
                    outlined
                    :rules="wajibRules"
                    dense
                    readonly
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Bawang Merah'"
                    color="teal"
                    v-model="editedItem.bawang_merah"
                    label="Bawang Merah - Nama Alat"
                    outlined
                    :rules="wajibRules"
                    dense
                    readonly
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Bawang Merah'"
                    color="teal"
                    v-model="editedItem.jumlah_bawang_merah"
                    label="Bawang Merah - Jumlah Produksi"
                    outlined
                    :rules="wajibRules"
                    dense
                    readonly
                    required
                  ></v-text-field>
                  <v-text-field
                    v-show="item === 'Kelapa'"
                    color="teal"
                    v-model="editedItem.kelapa"
                    label="Kelapa - Nama Alat"
                    outlined
                    readonly
                    :rules="wajibRules"
                    dense
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Kelapa'"
                    color="teal"
                    v-model="editedItem.jumlah_kelapa"
                    label="Kelapa - Jumlah Produksi"
                    outlined
                    readonly
                    :rules="wajibRules"
                    dense
                    required
                  ></v-text-field>
                  <v-text-field
                    v-show="item === 'Jahe'"
                    color="teal"
                    v-model="editedItem.jahe"
                    label="Jahe - Nama Alat"
                    outlined
                    readonly
                    :rules="wajibRules"
                    dense
                    required
                  ></v-text-field>

                  <v-text-field
                    v-show="item === 'Jahe'"
                    color="teal"
                    v-model="editedItem.jumlah_jahe"
                    label="Jahe - Jumlah Produksi"
                    outlined
                    readonly
                    :rules="nomorRules"
                    dense
                    hint="hanya berisi angka dengan satuan (Ha)"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" @click="closeDetails" outlined color="grey">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialogDelete" persistent max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Yakin hapus data Alat Pasca Panen ?</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br />
            <h4 class="brown--text">NIK :{{ editedItem.nik }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-card v-else elevation="0">
        <v-card-text>
          <v-data-table
            multi-sort
            :headers="headers"
            :items="hasildatas"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="center">
                <v-btn
                  class="lighten-4 mx-1"
                  @click="detailItem(item)"
                  outlined
                  small
                  color="cyan"
                >
                  <v-icon dense center> mdi-information </v-icon>
                </v-btn>
                <v-btn
                  class="lighten-4 mx-2"
                  @click="editItem(item)"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon dense> mdi-pencil </v-icon>
                </v-btn>
                <v-btn small @click="deleteItem(item)" outlined color="error">
                  <v-icon dense center> mdi-delete </v-icon>
                </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.usaha_pertanian`]="{ item }">
              <v-chip
                class="ma-1"
                color="brown"
                label
                small
                text-color="white"
                v-for="(items, index) in item.usaha_pertanian"
                :key="index"
              >
                <v-icon small left> mdi-label </v-icon>
                <h4 class="font-weight-bold">
                  {{ items }}
                </h4>
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "masterbarangvue",
  data() {
    return {
      date: null,

      modal: false,
      menu2: false,
      // ITEMs

      itemsmakanan: [{}],
      itemskesehatan: [{}],
      itemsrencana: [{}],
      hasildatas: [],

      dialog: false,
      dialogDelete: false,
      search: "",
      provinsi: "",
      valid: true,
      dialogDetail: false,

      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      // VALIDASI
      nomorduaRules: [
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "No NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No NIK harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      headers: [
        { text: "Nomor NIK", value: "nik", width: "100px" },
        {
          text: "Jenis Usaha Pertanian",
          value: "usaha_pertanian",
          width: "100px",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "130px",
        },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        id_nik: "",
        nik: "",
        id_nokk: "",
        usaha_pertanian: "",

        padi: null,
        jagung: null,
        cabe_merah: null,
        bawang_merah: null,
        kelapa: null,
        jahe: null,

        jumlah_padi: null,
        jumlah_jagung: null,
        jumlah_cabe_merah: null,
        jumlah_bawang_merah: null,
        jumlah_kelapa: null,
        jumlah_jahe: null,
      },
      defaultItem: {
        id: 0,
        id_nik: "",
        nik: "",
        id_nokk: "",
        usaha_pertanian: "",

        padi: null,
        jagung: null,
        cabe_merah: null,
        bawang_merah: null,
        kelapa: null,
        jahe: null,

        jumlah_padi: null,
        jumlah_jagung: null,
        jumlah_cabe_merah: null,
        jumlah_bawang_merah: null,
        jumlah_kelapa: null,
        jumlah_jahe: null,
      },

      hasildatakk: {
        id_nik: null,
        nik: null,
        nama: null,
        id_nokk: null,
      },
      pesancek: "",
      statusdata: null,

      itemstani: ["Padi", "Jagung", "Cabe", "Bawang Merah", "Kelapa", "Jahe"],

      itemsyatidak: [{}],
      itemsjk: [{}],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Pasca Panen dan Kebutuhanya "
        : "Form Edit Pasca Panen dan Kebutuhanya ";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {
    this.getPascaPanenPertanianAll();
    this.detailUsers();
    this.getDusun();
  },
  methods: {
    cekKAKA: function (row) {
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          // console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          this.kota = this.detailusers.kota;
          this.kecamatan = this.detailusers.kecamatan;
          this.kelurahan = this.detailusers.kelurahan;
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getPascaPanenPertanianAll: function () {
      AuthService.getPascaPanenPertanianAll().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          // console.log(this.hasildatas);
        }.bind(this)
      );
    },
    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          // console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
      this.dialog = true;
    },

    detailItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.statusdata = 1;
      const credentials = {
        nik: this.editedItem.nik,
      };
      AuthService.cekNik(credentials).then(
        function (response) {
          // console.log(response.data.message);
          if (response.data.success === true) {
            this.statusdata = 1;
            this.hasildatakk.nik = response.data.message.nik;
            this.hasildatakk.nama = response.data.message.nama;
            this.hasildatakk.id_nik = response.data.message.id;
            this.hasildatakk.id_nokk = response.data.message.id_kk;

            this.pesancek = "Data Ditemukan ";
          } else {
            (this.statusdata = 0), (this.pesancek = "Data Tidak Ditemukan");
          }
        }.bind(this)
      );
      this.dialogDetail = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      // console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.hasildatas.splice(this.editedIndex, 1);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deletePascaPanenPertanian(credentials);
      this.message = response.message;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.getPascaPanenPertanianAll();
        this.detailUsers();
        this.getDusun();

        this.$router
          .push("/admin-kelurahan/pertanian/pasca-panen")
          .catch(() => {});

        this.dialogDelete = this.closeDelete();
        this.hasildatakk = [{}];
        this.statusdata = null;
        this.pesancek = null;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.statusdata = null;
      });
    },
    closeDetails() {
      this.dialogDetail = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.statusdata = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update
        if (this.editedIndex === -1) {
          //proses simpan
          // console.log(this.editedItem);
          const credentials = {
            nik: this.hasildatakk.nik,

            jenis_usaha: this.editedItem.usaha_pertanian,
            padi: this.editedItem.padi,
            jagung: this.editedItem.jagung,
            cabe_merah: this.editedItem.cabe_merah,
            bawang_merah: this.editedItem.bawang_merah,
            kelapa: this.editedItem.kelapa,
            jahe: this.editedItem.jahe,

            jumlah_padi: this.editedItem.jumlah_padi,
            jumlah_jagung: this.editedItem.jumlah_jagung,
            jumlah_cabe_merah: this.editedItem.jumlah_cabe_merah,
            jumlah_bawang_merah: this.editedItem.jumlah_bawang_merah,
            jumlah_kelapa: this.editedItem.jumlah_kelapa,
            jumlah_jahe: this.editedItem.jumlah_jahe,
          };
          const response = await AuthService.tambahPascaPanenPertanian(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getPascaPanenPertanianAll();
            this.detailUsers();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/pertanian/pasca-panen")
              .catch(() => {});
            this.dialog = false;
            this.statusdata = null;

            this.$refs.form.reset();
            console.log("berhasil simpan data");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses update
          const credentials = {
            id: this.editedItem.id,
            nik: this.hasildatakk.nik,

            jenis_usaha: this.editedItem.usaha_pertanian,
            padi: this.editedItem.padi,
            jagung: this.editedItem.jagung,
            cabe_merah: this.editedItem.cabe_merah,
            bawang_merah: this.editedItem.bawang_merah,
            kelapa: this.editedItem.kelapa,
            jahe: this.editedItem.jahe,

            jumlah_padi: this.editedItem.jumlah_padi,
            jumlah_jagung: this.editedItem.jumlah_jagung,
            jumlah_cabe_merah: this.editedItem.jumlah_cabe_merah,
            jumlah_bawang_merah: this.editedItem.jumlah_bawang_merah,
            jumlah_kelapa: this.editedItem.jumlah_kelapa,
            jumlah_jahe: this.editedItem.jumlah_jahe,
          };
          const response = await AuthService.editPascaPanenPertaninan(
            credentials
          );
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.getPascaPanenPertanianAll();
            this.detailUsers();
            this.getDusun();

            this.loading = false;
            this.$router
              .push("/admin-kelurahan/pertanian/pasca-panen")
              .catch(() => {});
            this.dialog = false;
            this.statusdata = null;
            this.$refs.form.reset();

            console.log("berhasil update");
            this.close();
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.close();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
