<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Luas Lahan</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title><h5>Apakah yakin hapus data lahan ?</h5> </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogEdit" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2">Edit Data Lahan</strong>
            <v-btn small color="red" class="white--text" @click="closeEdit"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="updateLahan"
            v-model="valid"
            lazy-validation
          >
            <br />

            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.ladang"
                  color="teal"
                  label="Luas Lahan Ladang / Tegalan"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.kebun"
                  color="teal"
                  label="Luas Lahan Kebun"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.kolam"
                  color="teal"
                  label="Luas Lahan Kolam"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.tebuka"
                  color="teal"
                  label="Luas Lahan Terbuka"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.permukiman"
                  color="teal"
                  label="Luas Lahan Pemukiman"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.sawah"
                  color="teal"
                  label="Luas Lahan Sawah"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.semak"
                  color="teal"
                  label="Luas Lahan Semak Belukar"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.pemakaman"
                  color="teal"
                  label="Luas Lahan Pemakaman"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.hutan"
                  color="teal"
                  label="Luas Lahan Hutan"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.lainnya"
                  color="teal"
                  label="Luas Lahan Lainnya"
                  filled
                  dense
                  hint="input angka dengan satuan (Ha)"
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                :disabled="!valid"
                @click="validate"
                type="submit"
                color="teal"
                class="mr-0 white--text"
              >
                Update Data
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
        multi-sort
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td>{{ item.ladang }} Ha</td>
              <td>{{ item.kebun }} Ha</td>
              <td>{{ item.kolam }} Ha</td>
              <td>{{ item.tebuka }} Ha</td>
              <td>{{ item.permukiman }} Ha</td>
              <td>{{ item.sawah }} Ha</td>
              <td>{{ item.semak }} Ha</td>
              <td>{{ item.pemakaman }} Ha</td>

              <td>{{ item.hutan }} Ha</td>
              <td>{{ item.lainnya }} Ha</td>
              <td>{{ item.total }} Ha</td>
              <td>
                <div class="d-flex justify-center">
                  <v-btn
                    class="lighten-4 mx-2"
                    @click="editItem(item)"
                    outlined
                    small
                    color="primary"
                  >
                    <v-icon dense center> mdi-pencil </v-icon>
                  </v-btn>

                  <v-btn @click="deleteItem(item)" outlined small color="error">
                    <v-icon dense center> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "LahanData",

  data() {
    return {
      // Dialog
      dialogEdit: false,
      dialogDetail: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: " Ladang", value: "ladang", width: "140px" },
        { text: " Kebun", value: "kebun", width: "140px" },
        { text: " Kolam", value: "kolam", width: "140px" },
        { text: " Terbuka", value: "tebuka", width: "140px" },
        { text: " Permukiman", value: "permukiman", width: "140px" },

        { text: " Sawah", value: "sawah", width: "140px" },
        { text: " Semak Belukar", value: "semak", width: "140px" },
        { text: " Pemakaman", value: "pemakaman", width: "140px" },
        { text: " Hutan", value: "hutan", width: "140px" },
        { text: " Lainnya", value: "lainnya", width: "140px" },
        { text: " Total", value: "total", width: "140px" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      individuItem: {
        id: 0,
      },

      editedIndex: -1,
      editedItem: {
        id: 0,
        ladang: "",
        kebun: "",
        kolam: "",
        tebuka: "",
        permukiman: "",
        sawah: "",
        semak: "",
        pemakaman: "",
        hutan: "",
        lainnya: "",
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getLahanAll();

    this.detailUsers();
    this.getDusun();
  },
  methods: {
    // Data Bangunan
    getLahanAll: function () {
      AuthService.getLahanAll().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
      console.log(this.editedItem);
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },
    detailData(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDetail = true;

      console.log(item.nama_jalan);
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteLahan(credentials);
      this.message = response.data;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getLahanAll();

        this.$router.push("/admin-kelurahan/lahan/data").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.dialogEdit = false;

      this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },
    async updateLahan() {
      this.loading = true;

      try {
        const credentials = {
          id: this.editedItem.id,

          ladang: this.editedItem.ladang,
          kebun: this.editedItem.kebun,
          kolam: this.editedItem.kolam,
          tebuka: this.editedItem.tebuka,
          permukiman: this.editedItem.permukiman,
          sawah: this.editedItem.sawah,
          semak: this.editedItem.semak,
          pemakaman: this.editedItem.pemakaman,
          hutan: this.editedItem.hutan,
          lainnya: this.editedItem.lainnya,
        };

        const response = await AuthService.editLahan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil update lahan");
          this.getLahanAll();

          this.$router.push("/admin-kelurahan/lahan/data").catch(() => {});
          this.loading = false;
          this.closeEdit();
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.closeEdit();
        }
      } catch {
        this.loading = false;
        this.closeEdit();
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>



<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>





