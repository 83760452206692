<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Halaman Beranda Superadmin</h4></v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />
  </v-container>
</template>
<script>
export default {
  name: "HomeView",
  data: () => ({
    items: [
      {
        text: "Dashboard",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Link 1",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "Link 2",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
    ],
  }),
};
</script>